import styled from "styled-components";
import { Paragraph } from "../../../Typography";

export const RunnerItemContainer = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: var(--fd-space-space-2, 8px);
  gap: var(--fd-space-space-2, 8px);
`;

export const Position = styled(Paragraph)`
  width: 21px;
`;

export const FavoriteTag = styled.div`
  align-self: center !important;
`;

export const PayoutsContainer = styled.div`
  display: flex;
  gap: var(--fd-space-space-3, 12px);
  margin-left: auto;
`;

export const PayoutValue = styled(Paragraph)`
  display: flex;
  justify-content: flex-end;
  width: 37px;
`;
