import styled from "styled-components";

export const StyledComponent = styled.div`
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
  width: 62px;
  height: 62px;
`;

export const IconContainer = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BagdeContainer = styled.div<{ size?: string }>`
  position: absolute;
  right: -0.1px;
  top: -0.1px;

  ${({ size }) => size === "m" && "top: 4px; right: 4px;"}
`;
