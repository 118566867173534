import React, { useContext, type FC, isValidElement } from "react";

import { Counter } from "../../../Counter";
import { SegmentTab, SegmentText } from "./styled-components";

import { SCContext } from "../../context";

import type { TabProps } from "./types";

export const SegmentedControlTab: FC<TabProps> = ({
  value,
  qaLabel,
  children,
  notificationCount,
  onClick
}) => {
  const { selectedValue } = useContext(SCContext);

  return (
    <SegmentTab
      data-qa-label={qaLabel}
      role="tab"
      onClick={onClick}
      aria-selected={value === selectedValue}
    >
      {isValidElement(children) ? (
        children
      ) : (
        <SegmentText>{children}</SegmentText>
      )}
      {notificationCount && (
        <Counter value={notificationCount} variant="primary" />
      )}
    </SegmentTab>
  );
};

export { SegmentText };
