import styled, { css } from "styled-components";

const BorderLine = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 1px;
  ${(props) => css`
    background: ${props.color};
  `}
`;

export default BorderLine;
