import styled, { css } from "styled-components";
import { Theme } from "styled-system";

export const PickBetsContainer = styled.div<{
  hasRoundBottomBorder: boolean;
  theme: Theme;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  border-top: 1px solid var(--fd-colors-border-subtle);
  ${({ hasRoundBottomBorder }) =>
    hasRoundBottomBorder &&
    css`
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    `}
`;

export const PickBetsTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const PickBetsLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  text-decoration: none;
`;
