import styled from "styled-components";
import { fontNormal } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const Container = styled.section`
  width: 100%;
  text-align: center;
  word-break: break-word;
  margin: 25px 0 32px;

  @media only screen and (max-width: 599px) {
    text-align: left;
    margin: 16px 0 24px;
    padding: 0 12px;
  }
`;

export const Text = styled.p`
  color: ${buildColor("grey", "800")};
  font-size: 14px;
  font-family: ${fontNormal};
  font-weight: normal;
  letter-spacing: 0;
  line-height: 18px;
`;
