import React, { PureComponent } from "react";

import {
  PromosInfoContainer,
  InfoStepsContainer,
  InfoStepsWrapper
} from "./styled-components";
import ContentTitle from "../../_atoms/ContentTitle";
import Step from "../../_molecules/PromosInfoStep";

class PromoInfoContent extends PureComponent {
  static defaultProps = {
    component: "",
    _uid: "",
    content: {
      steps: [],
      header: ""
    },
    qaLabel: "promoInfo"
  };

  render() {
    const { content, qaLabel } = this.props;

    return (
      <PromosInfoContainer data-qa-label={qaLabel}>
        <InfoStepsWrapper data-qa-label={`${qaLabel}StepsWrapper`}>
          <ContentTitle
            content={{
              header: content.header,
              className: "promoInfoStepsTitle"
            }}
            qaLabel={`${qaLabel}StepsTitle`}
          />
          <InfoStepsContainer data-qa-label={`${qaLabel}Container`}>
            {content.steps !== null &&
              content.steps.length > 0 &&
              content.steps.map((step) => (
                <Step
                  key={step._uid}
                  isTopPromo
                  content={step}
                  qaLabel={`${step.stepNumber || 0}-${qaLabel}Step`}
                />
              ))}
          </InfoStepsContainer>
        </InfoStepsWrapper>
      </PromosInfoContainer>
    );
  }
}

export default PromoInfoContent;
