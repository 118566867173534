import React from "react";
import { LoadingMaskAnimationProps } from "./types";
import { StyledAnimatedContainer } from "./styled-components";

export const LoadingMaskAnimation = ({
  children,
  qaLabel = "load-mask-animation",
  ...rest
}: LoadingMaskAnimationProps) => (
  <StyledAnimatedContainer {...rest} data-qa-label={qaLabel}>
    {children}
  </StyledAnimatedContainer>
);

export type { LoadingMaskAnimationProps };
