import styled from "styled-components";

export const SwitchComp = styled.div`
  position: relative;
  width: 52px;
  height: 24px;
  background-color: var(
    --fd-colors-component-selection-control-background-unselected
  );
  border-radius: 12px;
  gap: 4px;
  transition: 200ms all ease-in-out;

  &::before {
    transition: 200ms all ease-in-out;
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 10px;
    top: 50%;
    left: 4px;
    background-color: var(
      --fd-colors-component-selection-control-background-base
    );
    transform: translate(0, -50%);
  }
`;

export const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${SwitchComp} {
    background-color: var(
      --fd-colors-component-selection-control-background-selected
    );

    &::before {
      transform: translate(26px, -50%);
    }
  }

  &:disabled + ${SwitchComp} {
    background-color: var(
      --fd-colors-component-selection-control-background-disabled
    );
  }

  &:checked:disabled + ${SwitchComp} {
    background-color: var(
      --fd-colors-component-selection-control-background-disabled
    );
  }
`;

export const SliderWrapper = styled.div`
  display: flex;
  position: relative;
  width: fit-content;

  &::before {
    width: 22px;
    color: var(--fd-colors-content-on-dark);
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: absolute;
    content: attr(data-status);
    font-size: 12px;
    font-family: Roboto-Regular, sans-serif;
    right: 4px;
    font-weight: 500;
    line-height: 125%;
  }

  &:has(input:checked)::before {
    left: 4px;
  }

  &:has(input:disabled)::before {
    color: var(--fd-colors-content-disabled);
  }
`;
