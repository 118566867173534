import React, { memo, forwardRef, useState } from "react";
import type {
  TextInput,
  NativeSyntheticEvent,
  TextInputFocusEventData
} from "react-native";
import type { InputTextProps } from "../types";
import { InputText } from "../inputText";

const REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export const InputEmail = forwardRef<TextInput, InputTextProps>(
  (
    {
      onChangeText,
      onBlur,
      isInvalid = false,
      value = "",
      label = "",
      ...rest
    },
    ref
  ) => {
    const [isInvalidState, setInvalidState] = useState(false);

    const changeTextHandler = (text: string) => {
      if (onChangeText) onChangeText(text);
      setInvalidState(false);
    };

    const blurHandler = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
      if (onBlur) onBlur(e);
      setInvalidState(value !== "" && !REGEX.test(value));
    };

    return (
      <InputText
        ref={ref}
        label={label}
        {...rest}
        isInvalid={isInvalidState || isInvalid}
        onChangeText={changeTextHandler}
        onBlur={blurHandler}
        autoCapitalize="none"
      />
    );
  }
);

export default memo(InputEmail);
