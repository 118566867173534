import React from "react";
import Icon from "../../_static/Icons";
import { warning } from "../../_static/Icons/icons";
import { ErrorContainer, ErrorText } from "./styled-components";

const ErrorNotification = ({
  text,
  addBottomMargin,
  borderTopRadius,
  qaLabel
}) => (
  <ErrorContainer
    data-qa-label={qaLabel}
    addBottomMargin={addBottomMargin}
    borderTopRadius={borderTopRadius}
  >
    <Icon icon={warning} size={16} view="0 0 16 16" />
    <ErrorText data-qa-label={`${qaLabel}Text`}>{text}</ErrorText>
  </ErrorContainer>
);

ErrorNotification.defaultProps = {
  addBottomMargin: false,
  borderTopRadius: false,
  qaLabel: "errorNotification"
};

export default ErrorNotification;
