module.exports = {
  domain: {
    desktop: "pabets-qa.tvg.com",
    mobile: "pabets-qa.tvg.com",
    talentPicks: "pabets-qa.tvg.com/talent-picks/",
    equibase: "equibase-store-qa.tvg.com"
  },
  recaptchaID: "6Lf-4hUqAAAAAGupa6W70kpZVsaFd7yaxz5pHD8r",
  recaptchaIdNative: "6Le9B1YqAAAAAJEWJZsQittPtHDcjz6xLNORgHyI"
};
