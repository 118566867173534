import React from "react";
import styled from "styled-components";

export const generatePaths = (icon, qaLabel) =>
  icon.map((shape, index) => {
    if (shape.path) {
      return (
        <path
          key={`path-${index.toString()}`}
          fill={shape.fill}
          fillRule={shape.fillRule}
          clipRule={shape.clipRule}
          stroke={shape.strokeColor}
          strokeWidth={shape.strokeWidth}
          fillOpacity={shape.fillOpacity}
          d={shape.path}
          {...(qaLabel ? { "data-qa-label": `${qaLabel}-path` } : {})}
        />
      );
    }
    return (
      <rect
        key={`rect-${index.toString()}`}
        x={shape.xRect}
        y={shape.yRect}
        width={shape.widthRect}
        height={shape.heightRect}
        rx={shape.rxRect}
        fill={shape.fill}
        stroke={shape.strokeColor}
        strokeWidth={shape.strokeWidth}
        {...(qaLabel ? { "data-qa-label": `${qaLabel}-rect` } : {})}
      />
    );
  });

const colorize = (
  colorList = [],
  strokeColorList = [],
  strokeWidthList = [],
  icon
) => {
  const localIcon = icon.map((shape) => ({ ...shape }));

  if (colorList.length) {
    colorList.forEach((color, index) => {
      if (color && localIcon[index]) {
        localIcon[index].fill = color;
      }
    });
  }

  if (strokeColorList.length) {
    strokeColorList.forEach((color, index) => {
      if (color && localIcon[index]) {
        localIcon[index].strokeColor = color;
      }
    });
  }

  if (strokeWidthList.length) {
    strokeWidthList.forEach((width, index) => {
      if (width && localIcon[index]) {
        localIcon[index].strokeWidth = width;
      }
    });
  }

  return localIcon;
};

const IconSVG = styled.svg.attrs(({ size, qaLabel, viewBoxSize, filter }) => ({
  width: size,
  height: size,
  viewBox: `0 0 ${viewBoxSize} ${viewBoxSize}`,
  "data-qa-label": qaLabel,
  filter
}))`
  display: inline-block;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  fill: ${({ color, colorList }) =>
    color !== "inherit" && colorList.length === 0 && color};
  stroke: ${({ stroke }) => stroke !== "inherit" && stroke};
  ${({ filter }) => (filter ? `filter: ${filter};` : "")};
`;

const Icon = ({
  icon,
  size,
  qaLabel,
  className,
  colorList,
  stroke,
  strokeColorList,
  strokeWidthList,
  filter,
  color,
  viewBoxSize
}) => (
  <IconSVG
    size={size}
    qaLabel={qaLabel}
    className={className}
    colorList={colorList}
    filter={filter}
    color={color}
    stroke={stroke}
    viewBoxSize={viewBoxSize}
  >
    {generatePaths(
      colorList.length || strokeColorList.length || strokeWidthList.length
        ? colorize(colorList, strokeColorList, strokeWidthList, icon.shapes)
        : icon.shapes,
      qaLabel
    )}
  </IconSVG>
);

Icon.defaultProps = {
  size: 16,
  icon: null,
  color: "inherit",
  stroke: "inherit",
  className: "",
  qaLabel: null,
  filter: "",
  colorList: [],
  strokeColorList: [],
  strokeWidthList: [],
  viewBoxSize: 1024
};

export default Icon;
