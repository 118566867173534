import React, { PureComponent } from "react";
import { Route, Routes } from "react-router-dom";
import withRouter from "@tvg/utils/withCustomRouter";

import { TvgSafeAreaProvider } from "@tvg/design-system/src/utils/tvgSafeAreaProvider";
import { connect } from "react-redux";

import { attempt, get, isEmpty, bindAll } from "lodash";
import tvgConf from "@tvg/conf";
import { Containers } from "@tvg/atomic-ui/_static/BaseComponents";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import mediator from "@tvg/mediator";
import {
  getAccountNumber,
  getEmail,
  getResidenceState
} from "@urp/store-selectors";

import calculateFeatureOverride from "@tvg/utils/featuresUtils";
import {
  failFeaturesRequest,
  successFeaturesRequest,
  successMessagesRequest,
  failMessagesRequest
} from "../actions/capi";
// eslint-disable-next-line
import Refer from "./components/Refer";
import {
  getUserData,
  successUserPromos,
  loadingUserPromos,
  clearUserPromos
} from "../actions/user";
import { setEmailReferralOpen } from "../actions/app";

// eslint-disable-next-line
export class Main extends PureComponent {
  constructor(props) {
    super(props);
    bindAll(this, ["getFeatures"]);

    this.tvg = tvgConf();

    this.state = {
      initialRender: false
    };
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      mediatorClassic.subscribe("UPDATE_ROUTER", () => {
        const pathWithParameters = window.location.href.replace(
          window.location.origin,
          ""
        );

        this.props.history.replace(pathWithParameters);
      });

      mediator.base.subscribe("TVG4_NAVIGATION", (data) => {
        mediatorClassic.dispatch("TVG4_NAVIGATION", data.payload);
      });

      mediator.base.subscribe("TVG_LOGIN:UPDATE_USER_PROMOS", (data) => {
        if (data.payload) {
          this.props.dispatch(successUserPromos(data.payload));
        }
      });

      mediator.base.subscribe("TVG_LOGIN:CLEAR_USER_PROMOS", () => {
        this.props.dispatch(clearUserPromos());
      });

      mediator.base.subscribe("OPEN_EMAIL_REFERRAL_MODAL", () => {
        this.props.dispatch(setEmailReferralOpen(true));
      });

      if (!isEmpty(this.props.features)) {
        const featureToggles = {};
        let featureOverrides = this.getFeaturesOverrides() || {};
        const hasFeaturesOverrides = !!this.getFeaturesOverrides();

        this.props.features.forEach((toggle) => {
          let { enabled } = toggle;
          featureOverrides = calculateFeatureOverride(
            hasFeaturesOverrides,
            featureOverrides,
            toggle
          );
          if (get(featureOverrides, toggle.name)) {
            enabled = featureOverrides[toggle.name].enabled;
          }

          featureToggles[toggle.name] = enabled;
        });

        if (typeof window !== "undefined" && !isEmpty(featureOverrides)) {
          this.setFeaturesOverrides(featureOverrides);
        }
        this.props.dispatch(successFeaturesRequest(featureToggles));
      } else {
        this.getFeatures();
      }
      mediatorClassic.subscribeWithPast(
        "TVG_LOGIN:USER_SESSION_UPDATE",
        (data) => {
          this.props.dispatch(getUserData(data));
        }
      );
    }
    this.getMessages();
    this.setInitialRender();
  }

  componentDidUpdate(prevProps) {
    const prevPath = get(prevProps, "location.pathname", "");
    const currPath = get(this.props, "location.pathname", "");

    if (
      this.props.isLogged &&
      this.props.accountNumber &&
      this.props.accountNumber !== "" &&
      !this.props.loadingPromos &&
      (!this.props.gotPromos ||
        (!prevPath.includes("referral") && currPath.includes("referral")))
    ) {
      this.props.dispatch(loadingUserPromos());
      mediator.base.dispatch({
        type: "TVG_LOGIN:GET_USER_PROMOS"
      });
    }

    if (!this.props.isLogged && this.props.gotPromos) {
      mediator.base.dispatch({
        type: "TVG_LOGIN:CLEAR_USER_PROMOS"
      });
    }
  }

  setInitialRender = () => {
    this.setState({ initialRender: true });
  };

  getMessages = () => {
    const messageNamespaces = [
      "MobileWebApp",
      "Login",
      "Global",
      "InformationalPages",
      "BetTicket",
      "WagerRewards",
      "Homepage",
      "tutorials",
      "DepositLimits",
      "ResponsibleGaming",
      "FundingMethods",
      "tutorials",
      "RedirectEngine"
    ];

    return this.tvg
      .getMessages(messageNamespaces, true)
      .then((response) => this.props.dispatch(successMessagesRequest(response)))
      .catch((err) => this.props.dispatch(failMessagesRequest(err)));
  };

  getFeaturesOverrides = () =>
    attempt(
      () => JSON.parse(window.localStorage.getItem("featureOverrides")),
      false
    );

  setFeaturesOverrides = (features) =>
    attempt(() =>
      window.localStorage.setItem("featureOverrides", JSON.stringify(features))
    );

  getFeatures = () =>
    typeof window !== "undefined" &&
    this.tvg
      .getFeatures()
      .then((response) => {
        const featureToggles = {};
        let featureOverrides = this.getFeaturesOverrides() || {};
        const hasFeaturesOverrides = !!this.getFeaturesOverrides();

        if (response && Array.isArray(response.featureToggles)) {
          response.featureToggles.forEach((toggle) => {
            let { enabled } = toggle;
            featureOverrides = calculateFeatureOverride(
              hasFeaturesOverrides,
              featureOverrides,
              toggle
            );
            if (get(featureOverrides, toggle.name)) {
              enabled = featureOverrides[toggle.name].enabled;
            }

            featureToggles[toggle.name] = enabled;
          });
        }

        if (!isEmpty(featureOverrides)) {
          this.setFeaturesOverrides(featureOverrides);
        }

        this.props.dispatch(successFeaturesRequest(featureToggles));
      })
      .catch((err) => this.props.dispatch(failFeaturesRequest(err)));

  tvg;

  render() {
    return (
      <main>
        <TvgSafeAreaProvider>
          <Routes>
            <Route
              path="/referral"
              element={
                <Containers.Page>
                  <Refer
                    isLogged={this.props.isLogged}
                    accountNumber={this.props.accountNumber}
                    returningUser={this.props.returningUser}
                    userEmail={this.props.userEmail}
                    homeState={this.props.homeState}
                    dispatch={this.props.dispatch}
                    promoId={this.props.promoId}
                    isEmailReferralOpen={this.props.isEmailReferralOpen}
                    isEmailReferralEnable={this.props.isEmailReferralEnable}
                    initialRenderComplete={this.state.initialRender}
                    device={this.tvg.device}
                  />
                </Containers.Page>
              }
            />
          </Routes>
        </TvgSafeAreaProvider>
      </main>
    );
  }
}

export default connect(
  (store) => ({
    isLogged: get(store, "userData.logged"),
    accountNumber: getAccountNumber(store),
    returningUser: get(store, "userData.returningUser", false),
    userEmail: getEmail(store),
    homeState: getResidenceState(store),
    promoId: Number(get(store, "capi.messages.ReferAFriendDefaultPromoId", "")),
    gotPromos: get(store, "userData.gotPromos"),
    loadingPromos: get(store, "userData.loadingPromos"),
    isEmailReferralOpen: get(store, "app.isEmailReferralOpen", false),
    isEmailReferralEnable: get(
      store,
      "capi.featureToggles.enableEmailReferral",
      false
    )
  }),
  (dispatch) => ({ dispatch })
)(withRouter(Main));
