import React, { PureComponent } from "react";
import { bindAll, get } from "lodash";

import { ScrollWrapperContainer } from "./styled-components";

export default class ScrollWrapper extends PureComponent {
  static defaultProps = {
    showFaders: true,
    centerFaders: true,
    fadersWidth: 18,
    fadeFrom: "rgba(255, 255, 255, 0)",
    fadeTo: "rgba(255, 255, 255, 1)",
    qaLabel: "scrollWrapper",
    isFullWidth: false,
    stretch: true
  };

  constructor(props) {
    super(props);

    this.ref = React.createRef();

    this.state = {
      hasContentLeft: false,
      hasContentRight: false,
      isListening: false
    };

    bindAll(this, ["verifyContentOverflow", "checkScrollableElement"]);
  }

  componentDidMount() {
    // FIX: fixes problem with modal opening on my bets and probables - if we dont have this setTimeout, opening my bets, the modal doesn't show any transition
    // it seems that it is too much js processing and the transition group can't handle it so we need to take this execution off the call stack
    setTimeout(() => this.checkScrollableElement(), 0);
  }

  componentDidUpdate(prevProps) {
    const previousChildrenKey = get(prevProps, "children.key");
    const currentChildrenKey = get(this.props, "children.key");

    if (previousChildrenKey !== currentChildrenKey) {
      // means content has changed, re-check for scrollable element inside children
      this.checkScrollableElement();
    }
  }

  componentWillUnmount() {
    if (get(this, "scrollableElement.removeEventListener")) {
      this.scrollableElement.removeEventListener(
        "scroll",
        this.verifyContentOverflow
      );
    }
  }

  checkScrollableElement() {
    this.scrollableElement = get(this.ref, "firstChild");

    if (
      this.scrollableElement &&
      this.scrollableElement.scrollWidth > this.scrollableElement.clientWidth
    ) {
      this.verifyContentOverflow();

      if (!this.state.isListening) {
        this.scrollableElement.addEventListener(
          "scroll",
          this.verifyContentOverflow
        );
        this.setState({ isListening: true });
      }
    }
  }

  verifyContentOverflow() {
    const hasContentLeft = this.scrollableElement.scrollLeft > 0;
    const hasContentRight =
      this.scrollableElement.scrollLeft <
      this.scrollableElement.scrollWidth - this.scrollableElement.clientWidth;
    this.setState({ hasContentLeft, hasContentRight });
  }

  ref;

  scrollableElement;

  render() {
    return (
      <ScrollWrapperContainer
        data-qa-label={this.props.qaLabel}
        fadersWidth={this.props.fadersWidth}
        fadeFrom={this.props.fadeFrom}
        fadeTo={this.props.fadeTo}
        hasContentLeft={this.state.hasContentLeft}
        hasContentRight={this.state.hasContentRight}
        showFaders={this.props.showFaders}
        centerFaders={this.props.centerFaders}
        ref={(comp) => (this.ref = comp)}
        isFullWidth={this.props.isFullWidth}
        stretch={this.props.stretch}
      >
        {this.props.children}
      </ScrollWrapperContainer>
    );
  }
}
