import styled, { css } from "styled-components";
import { margin } from "styled-system";

export const StyledContainer = styled.div<{ isFullHeight: boolean }>`
  position: relative;
  ${({ isFullHeight }) =>
    isFullHeight &&
    css`
      flex-grow: 1;
    `};
  ${margin}
`;

export const ContentContainer = styled.div<{ isFullHeight: boolean }>`
  ${({ isFullHeight }) =>
    isFullHeight &&
    css`
      flex-grow: 1;
    `};
`;

export const TabHeaderContainer = styled.div<{
  headerPadding?: string;
  headerWidth?: string;
}>`
  border-bottom: var(--fd-border-widths-border-width-default) var
    (--fd-border-styles-border-style-solid-style)
    var(--fd-colors-system-neutral-border-default);
  width: ${({ headerWidth }) => headerWidth || `100%`};
  background-color: var(--fd-colors-background-surface);
  z-index: 1;
  ${({ headerPadding }) =>
    headerPadding &&
    css`
      padding: ${headerPadding};
    `}
`;
