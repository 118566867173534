import React from "react";

const DEFAULT_PATH = {
  currentSlug: "",
  setContent: () => {}
};

const pathContext = React.createContext(DEFAULT_PATH);

export default pathContext;
