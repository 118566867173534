import styled from "styled-components";
import { fontBold, fontNormal } from "@tvg/atomic-ui/_static/Typography";

export const PromoInfoStepContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;

  @media screen and (min-width: 768px) {
    padding: 0 10px;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    flex: 1 1 0;

    ::after {
      width: 70px;
      height: 70px;
    }
  }
`;

export const PromoDetailContainer = styled.div`
  flex: 1;
`;

export const PromoIconContainer = styled.div`
  width: 72px;
  height: 72px;
  max-width: 72px;
  background-color: #f0f6ff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: auto;
  border-radius: 50%;
  margin-right: 24px;
  position: relative;

  ::after {
    position: absolute;
    width: 48px;
    height: 48px;
    background-color: #d6e3f0;
    border-radius: 50%;
    content: "";
  }

  svg {
    color: #7f9abf;
    z-index: 1;
    font-size: 24px;
  }

  @media screen and (min-width: 768px) {
    width: 105px;
    height: 105px;
    max-width: 105px;
    margin-right: 0;
    margin-bottom: 16px;

    ::after {
      width: 70px;
      height: 70px;
    }

    svg {
      font-size: 34px;
    }
  }
`;

export const PromoStepNumber = styled.p`
  font-size: 18px;
  color: rgb(98, 109, 120);
  font-weight: bold;
  font-family: ${fontBold};
  margin-bottom: 5px;
`;

export const PromoStepDescription = styled.p`
  font-size: 14px;
  color: rgb(98, 109, 120);
  font-family: ${fontNormal};
`;
