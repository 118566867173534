import React, { useEffect, useState, useRef } from "react";
import Parser from "html-react-parser";
import { sum } from "lodash";

import ScrollWrapper from "@tvg/atomic-ui/_molecule/ScrollWrapper";

import InternalLink from "../../_atoms/InternalLink";
import ExternalLink from "../../_atoms/ExternalLink";
import RichText from "../../_molecules/RichText";
import {
  TableContentWrapper,
  TableTitle,
  TableWrapper,
  Table,
  Td,
  Description,
  FooterNote
} from "./styled-components";

const TableContent = ({ content, qaLabel }) => {
  const {
    title,
    description,
    tableContent,
    boldCells,
    colorCells,
    alignCells,
    sizeColumns,
    linkCells,
    footerNote,
    fontSize,
    borderLess,
    paddingLess,
    isWidget
  } = content;

  const [hasScroll, setHasScroll] = useState(true);

  const tableRef = useRef();

  const checkTHead = () => {
    const cols = (tableContent.thead || []).map((th) =>
      th.value === "" ? 0 : 1
    );
    return !!sum(cols);
  };

  const boldCellsCollection = {};
  if (boldCells && boldCells !== "") {
    const cells = boldCells.toLowerCase().split(";");
    cells.forEach((cell) => {
      const cellSplitted = cell.split("");
      const col = cellSplitted.shift().charCodeAt(0) - 97; // column starting in 0
      const row = +cellSplitted.join("") - 2; // row starting in 0 including thead
      const key = row < 0 ? col : `${col}${row}`;
      boldCellsCollection[key] = true;
    });
  }

  const colorCellsCollection = {};
  if (colorCells && colorCells !== "") {
    const colors = colorCells.split(";");
    colors.forEach((cellColor) => {
      const [cell, color] = cellColor.split(":");
      const cellSplitted = cell.toLowerCase().split("");
      const col = cellSplitted.shift().charCodeAt(0) - 97; // column starting in 0
      const row = +cellSplitted.join("") - 2; // row starting in 0 including thead
      const key = row < 0 ? col : `${col}${row}`;
      colorCellsCollection[key] = color;
    });
  }

  const linkCellsCollection = {};
  if (linkCells && linkCells.length > 0) {
    linkCells.forEach((cellLink) => {
      const cellSplitted = cellLink.cell.toLowerCase().split("");
      const col = cellSplitted.shift().charCodeAt(0) - 97; // column starting in 0
      const row = +cellSplitted.join("") - 2; // row starting in 0 including thead
      const key = row < 0 ? col : `${col}${row}`;
      linkCellsCollection[key] = cellLink.path;
    });
  }

  const alignCellsCollection = {};
  if (alignCells && alignCells !== "") {
    const alignments = alignCells.split(";");
    alignments.forEach((cellAlign) => {
      const [cell, alignment] = cellAlign.split(":");
      const cellSplitted = cell.toLowerCase().split("");
      const col = cellSplitted.shift().charCodeAt(0) - 97; // column starting in 0
      const row = +cellSplitted.join("") - 2; // row starting in 0 including thead
      const key = row < 0 ? col : `${col}${row}`;
      alignCellsCollection[key] = alignment;
    });
  }

  const sizeCollection = {};
  if (sizeColumns && sizeColumns !== "") {
    const sizes = sizeColumns.split(";");
    sizes.forEach((size) => {
      const [cell, width] = size.split(":");
      const col = cell.toLowerCase().charCodeAt(0) - 97; // column starting in 0
      sizeCollection[col] = width;
    });
  }

  const renderExternalLink = (text, path) => (
    <ExternalLink url={path.url} qaLabel={`${qaLabel}ExternalLink`}>
      {text}
    </ExternalLink>
  );

  const renderInternalLink = (text, path) => (
    <InternalLink
      pathName={path.linktype === "story" ? path.cached_url : path.url}
      linkType={path.linktype}
      target="_self"
      qaLabel={`${qaLabel}InternalLink`}
    >
      {text}
    </InternalLink>
  );

  const renderLink = (text, path) =>
    path.linktype === "story" ||
    (path.url.indexOf("http") < 0 && path.linktype === "url")
      ? renderInternalLink(text, path)
      : renderExternalLink(text, path);

  useEffect(() => {
    if (tableRef.current.scrollWidth <= tableRef.current.clientWidth) {
      setHasScroll(false);
    }
  }, []);

  return (
    <TableContentWrapper
      isWidget={isWidget}
      borderLess={borderLess}
      paddingLess={paddingLess}
      data-qa-label={qaLabel}
    >
      <TableTitle data-qa-label={`${qaLabel}Title`}>{title}</TableTitle>
      {description && (
        <Description data-qa-label={`${qaLabel}DescriptionWrapper`}>
          <RichText
            content={{ markdown: description }}
            qaLabel={`${qaLabel}Description`}
          />
        </Description>
      )}
      <ScrollWrapper fadersWidth={100} qaLabel={`${qaLabel}Title`}>
        <TableWrapper ref={tableRef} data-qa-label={`${qaLabel}TableWrapper`}>
          <Table
            hasScroll={hasScroll}
            fontSize={fontSize}
            data-qa-label={`${qaLabel}Table`}
          >
            {checkTHead() && (
              <thead data-qa-label={`${qaLabel}TableHead`}>
                <tr data-qa-label={`${qaLabel}TableHeadRow`}>
                  {(tableContent.thead || []).map((col) => (
                    <th
                      key={col._uid}
                      data-qa-label={`${qaLabel}TableHeadItem`}
                    >
                      {col.value}
                    </th>
                  ))}
                </tr>
              </thead>
            )}
            <tbody data-qa-label={`${qaLabel}TableBody`}>
              {(tableContent.tbody || []).map((row, rowIndex) => (
                <tr
                  key={row._uid}
                  data-qa-label={`${rowIndex}-${qaLabel}TableBodyRow`}
                >
                  {row.body.map((col, colIndex) => (
                    <Td
                      key={col._uid}
                      isBold={
                        boldCellsCollection[`${colIndex}${rowIndex}`] || ""
                      }
                      color={
                        colorCellsCollection[`${colIndex}${rowIndex}`] || ""
                      }
                      align={
                        alignCellsCollection[`${colIndex}${rowIndex}`] || ""
                      }
                      width={sizeCollection[colIndex] || ""}
                      data-qa-label={`${colIndex}${rowIndex}-${qaLabel}TableBodyItem`}
                    >
                      {linkCellsCollection[`${colIndex}${rowIndex}`]
                        ? renderLink(
                            Parser(
                              col.value.replace(/(?:\r\n|\r|\n)/g, "<br />")
                            ),
                            linkCellsCollection[`${colIndex}${rowIndex}`]
                          )
                        : Parser(
                            col.value.replace(/(?:\r\n|\r|\n)/g, "<br />")
                          )}
                    </Td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </TableWrapper>
      </ScrollWrapper>
      {footerNote && (
        <FooterNote data-qa-label={`${qaLabel}FooterNoteWrapper`}>
          <RichText
            content={{ markdown: footerNote }}
            qaLabel={`${qaLabel}FooterNote`}
          />
        </FooterNote>
      )}
    </TableContentWrapper>
  );
};

TableContent.defaultProps = {
  content: {
    title: "",
    description: "",
    tableContent: [],
    boldCells: "",
    colorCells: "",
    alignColumns: "",
    sizeColumns: "",
    linkCells: {},
    footerNote: "",
    fontSize: "12px",
    borderLess: false,
    paddingLess: false,
    isWidget: false
  },
  qaLabel: "tableContent"
};

export default TableContent;
