import styled from "styled-components";
import { buildColor } from "@tvg/design-system";

export const ProcessingContainer = styled.div`
  height: 100vh;
`;

export const ReferPageContainer = styled.div`
  width: 100%;
  background-color: #102337;
  margin: 0 auto;
  z-index: 0;

  @media only screen and (hover: none) and (pointer: coarse) and (min-width: 1024px) {
    height: 100%;
    min-height: 100vh;
    padding-bottom: 40px;
  }
`;

export const ReferCodeInput = styled.input`
  position: absolute;
  opacity: 0;
  top: 0;

  @media only screen and (hover: none) and (pointer: coarse) {
    -webkit-touch-callout: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${buildColor("blue", "000")};

  @media only screen and (min-width: 768px) {
    padding: 12px;
    flex-direction: row;
  }

  @media only screen and (hover: none) and (pointer: coarse) and (min-width: 1024px) {
    border-radius: 4px 4px 0 0;
    max-width: 800px;
    margin: 0 auto;
  }
`;

export const FlexContentWrapper = styled.div`
  margin-bottom: 12px;

  & > div {
    width: 100%;
    border-radius: 0;
  }

  @media only screen and (min-width: 768px) {
    margin-bottom: 0;

    & > div {
      border-radius: 4px;
    }

    &:first-of-type {
      flex: 1;

      &:not(:only-of-type) {
        margin-right: 12px;
      }

      & > div {
        box-shadow: inset 0 -1px 0 ${buildColor("blue", "100")};
      }
    }

    &:last-of-type:not(:only-of-type) {
      width: 325px;

      & > div {
        box-shadow: inset 0 -1px 0 ${buildColor("blue", "100")};
      }
    }
  }
`;

export const LegalContentWrapper = styled.div`
  & > div {
    padding: 12px 12px 32px 12px;
  }

  @media only screen and (min-width: 768px) {
    & > div {
      padding: 32px 20px 40px;
    }
  }

  @media only screen and (hover: none) and (pointer: coarse) and (min-width: 1024px) {
    max-width: 800px;
    margin: 0 auto;

    & > div {
      border-radius: 0 0 4px 4px;
    }
  }
`;
