import React, { Fragment, useState, useEffect } from "react";
import withRouter from "@tvg/utils/withCustomRouter";
import moment from "moment";
import { get } from "lodash";

import Title from "@tvg/atomic-ui/_atom/Title";
import MTP from "@tvg/atomic-ui/_atom/MTP";
import TabGroup from "@tvg/atomic-ui/_molecule/TabGroup";
import Spinner from "@tvg/atomic-ui/_static/Spinners";
import buildRaceUrl from "@tvg/formatter/url";

import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import { chevronRight, cup, info } from "../../_static/Icons/icons";
import InternalLink from "../../_atoms/InternalLink";
import Paginator from "../../_molecules/Paginator";
import { filterComingUpRaces, filterPastRaces } from "./utils";
import {
  EligibleRacesContainer,
  Processing,
  EligibleRacesTitle,
  Races,
  Race,
  NoRaces,
  NoRace,
  RaceNoLink,
  IconBackground,
  WarningText
} from "./styled-components";

const EligibleRaces = ({
  location,
  content,
  comingUpRaces,
  comingUpRacesLoading,
  allRaces,
  allRacesLoading,
  isMTPNewRules,
  qaLabel
}) => {
  const { title, resultsPerPage } = content;

  const [activeTab, setActiveTab] = useState(0);
  const [hasPastRaces, setHasPastRaces] = useState(false);
  const [races, setRaces] = useState(
    filterComingUpRaces(comingUpRaces, allRaces, +resultsPerPage, 1)
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [showPrevButton, setShowPrevButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(
    resultsPerPage < races.count
  );

  const tabs = [
    {
      title: "Coming Up",
      isTitleUppercase: false,
      isActive: activeTab === 0,
      onClick: () => {
        setActiveTab(0);
        setCurrentPage(1);
      }
    },
    {
      title: "Past Races",
      isTitleUppercase: false,
      isActive: activeTab === 1,
      onClick: () => {
        setActiveTab(1);
        setCurrentPage(1);
      }
    }
  ];

  useEffect(() => {
    if (activeTab === 0) {
      setRaces(
        filterComingUpRaces(
          comingUpRaces,
          allRaces,
          +resultsPerPage,
          currentPage
        )
      );
    } else if (activeTab === 1) {
      setRaces(
        filterPastRaces(comingUpRaces, allRaces, +resultsPerPage, currentPage)
      );
    }
    setHasPastRaces(
      !!comingUpRaces.filter((race) => race.status.code === "RO").length ||
        !!allRaces.filter((race) => moment().isAfter(race.raceDate)).length
    );
  }, [activeTab, comingUpRaces, allRaces]);

  const drawRace = (race) => (
    <Fragment>
      <div>
        {race.trackName}
        {race.number === "all" ? " - All Races" : ` R${race.number}`}
      </div>
      <div>
        {race.postTime ? (
          <MTP
            isPlainMTP
            mtp={race.mtp}
            postTime={new Date(race.postTime)}
            status={race.status.code}
            paddingless
            isMTPNewRules={isMTPNewRules}
          />
        ) : (
          <time className="race-date">{race.raceDate}</time>
        )}
      </div>
    </Fragment>
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);

    if (activeTab === 0) {
      setRaces(
        filterComingUpRaces(comingUpRaces, allRaces, +resultsPerPage, page)
      );
    } else if (activeTab === 1) {
      setRaces(filterPastRaces(comingUpRaces, allRaces, +resultsPerPage, page));
    }
  };

  useEffect(() => {
    setShowPrevButton(currentPage > 1);
    setShowNextButton(currentPage * resultsPerPage < races.count);
  }, [currentPage, races.count]);

  return (
    <EligibleRacesContainer data-qa-label={qaLabel}>
      {(comingUpRaces || []).length > 0 || (allRaces || []).length > 0 ? (
        <Fragment>
          <EligibleRacesTitle data-qa-label={`${qaLabel}WrapperTitle`}>
            <Title
              tag="h2"
              normal
              fontSize={16}
              color={buildColor("grey", "900")}
              data-qa-label={`${qaLabel}Title`}
            >
              {title}
            </Title>
          </EligibleRacesTitle>
          <TabGroup tabs={hasPastRaces ? tabs : tabs.slice(0, 1)} />
          <Races data-qa-label={`${qaLabel}List`}>
            {(races.races || []).map((race, index) => (
              <Race
                data-qa-label={`${race.trackName}-R${race.number}`}
                key={`${race.trackName}-R${race.number}-${index.toString()}`}
              >
                {!get(race, "postTime", false) ? (
                  <RaceNoLink data-qa-label={`${qaLabel}RaceNoLink`}>
                    {drawRace(race)}
                  </RaceNoLink>
                ) : (
                  <InternalLink
                    pathName={buildRaceUrl(
                      race.trackCode,
                      race.trackName,
                      race.number === "all" ? 1 : +race.number
                    )}
                    linkType="url"
                    target="_self"
                    location={location}
                    data-qa-label={`${qaLabel}RaceLink`}
                  >
                    {drawRace(race)}
                    <Icon
                      size={16}
                      icon={chevronRight}
                      qaLabel={`${qaLabel}RaceIcon`}
                    />
                  </InternalLink>
                )}
              </Race>
            ))}
            {races.count === 0 && (
              <NoRace data-qa-label={`${qaLabel}NoRacesAvailable`}>
                No races available
              </NoRace>
            )}
          </Races>
          {(showPrevButton || showNextButton) && (
            <Paginator
              showPrevButton={showPrevButton}
              showNextButton={showNextButton}
              handleClick={handlePageChange}
              data-qa-label={`${qaLabel}RacePagination`}
            />
          )}
        </Fragment>
      ) : (
        <Fragment>
          {(comingUpRacesLoading || allRacesLoading) && (
            <Processing data-qa-label={`${qaLabel}Processing`}>
              <Spinner data-qa-label={`${qaLabel}Spinner`} />
              <div data-qa-label={`${qaLabel}LoadingText`}>Loading...</div>
            </Processing>
          )}
          {(comingUpRacesLoading &&
            allRacesLoading &&
            (comingUpRaces || []).length === 0) ||
            ((allRaces || []).length === 0 && (
              <NoRaces data-qa-label={`${qaLabel}NoRaces`}>
                <IconBackground data-qa-label={`${qaLabel}IconBackground`}>
                  <Icon size={16} icon={cup} view="0 0 16 16" />
                  <Icon size={13} icon={info} view="0 0 16 16" />
                </IconBackground>
                <WarningText data-qa-label={`${qaLabel}WarningTextWrapper`}>
                  <p
                    style={{ fontWeight: "bold" }}
                    data-qa-label={`${qaLabel}WarningTextBold`}
                  >
                    No races added
                  </p>
                  <p data-qa-label={`${qaLabel}WarningText`}>
                    Come back later for more details on eligible races
                  </p>
                </WarningText>
              </NoRaces>
            ))}
        </Fragment>
      )}
    </EligibleRacesContainer>
  );
};

EligibleRaces.defaultProps = {
  content: {
    title: "",
    resultsPerPage: 0
  },
  comingUpRaces: [],
  comingUpRacesLoading: false,
  allRaces: [],
  allRacesLoading: false,
  isMTPNewRules: false,
  qaLabel: "eligibleRaces"
};

export default withRouter(EligibleRaces);
