import styled, { css } from "styled-components";
import { buildColor } from "@tvg/design-system";

export const RefereeRowContainer = styled.div<{
  isClickable?: boolean;
}>`
  display: grid;
  align-items: center;
  min-height: 55px;
  grid-template-columns: 129px auto ${({ isClickable }) =>
      isClickable ? "44px" : "12px"};
  background-color: ${buildColor("white", "900")};
  ${({ isClickable }) =>
    isClickable &&
    css`
      cursor: pointer;
    `}

  &:not(:last-of-type) {
    box-shadow: inset 0 -1px 0 ${buildColor("blue", "100")};
  }

  @media only screen and (hover: hover) and (pointer: fine) and (min-width: 1024px) {
    &:last-of-type {
      box-shadow: inset 0 -1px 0 ${buildColor("blue", "100")};
    }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
