import React, { PureComponent } from "react";
import Icon from "../../_static/Icons";
import { optedInTag } from "../../_static/Icons/icons";
import { TagContainer, TagLabel } from "./styled-components";

export default class OptedTag extends PureComponent {
  static defaultProps = {
    qaLabel: "optedTag"
  };

  render() {
    const { qaLabel } = this.props;
    return (
      <TagContainer className="cardOptedInTag" data-qa-label={qaLabel}>
        <Icon icon={optedInTag} size={16} />
        <TagLabel data-qa-label={`${qaLabel}TagLabel`}>Opted-in</TagLabel>
      </TagContainer>
    );
  }
}
