import { useState, useEffect } from "react";
import { AxiosResponse } from "axios";
import promoService from "@tvg/api/pro";
import {
  ReferralStatus,
  ReferralStatusResponse
} from "@tvg/ts-types/ReferFriend";

export interface Props {
  accountNumber: string;
  enableFetchService: boolean;
}

interface ReferralStatusInterface {
  referralStatus: ReferralStatus[];
  totalBonusAmount: number;
  totalCompletedReferrals: number;
}

const useReferralStatus = (props: Props) => {
  const { accountNumber, enableFetchService } = props;
  const [isLoading, setLoading] = useState(true);
  const [isServiceError, setServiceError] = useState(false);
  const defaultReferralStatus: ReferralStatusInterface = {
    referralStatus: [],
    totalBonusAmount: 0,
    totalCompletedReferrals: 0
  };
  const [referralStatusRes, setReferralStatusRes] = useState(
    defaultReferralStatus
  );

  let mounted = true;

  const fetchReferralStatus = () => {
    setLoading(true);
    promoService
      .fetchReferralStatus(accountNumber)
      .then((resp: AxiosResponse<ReferralStatusResponse>) => {
        if (mounted) {
          setServiceError(false);
          const {
            refereeReferralStatusList,
            totalBonusAmount,
            totalCompletedReferrals
          } = resp.data;

          setReferralStatusRes({
            referralStatus: refereeReferralStatusList,
            totalBonusAmount,
            totalCompletedReferrals
          });
        }
      })
      .catch(() => {
        setServiceError(true);
        setReferralStatusRes(defaultReferralStatus);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (enableFetchService && accountNumber) {
      fetchReferralStatus();
    }
    return () => {
      mounted = false;
    };
  }, [accountNumber, enableFetchService]);

  const { referralStatus, totalBonusAmount, totalCompletedReferrals } =
    referralStatusRes;
  return {
    isLoading,
    isServiceError,
    referralStatus,
    totalBonusAmount,
    totalCompletedReferrals
  };
};

export default useReferralStatus;
