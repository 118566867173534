import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";

const socialStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 48px;
  height: 48px;
  background-color: ${buildColor("blue", "700")};
  border-radius: 24px;
  margin-bottom: 8px;
  border: none;
  outline: none;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: ${buildColor("blue", "500")};
    }
  }

  &:active {
    background: ${buildColor("blue", "400")};
  }
`;

export const Button = styled.button`
  ${socialStyle};
`;

export const Link = styled.a`
  ${socialStyle};
`;
