import styled, { css } from "styled-components/native";
import { rgba } from "polished";

import {
  color,
  background,
  space,
  borderRadius,
  width,
  compose
} from "../../../../theming/styledSystem";
import { Theme } from "../../../../theming/types";

const styleFns = compose(color, background, borderRadius, width);

export const Container = styled.View`
  display: flex;
`;

export const ContentContainer = styled.View<{ layer: number; width: string }>`
  position: absolute;
  z-index: ${({ layer }) => layer};
  display: flex;
  flex-direction: column;
  ${space}
  ${styleFns}
`;

export const Dropdown = styled.View<{ isDarkMode: boolean; theme: Theme }>`
  border-width: 1px;
  ${({ theme, isDarkMode }) => css`
    background-color: ${isDarkMode
      ? theme.colors.blue["900"]
      : theme.colors.white["900"]};
    border-color: ${theme.colors.blue["100"]};
    border-radius: ${theme.radii.s};
    filter: drop-shadow(0 1px 3px ${rgba(theme.colors.blue[900], 0.14)});
    will-change: filter;
  `}
  ${styleFns}
`;
