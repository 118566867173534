import React, { PureComponent } from "react";
import buildColor from "../../_static/ColorPalette";

import BorderLine from "./styled-components";

export default class BorderBottom extends PureComponent {
  static defaultProps = {
    content: {
      color: buildColor("blue", "000")
    },
    qaLabel: "borderBottom"
  };

  render() {
    const { content, qaLabel } = this.props;
    return <BorderLine data-qa-label={qaLabel} color={content.color} />;
  }
}
