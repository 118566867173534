import { EnvironmentConfig, EventType, Event } from "../types";

export const CLIENT_AUTH_TOKEN = "a301e728606047a5b6522727d495b3ed";

const webConfig: EnvironmentConfig = {
  [EventType.Trigger]: {
    [Event.Login]: {
      eventName: "LOGIN_FLOW_COMPLETE",
      exceptionEventName: "LOGIN_CANCELLED"
    },
    [Event.Join]: {
      eventName: "LOGIN_FLOW_COMPLETE",
      exceptionEventName: "LOGIN_CANCELLED"
    },
    [Event.Logout]: {
      eventName: "LOGOUT_FLOW_COMPLETE"
    },
    [Event.OnRefreshSession]: {
      eventName: "REFRESH_SESSION_COMPLETED",
      exceptionEventName: "ON_REFRESH_SESSION_CANCELLED"
    },
    [Event.AccountSummary]: {
      eventName: "FOOTER_CLICK_ACCOUNT"
    },
    [Event.Deposit]: {
      eventName: "OPEN_AW_PAGE"
    }
  },
  [EventType.Subscribe]: {
    [Event.RefreshSession]: {
      eventName: "REFRESH_SESSION_COMPLETED"
    },
    [Event.LoginComplete]: {
      eventName: "LOGIN_FLOW_COMPLETE"
    },
    [Event.LoginCancelled]: {
      eventName: "LOGIN_CANCELLED"
    },
    [Event.LogoutComplete]: {
      eventName: "LOGOUT_FLOW_COMPLETE"
    },
    [Event.OpenAwPage]: {
      eventName: "OPEN_AW_PAGE"
    },
    [Event.AuthDetailsUpdated]: {
      eventName: "UPDATE_SESSION"
    },
    [Event.AccountAndWalletClosed]: {
      eventName: "CLOSED_AW_MODAL"
    }
  }
};

export default webConfig;
