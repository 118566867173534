import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";

export const ImagesWrapper = styled.div`
  background-color: ${buildColor("white", "900")};

  @media only screen and (width >= 768px) and (width <= 1023px) {
    padding: 24px 24px 0;
  }

  @media only screen and (width >= 1024px) {
    padding: 12px 12px 0;
  }
`;

export const ImagesContainer = styled.div`
  &.grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    & > div {
      display: inline-flex;
      width: calc(50% - 6px);
      margin-bottom: 12px;
    }
  }

  &.col-1,
  &.col-3,
  &.col-5 {
    & > div:last-child {
      flex-basis: 100%;
    }
  }

  @media only screen and (width <= 767px) {
    &.grid {
      flex-direction: column;

      & > div {
        width: 100%;
      }
    }
  }
`;

export const ImageWrapper = styled.div``;

const smallImageStyling = css`
  @media only screen and (width >= 1024px) {
    height: 178px;
  }

  @media only screen and (width >= 768px) and (width <= 1023px) {
    height: 210px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  ${({ largeImage }) => (largeImage ? null : smallImageStyling)}
`;

export const Caption = styled.div`
  padding-bottom: 12px;

  @media only screen and (width <= 767px) {
    padding-left: 12px;
    padding-right: 12px;
  }
`;
