import React, { useEffect, useState } from "react";

import { TableRowContainer, TableCell } from "./styled-components";

export const validateValue = (valueToValidate) => {
  const result = valueToValidate !== undefined && valueToValidate !== "";
  return result;
};

export const useContent = (content) => {
  const [textArrays, setTextArrays] = useState([]);
  const [textColorArrays, setTextColorArrays] = useState([]);

  useEffect(() => {
    const tempTextArrays = [];
    const tempTextColorArrays = [];
    const moreThanOneColor = validateValue(content.secondCellTextColor);

    tempTextArrays.push(content.firstCellText);
    tempTextArrays.push(content.secondCellText);
    tempTextColorArrays.push(content.firstCellTextColor);

    if (moreThanOneColor) {
      tempTextColorArrays.push(content.secondCellText);
    }

    if (validateValue(content.thirdCellText)) {
      tempTextArrays.push(content.thirdCellText);

      if (moreThanOneColor && validateValue(content.thirdCellTextColor)) {
        tempTextColorArrays.push(content.thirdCellTextColor);
      }

      if (validateValue(content.fourthCellText)) {
        tempTextArrays.push(content.fourthCellText);

        if (moreThanOneColor && validateValue(content.fourthCellTextColor)) {
          tempTextColorArrays.push(content.fourthCellTextColor);
        }
      }
    }

    setTextArrays(tempTextArrays);
    setTextColorArrays(tempTextColorArrays);
  }, [content]);

  return [textArrays, textColorArrays];
};

const TableRow = ({ content, qaLabel }) => {
  const [textArrays, textColorArrays] = useContent(content);

  return (
    <TableRowContainer
      isUppercase={content.isUppercase}
      length={
        textArrays.length === 2 && textArrays[1] === "" ? 1 : textArrays.length
      }
      data-qa-label={qaLabel}
    >
      {textArrays
        .filter((cell) => cell !== "")
        .map((cellText, index, array) => (
          <TableCell
            length={array.length}
            index={index}
            isBold={content.isBold}
            color={
              textColorArrays[index] === undefined
                ? textColorArrays[0]
                : textColorArrays[index]
            }
            key={cellText}
            data-qa-label={`${index}TableCell`}
          >
            {cellText}
          </TableCell>
        ))}
    </TableRowContainer>
  );
};

TableRow.defaultProps = {
  content: {
    firstCellText: "",
    secondCellText: "",
    thirdCellText: "",
    fourthCellText: "",
    firstCellTextColor: "",
    secondCellTextColor: "",
    thirdCellTextColor: "",
    fourthCellTextColor: "",
    isUppercase: false,
    isBold: false
  },
  qaLabel: "tableRow"
};

export default TableRow;
