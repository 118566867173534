import React, { PureComponent } from "react";
import { noop } from "lodash";
import { ButtonComp } from "./styled-components";
import { getSize, getType } from "./styles";
import CircularLoader from "../../_static/CircularLoader";

export default class Button extends PureComponent {
  static defaultProps = {
    children: null,
    className: "",
    onClick: noop,
    size: "default",
    type: "primary",
    isDisabled: false,
    isSelected: false,
    hasRoundedCorners: true,
    isStretched: false,
    isUppercase: true,
    textTransform: "normal",
    isBold: true,
    hasShadow: false,
    qaLabel: "",
    fontFamily: "inherit",
    isLoading: false,
    loadingBorderColor: "",
    loadingBackgroundColor: "",
    optionalData: null,
    customMarginBottom: 0
  };

  render() {
    const sizeSpecs = getSize(this.props.size);
    const typeSpecs = getType(this.props.type);

    const compProps = {
      $sizeSpecs: sizeSpecs,
      $typeSpecs: typeSpecs,
      isSelected: this.props.isSelected,
      $isStretched: this.props.isStretched,
      isUppercase: this.props.isUppercase,
      textTransform: this.props.textTransform,
      isBold: this.props.isBold,
      $hasShadow: this.props.hasShadow,
      $isDisabled: this.props.isDisabled,
      $hasRoundedCorners: this.props.hasRoundedCorners,
      ...(this.props.qaLabel !== ""
        ? {
            qaLabel: this.props.qaLabel
          }
        : {}),
      fontFamily: this.props.fontFamily,
      isLoading: this.props.isLoading,
      optionalData: this.props.optionalData
    };

    return (
      <ButtonComp
        {...compProps}
        className={this.props.className}
        isDisabled={this.props.isDisabled}
        customMarginBottom={this.props.customMarginBottom}
        onClick={this.props.onClick}
      >
        {this.props.isLoading ? (
          <span>
            <CircularLoader
              borderColor={this.props.loadingBorderColor}
              backgroundColor={this.props.loadingBackgroundColor}
            />
          </span>
        ) : (
          <span
            {...(compProps.optionalData
              ? { "data-optional": compProps.optionalData }
              : {})}
          >
            {this.props.children}
          </span>
        )}
      </ButtonComp>
    );
  }
}
