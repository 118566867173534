import styled from "styled-components";
import {
  color,
  background,
  space,
  borderRadius,
  width,
  compose,
  variant
} from "../../../src/theming/styledSystem";
import type { TooltipDescriptiveWrapperProps } from "./types";

const OFFSET_SPACE: string = "12px";

const styles = compose(
  color,
  background,
  space,
  borderRadius,
  width,
  variant({
    prop: "placement",
    variants: {
      left: { marginRight: OFFSET_SPACE },
      right: { marginLeft: OFFSET_SPACE },
      "top-mid": { marginBottom: OFFSET_SPACE },
      "top-left": { marginBottom: OFFSET_SPACE },
      "top-right": { marginBottom: OFFSET_SPACE },
      "bottom-mid": { marginTop: OFFSET_SPACE },
      "bottom-left": { marginTop: OFFSET_SPACE },
      "bottom-right": { marginTop: OFFSET_SPACE }
    }
  })
);

export const Wrapper = styled.div<TooltipDescriptiveWrapperProps>`
  box-shadow: 0 2px 4px 0 rgba(10 10 10 / 10%);
  margin: 4px;
  border-radius: 4px;
  ${styles};

  @media screen and (width <= 360px) {
    margin-right: 48px;
  }
`;
