import styled from "styled-components";
import buildColor from "../../_static/ColorPalette";

const PromosIconContainer = styled.div`
  width: 104px;
  height: 104px;
  background-color: ${buildColor("blue", "000")};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;

  ::after {
    position: absolute;
    width: 70px;
    height: 70px;
    background-color: ${buildColor("blue", "100")};
    border-radius: 50%;
    content: "";
  }

  svg {
    color: ${buildColor("blue", "400")};
    z-index: 1;
    font-size: 34px;
  }

  @media only screen and (width <= 599px) {
    margin-left: 0;
    height: 72px;
    width: 72px;

    ::after {
      width: 48px;
      height: 48px;
    }

    svg {
      font-size: 24px;
    }
  }
`;

export default PromosIconContainer;
