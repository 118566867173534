import styled, { css } from "styled-components";
import { buildColor } from "@tvg/design-system";

export const HeaderContainer = styled.div<{
  addExtraPadding?: boolean;
}>`
  background-color: ${buildColor("blue_accent", "000")};
  box-shadow: inset 0 -1px 0 ${buildColor("blue", "100")};
  display: flex;
  text-transform: uppercase;
  justify-content: space-around;
  border-radius: 4px 4px 0 0;
  padding: 8px 12px 8px 129px;
  ${({ addExtraPadding }) =>
    addExtraPadding &&
    css`
      padding-right: 44px;
    `}
`;
