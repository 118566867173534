import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontBold, fontNormal } from "../../_static/Typography";

const titleStyling = css`
  margin: 12px 0;
  font-family: ${fontBold};
  color: ${buildColor("grey", "900")};
`;

const BodyText = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: ${buildColor("grey", "800")};
  font-family: ${fontNormal};
  background-color: ${buildColor("white", "900")};

  h2 {
    font-size: 18px;
    line-height: 24px;
    ${titleStyling}
  }

  h3 {
    font-size: 16px;
    line-height: 22px;
    ${titleStyling}
  }

  h4 {
    font-size: 14px;
    line-height: 22px;
    ${titleStyling}
  }

  > p,
  span {
    margin-bottom: 12px;
    font-family: ${fontNormal};

    &:last-child {
      margin-bottom: 0;
    }
  }

  > ul {
    list-style: none;

    > li {
      width: 100%;
      display: inline-block;
      text-indent: -0.5em;
      padding-left: 1em;

      p {
        display: inline;
        font-family: ${fontNormal};
      }

      ul {
        list-style: none;

        li {
          text-indent: -0.5em;
          margin-left: 1.5em;

          li::before {
            content: "\\25AA";
            color: ${buildColor("blue_accent", "400")};
          }
        }

        li::before {
          content: "\\25E6";
          color: ${buildColor("blue_accent", "400")};
        }
      }
    }

    li::before {
      content: "\\2022";
      color: ${buildColor("blue_accent", "400")};
      display: inline-block;
      margin-right: 0.5em;
    }
  }

  > ol {
    list-style: none;
    counter-reset: li;

    > li {
      display: flex;
      flex-direction: row;
      counter-increment: li;

      p {
        display: inline-block;
        font-family: ${fontNormal};
      }
    }

    > li::before {
      content: "." counter(li);
      color: ${buildColor("blue_accent", "400")};
      display: inline-block;
      width: 1em;
      margin: 0 0.5em;
      text-align: right;
      direction: rtl;
    }
  }

  a {
    font-family: ${fontNormal};
    color: ${buildColor("blue_accent", "500")};
    text-decoration: none;
    display: inline-block;
    vertical-align: baseline;

    &.betTVG,
    &.marketingTVG {
      display: inline-flex;
    }
  }

  blockquote {
    margin: 1.5em 10px;
    padding: 0.5em 10px;

    p {
      font-family: ${fontNormal};
    }
  }

  u > span {
    vertical-align: bottom;
  }
`;

export default BodyText;
