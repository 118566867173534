export enum GeolocationStatus {
  PROGRESS = "LOCATION_IN_PROGRESS",
  FAILURE = "LOCATION_FAILURE",
  PASSED = "GEOLOCATION_PASSED",
  REJECTED = "GEOLOCATION_REJECTED"
}

export interface GeolocationRejectedErrorMessage {
  retry: boolean;
  title: string;
  message: string;
  rule: string;
}

export interface GeolocationRejectedError {
  message: string;
  region: string;
  troubleshooters: GeolocationRejectedErrorMessage[];
}

export interface GeolocationFailureError {
  geoErrorName: string;
  geoErrorCode: number;
}

export interface GeolocationData {
  geoToken?: string;
  ipAddress: string;
}

export interface GeolocationFailureErrorName {
  message: string;
}

export enum GeolocationRetryState {
  IDLE = "idle",
  REQUESTED = "requested",
  RETRYING = "retrying"
}

export interface GeolocationRetryStatus {
  state: GeolocationRetryState;
  attempts: number;
}

export type GeolocationError = Record<
  string,
  Array<GeolocationRejectedErrorMessage | GeolocationFailureErrorName>
>;

export type GeolocationRejectedErrorModalTitle = Record<string, string>;
