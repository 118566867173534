import React, { PureComponent } from "react";
import Parser from "html-react-parser";

import { noop } from "lodash";
import BodyText from "./styled-components";
import { RenderRichText } from "../../_static/richTextEditorResolver";

class ContentText extends PureComponent {
  static defaultProps = {
    content: {
      qaLabel: "",
      className: undefined,
      fontSize: undefined,
      lineHeight: undefined
    },
    qaLabel: "contentText"
  };

  render() {
    const { content, qaLabel } = this.props;

    const listOfContents = [];
    if (typeof content.markdown !== "string") {
      content.markdown.content.forEach((blok) => listOfContents.push(blok));
    } else {
      return (
        <BodyText data-qa-label={qaLabel}>{Parser(content.markdown)}</BodyText>
      );
    }

    return (
      <BodyText data-qa-label={qaLabel}>
        {listOfContents.map((blok) => RenderRichText(blok))}
      </BodyText>
    );
  }
}

export default ContentText;
