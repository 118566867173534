import styled from "styled-components";
import { fontBold, fontNormal } from "../Typography";
import buildColor from "../ColorPalette";

const LegalsContainer = styled.div`
  padding: 24px 12px;
  font-family: ${fontNormal};
  font-size: 12px;
  line-height: 14px;
  color: ${buildColor("grey", "600")};
  background-color: ${buildColor("white", "100")};
  margin: 0 auto;

  strong,
  b {
    font-family: ${fontBold};
  }

  div,
  p,
  span {
    font-family: ${fontNormal};
  }

  a {
    font-family: ${fontNormal};
    color: ${buildColor("blue", "500")};
    display: inline-block;
    vertical-align: baseline;
  }

  @media only screen and (min-width: 768px) {
    padding: ${({ isModule }) => (isModule ? null : "32px 20px 20px")};

    &.legalsTopOffset {
      padding: 0 0 24px;
    }
  }

  @media only screen and (max-width: 767px) {
    padding: 20px 12px;

    &.legalsTopOffset {
      padding: 0 0 24px;
    }
  }
`;

export default LegalsContainer;
