import React, { PureComponent } from "react";

import { noop } from "lodash";
import buildColor from "../../_static/ColorPalette";
import { TabAtom, PillAtom, Text } from "./styled-components";

export default class Tab extends PureComponent {
  static defaultProps = {
    title: "",
    subTitle: "",
    isSubtitleHighlighted: false,
    counter: 0,
    displayZero: false,
    isActive: false,
    isDisabled: false,
    onClick: noop,
    isTitleUppercase: true,
    type: "TAB",
    className: "",
    qaLabel: "tab"
  };

  render() {
    let textColor;

    if (this.props.isDisabled && this.props.type === "TAB") {
      textColor = buildColor("grey", "500");
    } else {
      textColor = this.props.isActive
        ? buildColor("blue_accent", "500")
        : buildColor("grey", "900");
    }

    const Element = this.props.type === "TAB" ? TabAtom : PillAtom;

    return (
      <Element
        className={
          this.props.isActive
            ? `${this.props.className} active`.trim()
            : this.props.className
        }
        counter={this.props.counter}
        isActive={this.props.isActive}
        displayZero={this.props.displayZero}
        onClick={this.props.isDisabled ? noop : this.props.onClick}
        qaLabel={this.props.qaLabel}
        data-testid="tabItem"
      >
        <Text color={textColor} isTitleUppercase={this.props.isTitleUppercase}>
          {this.props.title}
        </Text>
        {this.props.subTitle ? this.props.subTitle : undefined}
      </Element>
    );
  }
}
