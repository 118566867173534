export const setPromosList = (promosList) => ({
  type: "PROMOS_LIST_SET",
  payload: promosList
});

export const setBetaOptin = (isBeta) => ({
  type: "SET_BETA_OPTIN",
  payload: isBeta
});

export const setEmailReferralOpen = (isOpen) => ({
  type: "SET_EMAIL_REFERRAL_OPEN",
  payload: isOpen
});
