class MediatorNoop {
  handlers;

  constructor() {
    this.handlers = {};
  }

  subscribe(type, handler) {
    return () => this.unsubscribe(type, handler);
  }

  subscribeTimes(times, type, handler) {
    return () => this.unsubscribe(type, handler);
  }

  subscribeWithPast(type, handler) {
    return () => this.unsubscribe(type, handler);
  }

  unsubscribe(
    type,
    handler // eslint-disable-line no-unused-vars
  ) {
    return this;
  }

  // eslint-disable-next-line no-unused-vars
  dispatch(type, value) {
    return this;
  }

  query(type) {
    const handler = this.handlers[type];
    return handler && handler.lastValue ? handler.lastValue : undefined;
  }
}

export default MediatorNoop;
