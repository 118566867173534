import styled from "styled-components";
import { fontNormal } from "@tvg/atomic-ui/_static/Typography";
import { fontBold } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const Container = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const IconWrapper = styled.div`
  width: 88px;
  height: 88px;
  border-radius: 50%;
  background-color: ${buildColor("red", "000")};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;

export const Title = styled.h2`
  color: ${buildColor("grey", "900")};
  font-size: 16px;
  font-family: ${fontBold};
  font-weight: bold;
  letter-spacing: 0;
  margin-bottom: 8px;
`;

export const Description = styled.p`
  color: ${buildColor("grey", "800")};
  font-size: 14px;
  font-family: ${fontNormal};
  font-weight: normal;
  letter-spacing: 0;
  margin-bottom: 24px;
`;
