import * as React from "react";
import { Button } from "./styled-components";

export default class SocialButton extends React.PureComponent {
  static defaultProps = {
    qaLabel: "socialButton"
  };

  render() {
    return (
      <React.Fragment>
        <Button
          data-qa-label={this.props.qaLabel}
          id={`refer-${this.props.id}`}
          onClick={(event) => this.props.onClick(event)}
        >
          {this.props.children}
        </Button>
      </React.Fragment>
    );
  }
}
