import React from "react";

const DEFAULT_OPTIN = {
  isOpted: false,
  optedIn: false,
  error: "",
  handleOptinClick: () => {},
  sentOptinEvents: false
};

const optinContext = React.createContext(DEFAULT_OPTIN);

export default optinContext;
