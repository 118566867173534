import React from "react";
import Svg, { Path } from "react-native-svg";
import { get } from "lodash";
import { useTheme } from "../../theming";
import { useQaLabel } from "../../hooks/useQaLabel";
import {
  BagdeContainer,
  StyledComponent,
  IconContainer
} from "./styled-components";
import { IconWithBackgroundProps } from "./types";
import { Icon } from "../icon";

const DefaultbadgeIcon = {
  lineColor: "blue_accent.600",
  backgroundColor: "blue_accent.200"
};

const DefaultMainIcon = {
  lineColor: "grey.800",
  backgroundColor: "white.900"
};

export const IconWithBackground = ({
  qaLabel = "icon-with-background",
  mainCircleBackground = "white.900",
  mainIcon,
  badgeIcon
}: IconWithBackgroundProps) => {
  const viewTestProps = useQaLabel(qaLabel);
  const { colors } = useTheme();

  return (
    <StyledComponent {...viewTestProps}>
      <Svg width="63" height="62" viewBox="0 0 63 62" fill="none">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d={
            badgeIcon
              ? "M45.6208 3.39549C41.3858 1.22478 36.586 0 31.5 0C14.3792 0 0.5 13.8792 0.5 31C0.5 48.1208 14.3792 62 31.5 62C48.6208 62 62.5 48.1208 62.5 31C62.5 25.914 61.2752 21.1142 59.1045 16.8792C57.7264 17.5953 56.1604 18 54.5 18C48.9772 18 44.5 13.5228 44.5 8C44.5 6.33956 44.9047 4.77364 45.6208 3.39549Z"
              : "M31.5 62C48.6208 62 62.5 48.1208 62.5 31C62.5 13.8792 48.6208 0 31.5 0C14.3792 0 0.5 13.8792 0.5 31C0.5 48.1208 14.3792 62 31.5 62Z"
          }
          fill={get(colors, mainCircleBackground, mainCircleBackground)}
        />
      </Svg>
      <IconContainer>
        <Icon
          name={mainIcon.name}
          size={mainIcon.size || "l"}
          lineColor={mainIcon.lineColor ?? DefaultMainIcon.lineColor}
          backgroundColor={
            mainIcon.backgroundColor ?? DefaultMainIcon.backgroundColor
          }
        />
      </IconContainer>
      {badgeIcon && (
        <BagdeContainer>
          <Icon
            name={badgeIcon.name}
            size="s"
            lineColor={badgeIcon.lineColor ?? DefaultbadgeIcon.lineColor}
            backgroundColor={
              badgeIcon.backgroundColor ?? DefaultbadgeIcon.backgroundColor
            }
          />
        </BagdeContainer>
      )}
    </StyledComponent>
  );
};

export { IconWithBackgroundProps };
export default IconWithBackground;
