import styled from "styled-components";
import { fontBold, fontNormal } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const TableComponentWrapper = styled.div`
  display: flex;
  flex-direction: Column;
  background: white;
  font-family: ${fontNormal};
  max-width: 702px;
  margin-top: 24px;

  > :nth-child(3) {
    font-weight: 500;
  }

  > :last-child {
    box-shadow: none;
  }

  @media only screen and (width <= 499px) {
    width: 100%;
    overflow-x: scroll;
    white-space: nowrap;

    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
  }
`;

export const Title = styled.h2`
  font-family: ${fontBold};
  font-size: 18px;
  color: ${buildColor("grey", "900")};
  margin: 8px 0 16px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const SubTitle = styled.h3`
  font-size: 14px;
  color: ${buildColor("blue", "400")};
  font-weight: normal;
  text-transform: uppercase;
`;
