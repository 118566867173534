import styled, { keyframes } from "styled-components";
import { buildColor } from "../../../../../src";

export const LoadingContainer = styled.div`
  width: 275px;
  height: 200px;
  border-radius: 4px;
  border: 1px solid var(--fd-colors-border-subtle, #eaf0f6);
`;
export const pulseAnimation = keyframes`
  0% {
    fill: ${buildColor("blue", "000")};
  }
  
  50% {
    fill: ${buildColor("blue", "100")};
  }
  
  100% {
    fill: ${buildColor("blue", "000")};
  }
`;

export const SVG = styled.svg`
  rect {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;
