import React from "react";
import { InputEmail, IconButton, InputTextProps } from "@tvg/design-system";
import type { Props } from "./types";

const RefereeInputEmail = ({
  qaLabel = "refereeInputEmail",
  isCloseButtonVisible = false,
  onCloseClick,
  ...rest
}: Props & InputTextProps) => (
  <InputEmail
    {...rest}
    qaLabel={qaLabel}
    keyboardType="email-address"
    end={
      isCloseButtonVisible && onCloseClick ? (
        <IconButton
          size="s"
          qaLabel={`${qaLabel}CloseButton`}
          variant="tertiary"
          iconName="close"
          tertiaryColor="grey.800"
          onPress={() => onCloseClick && onCloseClick()}
        />
      ) : undefined
    }
  />
);

export default RefereeInputEmail;
