import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";

import buildColor from "../ColorPalette";

const spinerwingfade = keyframes`
  0% {
    opacity: 0.85; 
  }
  
  50% {
    opacity: 0.25; 
  }
  
  100% {
    opacity: 0.25; 
  }
`;

const Spinnerwing = styled.div`
  position: absolute;
  left: 44.5%;
  top: 37%;
  width: 10%;
  height: 25%;
  border-radius: 50%/20%;
  background-color: ${(props) => props.color};
  animation: ${spinerwingfade} 1s linear infinite;
  animation-play-state: running;

  &:nth-child(1) {
    animation-delay: -1.6666666667s;
    transform: rotate(30deg) translate(0, -150%);
  }

  &:nth-child(2) {
    animation-delay: -1.5833333333s;
    transform: rotate(60deg) translate(0, -150%);
  }

  &:nth-child(3) {
    animation-delay: -1.5s;
    transform: rotate(90deg) translate(0, -150%);
  }

  &:nth-child(4) {
    animation-delay: -1.4166666667s;
    transform: rotate(120deg) translate(0, -150%);
  }

  &:nth-child(5) {
    animation-delay: -1.3333333333s;
    transform: rotate(150deg) translate(0, -150%);
  }

  &:nth-child(6) {
    animation-delay: -1.25s;
    transform: rotate(180deg) translate(0, -150%);
  }

  &:nth-child(7) {
    animation-delay: -1.1666666667s;
    transform: rotate(210deg) translate(0, -150%);
  }

  &:nth-child(8) {
    animation-delay: -1.0833333333s;
    transform: rotate(240deg) translate(0, -150%);
  }

  &:nth-child(9) {
    animation-delay: -1s;
    transform: rotate(270deg) translate(0, -150%);
  }

  &:nth-child(10) {
    animation-delay: -0.9166666667s;
    transform: rotate(300deg) translate(0, -150%);
  }

  &:nth-child(11) {
    animation-delay: -0.8333333333s;
    transform: rotate(330deg) translate(0, -150%);
  }

  &:nth-child(12) {
    animation-delay: -0.75s;
    transform: rotate(360deg) translate(0, -150%);
  }
`;

const SpinnerElement = styled.div`
  position: relative;
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
`;

export default class Spinner extends PureComponent {
  static defaultProps = {
    width: 24,
    height: 24,
    color: buildColor("black", "100")
  };

  render() {
    return (
      <SpinnerElement
        width={this.props.width}
        height={this.props.height}
        data-qa-label={this.props.qaLabel}
      >
        <Spinnerwing color={this.props.color} />
        <Spinnerwing color={this.props.color} />
        <Spinnerwing color={this.props.color} />
        <Spinnerwing color={this.props.color} />
        <Spinnerwing color={this.props.color} />
        <Spinnerwing color={this.props.color} />
        <Spinnerwing color={this.props.color} />
        <Spinnerwing color={this.props.color} />
        <Spinnerwing color={this.props.color} />
        <Spinnerwing color={this.props.color} />
        <Spinnerwing color={this.props.color} />
        <Spinnerwing color={this.props.color} />
      </SpinnerElement>
    );
  }
}
