import styled from "styled-components";
import { space, layout, compose } from "styled-system";

const styledUtils = compose(space, layout);

export const StyledViewContainer = styled.div`
  ${styledUtils};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: var(--fd-space-space-4);
  width: 100%;
`;
