import { useState, useEffect, useCallback } from "react";
import { uniqueId, debounce } from "lodash";
import { RefereeEmailField } from "@tvg/ts-types/ReferFriend";

// eslint-disable-next-line security/detect-unsafe-regex
const emailValidationRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export const validateFieldValue = (value: string) => {
  const warningMessage =
    value && !emailValidationRegex.test(value) ? "Invalid email address" : "";
  return { warningMessage, isInvalid: !!warningMessage };
};

const useDynamicReferees = () => {
  const [fields, setFields] = useState<RefereeEmailField[]>([]);
  const [isValid, setValid] = useState(false);

  useEffect(() => {
    if (fields.length === 0) {
      appendReferee();
    }
  }, []);

  useEffect(() => {
    const handleValidateAllFields = debounce(() => {
      if (fields.length > 0) {
        const allFieldsValid = fields.every(
          (field) =>
            field.value &&
            typeof field.isInvalid !== "undefined" &&
            !field.isInvalid
        );
        setValid(allFieldsValid);
      }
    }, 100);

    handleValidateAllFields();

    return () => {
      handleValidateAllFields.cancel();
    };
  }, [fields]);

  const appendReferee = useCallback(
    (value?: string) => {
      const updatedFields = [
        ...fields,
        {
          id: uniqueId("refereeField_"),
          value: value || ""
        }
      ];
      setFields(updatedFields);
    },
    [fields]
  );

  const removeReferee = useCallback(
    (index?: number) => {
      const updatedFields = [...fields];
      if (typeof index !== "undefined") {
        updatedFields.splice(index, 1);
      } else {
        updatedFields.splice(-1);
      }
      setFields(updatedFields);
    },
    [fields]
  );

  const updateReferee = useCallback(
    (
      field: RefereeEmailField,
      validate: boolean = false
    ): RefereeEmailField => {
      let validation = {
        isInvalid: field.isInvalid,
        warningMessage: field.warningMessage
      };
      const index = fields.findIndex((item) => item.id === field.id);
      if (validate) {
        validation = validateFieldValue(field.value);
      }
      const newField: RefereeEmailField = { ...field, ...validation };
      const updatedFields = [
        ...fields.slice(0, index),
        newField,
        ...fields.slice(index + 1)
      ];
      setFields(updatedFields);
      return newField;
    },
    [fields]
  );

  return {
    fields,
    appendReferee,
    removeReferee,
    updateReferee,
    isValid
  };
};

export default useDynamicReferees;
