const {
  AMPLITUDE_API_KEYS: AMPLITUDE_API_KEYS_STAGING
} = require("../../utils/urp/amplitude");
const {
  AMPLITUDE_API_KEYS: AMPLITUDE_API_KEYS_FDR_STAGING,
  AMPLITUDE_API_KEYS_XSELL: AMPLITUDE_API_KEYS_FDR_XSELL_STAGING
} = require("../../utils/fdr/amplitude");
const {
  AMPLITUDE_API_KEYS: AMPLITUDE_API_KEYS_MEP_STAGING
} = require("../../utils/mep/amplitude");
const {
  AMPLITUDE_API_KEYS: AMPLITUDE_API_KEYS_TVG4_STAGING
} = require("../../utils/tvg4/amplitude");

module.exports = {
  domain: {
    desktop: "www-staging.tvg.com",
    mobile: "www-staging.tvg.com",
    talentPicks: "www-staging.tvg.com/talent-picks/",
    equibase: "equibase-store.tvg.com"
  },
  externalDomain: {
    support: "https://support.tvg.com",
    contactSupport:
      "https://support.tvg.com/tvg/s/articles/360005946853-Contact-Support",
    NCPGamblingChat: "http://www.ncpgambling.org/chat"
  },
  service_host: "service-staging.tvg.com",
  google: {
    tvg4: {
      gtmId: "KTDSBG",
      gaId: "UA-8156856-11",
      gtmEnv: "668",
      gtmAuth: "Q3mmSetXL-9o6LJDN6Tz1A",
      siteVersion: "desktop",
      productVersion: "TVG4"
    },
    tvg5: {
      gtmId: "KTDSBG",
      gaId: "UA-8156856-11",
      gtmEnv: "668",
      gtmAuth: "Q3mmSetXL-9o6LJDN6Tz1A",
      siteVersion: "desktop",
      productVersion: "TVG5"
    },
    touch2: {
      gtmId: "KTDSBG",
      gaId: "UA-8156856-11",
      gtmEnv: "668",
      gtmAuth: "Q3mmSetXL-9o6LJDN6Tz1A",
      siteVersion: "mobile_web",
      productVersion: "MEP"
    },
    ios: {
      gtmId: "KTDSBG",
      gaId: "UA-8156856-11",
      gtmEnv: "668",
      gtmAuth: "Q3mmSetXL-9o6LJDN6Tz1A",
      siteVersion: "ios_native"
    },
    touch3: {
      gtmId: "KTDSBG",
      gaId: "UA-8156856-11",
      gtmEnv: "668",
      gtmAuth: "Q3mmSetXL-9o6LJDN6Tz1A",
      siteVersion: "mobile_web",
      productVersion: "MEP"
    },
    androidwrapper: {
      gtmId: "KTDSBG",
      gaId: "UA-8156856-2",
      gtmEnv: "668",
      gtmAuth: "Q3mmSetXL-9o6LJDN6Tz1A",
      siteVersion: "android_native",
      productVersion: "android_gps"
    },
    tvgandroid: {
      gtmId: "KTDSBG",
      gaId: "UA-8156856-2",
      gtmEnv: "668",
      gtmAuth: "Q3mmSetXL-9o6LJDN6Tz1A",
      siteVersion: "android_native",
      productVersion: "android_apk"
    },
    ios2: {
      gtmId: "KTDSBG",
      gaId: "UA-8156856-11",
      gtmEnv: "668",
      gtmAuth: "Q3mmSetXL-9o6LJDN6Tz1A",
      siteVersion: "ios_native"
    },
    fdrdesktop: {
      gaId: "UA-8156856-34",
      gtmId: "NSHBQSR",
      gtmEnv: "5",
      gtmAuth: "brY2SVdkc5NjBXD6t4kZTg",
      siteVersion: "desktop",
      productVersion: "URP"
    },
    fdrmobile: {
      gaId: "UA-8156856-33",
      gtmId: "NSHBQSR",
      gtmEnv: "5",
      gtmAuth: "brY2SVdkc5NjBXD6t4kZTg",
      siteVersion: "mobile_web",
      productVersion: "MEP"
    },
    fdrios: {
      gaId: "UA-8156856-33",
      gtmId: "NSHBQSR",
      gtmEnv: "5",
      gtmAuth: "brY2SVdkc5NjBXD6t4kZTg",
      siteVersion: "ios_native",
      productVersion: "MEP"
    },
    fdrandroid: {
      gaId: "UA-8156856-33",
      gtmId: "NSHBQSR",
      gtmEnv: "5",
      gtmAuth: "brY2SVdkc5NjBXD6t4kZTg",
      siteVersion: "android_native",
      productVersion: "android_apk"
    },
    fdrandroidwrapper: {
      gaId: "UA-8156856-33",
      gtmId: "NSHBQSR",
      gtmEnv: "5",
      gtmAuth: "brY2SVdkc5NjBXD6t4kZTg",
      siteVersion: "android_native",
      productVersion: "android_apk"
    },
    fdrandroidgps: {
      gaId: "UA-8156856-33",
      gtmId: "NSHBQSR",
      gtmEnv: "5",
      gtmAuth: "brY2SVdkc5NjBXD6t4kZTg",
      siteVersion: "android_native",
      productVersion: "android_apk"
    },
    fdriosxsell: {
      gaId: "UA-8156856-33",
      gtmId: "NSHBQSR",
      gtmEnv: "5",
      gtmAuth: "brY2SVdkc5NjBXD6t4kZTg",
      siteVersion: "x-sell",
      productVersion: "MEP"
    },
    fdrandroidxsell: {
      gaId: "UA-8156856-33",
      gtmId: "NSHBQSR",
      gtmEnv: "5",
      gtmAuth: "brY2SVdkc5NjBXD6t4kZTg",
      siteVersion: "x-sell",
      productVersion: "android_apk"
    },
    iosnative: {
      gaId: "UA-8156856-2",
      gtmId: "KTDSBG",
      gtmEnv: "482",
      gtmAuth: "EM7EyobnJbhcGWCe5IOyyw",
      siteVersion: "ios_native",
      productVersion: "ios_native"
    },
    gcs: {
      url: "https://storage.googleapis.com",
      tvgStatic: "tvg-static-staging"
    }
  },
  amplitude: {
    tvg5: {
      amplitudeAPI: AMPLITUDE_API_KEYS_STAGING.STAGING,
      siteVersion: "desktop",
      sitePlatform: "desktop",
      product: "tvg_urp"
    },
    tvg5fdr: {
      amplitudeAPI: AMPLITUDE_API_KEYS_FDR_STAGING.STAGING,
      siteVersion: "desktop",
      sitePlatform: "desktop",
      product: "fdr_standalone_lobby"
    },
    fdrmobile: {
      amplitudeAPI: AMPLITUDE_API_KEYS_FDR_STAGING.STAGING,
      siteVersion: "mobile_web",
      sitePlatform: "mobile_web",
      product: "fdr_standalone_lobby"
    },
    fdrios: {
      amplitudeAPI: AMPLITUDE_API_KEYS_FDR_STAGING.STAGING,
      siteVersion: "native",
      sitePlatform: "ios",
      product: "fdr_standalone_lobby"
    },
    fdrandroid: {
      amplitudeAPI: AMPLITUDE_API_KEYS_FDR_STAGING.STAGING,
      siteVersion: "native",
      sitePlatform: "android",
      product: "fdr_standalone_lobby",
      androidDistributionMethod: "self_dist"
    },
    fdrandroidwrapper: {
      amplitudeAPI: AMPLITUDE_API_KEYS_FDR_STAGING.STAGING,
      siteVersion: "native",
      sitePlatform: "android",
      product: "fdr_standalone_lobby",
      androidDistributionMethod: "google_playstore"
    },
    fdrandroidgps: {
      amplitudeAPI: AMPLITUDE_API_KEYS_FDR_STAGING.STAGING,
      siteVersion: "native",
      sitePlatform: "android",
      product: "fdr_standalone_lobby",
      androidDistributionMethod: "google_playstore"
    },
    fdriosxsell: {
      amplitudeAPI: AMPLITUDE_API_KEYS_FDR_XSELL_STAGING.STAGING,
      siteVersion: "native",
      sitePlatform: "ios",
      product: "fdr_xsell_lobby"
    },
    fdrandroidxsell: {
      amplitudeAPI: AMPLITUDE_API_KEYS_FDR_XSELL_STAGING.STAGING,
      siteVersion: "native",
      sitePlatform: "android",
      product: "fdr_xsell_lobby"
    },
    touch3: {
      amplitudeAPI: AMPLITUDE_API_KEYS_MEP_STAGING.STAGING,
      siteVersion: "mobile_web",
      sitePlatform: "mobile_web",
      product: "tvg_mep"
    },
    androidwrapper: {
      amplitudeAPI: AMPLITUDE_API_KEYS_MEP_STAGING.STAGING,
      siteVersion: "native",
      sitePlatform: "android",
      product: "tvg_mep",
      androidDistributionMethod: "self_dist"
    },
    tvgandroid: {
      amplitudeAPI: AMPLITUDE_API_KEYS_TVG4_STAGING.STAGING,
      siteVersion: "native",
      sitePlatform: "android",
      product: "tvg_mep",
      androidDistributionMethod: "self_dist"
    },
    tvg4: {
      amplitudeAPI: AMPLITUDE_API_KEYS_TVG4_STAGING.STAGING,
      siteVersion: "desktop",
      sitePlatform: "desktop",
      product: "tvg_tvg4"
    }
  },
  clientId: "88a7876dc4c330f0dfa6c5c611818d62",
  brazeId: "908c5857-650b-4f31-8ec7-63b15dc11d1d",
  siftAccountId: "60f88a09293fb116c8220750",
  siftApiKey: "2cd7fd28042a1454",
  siftBeaconKey: "df80113946",
  perimeterxId: "BMkUl9nm",
  storyblokToken: "4LYunuxrMxUarw6vBokjKAtt",
  storyblokVersion: "draft",
  placesAPI: "AIzaSyBwhD0RAB-vMDrx4IDcO_gezIVGpn7dFks",
  paypalID:
    "AfOWmRjlT73hGanCAUypmRtFas_NsPcXek2_fL8XOIrJHBDoUxcSEVBQ72Ls4fc_2xgJSWK3gTDrgjhI",
  apptentiveID: "61f08786218b3765d7000024",
  apptentiveTvg5: "6204200f48b9d649d700005d",
  apptentiveIosKey: "IOS-TVG-DEVELOPMENT-b03e074ea0dd",
  apptentiveIosSignature: "693be9827104eaa410239b062acd34b8",
  apptentiveAndroidKey: "ANDROID-TVG-DEVELOPMENT",
  apptentiveAndroidSignature: "809f6458570a7d899a7ff37684765a4d",
  sonarQube: {
    serverUrl: "https://sonarqube.gcp-dev.tvg.com",
    token: "8836caf742bb1c13755da2d957ba5d1674adbc43",
    projectKey: "tvg-monorepo"
  },
  datadogRum: {
    desktopApplicationId: "123b949b-108c-4306-bea0-9227242f5b17",
    desktopClientToken: "pub748e107dd1bd28256dad4b1425dda2e3",
    mobileApplicationId: "03715ae6-b7e2-4b30-ac7b-cf6a5bfdf763",
    mobileClientToken: "pub333a7706c3a954d17d9db1becba22673",
    fdrApplicationId: "c15f8a9f-f7a7-4108-97f3-9e81d2e936fb",
    fdrClientToken: "pubf5bbdc9d5e227feae77a9ad9f5e1013a",
    site: "datadoghq.com"
  },
  externalServices: {
    cpp: "https://promos-api.racing.fanduel.com"
  }
};
