import styled from "styled-components";
import { buildColor } from "@tvg/design-system";

export const ResultContainer = styled.div``;

export const HeaderWrapper = styled.div`
  background-color: ${buildColor("blue", "700")};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
`;

export const ContentWrapper = styled.div`
  background-color: ${buildColor("white", "900")};
  display: flex;
  flex-direction: column;
  padding: 12px 12px 32px;
  height: 100%;

  & > div:first-of-type {
    flex: 1;
    justify-content: center;
    margin-bottom: 32px;
  }
`;
