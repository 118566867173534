import styled from "styled-components";
import styleDS from "styled-components/native";
import { Paragraph } from "@tvg/design-system";

export const CounterWrapper = styled.div`
  text-transform: uppercase;
  overflow: hidden;

  @media only screen and (hover: hover) and (pointer: fine) and (min-width: 1024px) {
    &:first-of-type:not(:only-of-type) {
      margin-bottom: 20px;
    }
  }
`;

export const ValueText = styleDS(Paragraph)`
  display: block;
  font-size: 32px;
  line-height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LoadingMaskContainer = styled.div<{
  maskWidth: number;
}>`
  width: ${(props) => `${props.maskWidth}px`};
  height: 40px;
`;
