import React from "react";
import Icon from "../../_static/Icons";
import { cup, info } from "../../_static/Icons/icons";
import buildColor from "../../_static/ColorPalette";

import {
  EmptyStandingsContainer,
  EmptyStandingsIconContainer,
  EmptyStandingsInfoIcon,
  EmptyStandingsSubTitle,
  EmptyStandingsTitle
} from "./styled-components";

const EmptyStandingsLeaderboard = ({ title, text, qaLabel }) => (
  <EmptyStandingsContainer data-qa-label={qaLabel}>
    <EmptyStandingsIconContainer data-qa-label={`${qaLabel}IconContainer`}>
      <Icon
        icon={cup}
        size={20}
        view="0 0 16 16"
        strokeList={["", buildColor("grey", "700")]}
        qaLabel={`${qaLabel}CupIcon`}
      />
      <EmptyStandingsInfoIcon
        icon={info}
        size={18}
        view="0 0 16 16"
        qaLabel={`${qaLabel}InfoIcon`}
      />
    </EmptyStandingsIconContainer>
    <EmptyStandingsTitle data-qa-label={`${qaLabel}Title`}>
      {title}
    </EmptyStandingsTitle>
    <EmptyStandingsSubTitle data-qa-label={`${qaLabel}SubTitle`}>
      {text}
    </EmptyStandingsSubTitle>
  </EmptyStandingsContainer>
);

EmptyStandingsLeaderboard.defaultProps = {
  title: "",
  text: "",
  qaLabel: "emptyStandingsLeaderboard"
};

export default EmptyStandingsLeaderboard;
