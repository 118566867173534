import styled, { css } from "styled-components";
import desktopImgPromo from "../../_static/images/desktop-marquee-top-promos.png";
import mobileImgPromo from "../../_static/images/mobile-marquee-promo-mobile.png";
import desktopImgOptedIn from "../../_static/images/desktop-marquee-top-promos-optedin.png";
import mobileImgOptedIn from "../../_static/images/mobile-marquee-top-optedin.png";
import desktopImgEditorial from "../../_static/images/desktop-marquee-top-editorial.png";
import mobileImgEditorial from "../../_static/images/mobile-marquee-editorial.png";

const marqueeImage = {
  promo: {
    mobile: mobileImgPromo,
    desktop: desktopImgPromo
  },
  optedIn: {
    mobile: mobileImgOptedIn,
    desktop: desktopImgOptedIn
  },
  editorial: {
    mobile: mobileImgEditorial,
    desktop: desktopImgEditorial
  }
};

const mobileRadialGradientPromo = css`
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgb(255 184 12 / 40%) 0%,
    rgb(255 184 12 / 0%) 100%
  );
`;

const desktopRadialGradientPromo = css`
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgb(255 184 12 / 30%) 0%,
    rgb(255 184 12 / 0%) 100%
  );
`;

const desktopRadialGradientEditorial = css`
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgb(83 144 237 / 45%) 0%,
    rgb(83 144 237 / 0%) 100%
  );
`;

const mobileRadialGradientEditorial = css`
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgb(83 144 237 / 45%) 0%,
    rgb(83 144 237 / 0%) 100%
  );
`;

const desktopRadialGradientOptedIn = css`
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgb(56 171 79 / 45%) 0%,
    rgb(56 171 79 / 0%) 100%
  );
`;

const mobileRadialGradientOptedIn = css`
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgb(56 171 79 / 45%) 0%,
    rgb(56 171 79 / 0%) 100%
  );
`;

const radialGradient = {
  promo: {
    mobile: mobileRadialGradientPromo,
    desktop: desktopRadialGradientPromo
  },
  optedIn: {
    mobile: mobileRadialGradientOptedIn,
    desktop: desktopRadialGradientOptedIn
  },
  editorial: {
    mobile: mobileRadialGradientEditorial,
    desktop: desktopRadialGradientEditorial
  }
};

const tabletImgPromo = css`
  background-image: url(${({ promoTypeStyling }) =>
    marqueeImage[promoTypeStyling].desktop});
  background-position: top right;
  background-repeat: no-repeat;
`;

const badgeMobileImg = css`
  height: ${({ isBadgeImg }) => (isBadgeImg ? "177px" : "295px")};
  width: ${({ isBadgeImg }) => (isBadgeImg ? "177px" : "295px")};
  transform: translate(-50%, 0);
  ${({ promoTypeStyling }) => radialGradient[promoTypeStyling].mobile}
`;

const tabletImg = css`
  position: absolute;
  align-items: ${({ isBadgeImg }) => (isBadgeImg ? "center" : "flex-end")};
  top: 0;
  height: 339px;
  width: 339px;
  ${({ promoTypeStyling }) => radialGradient[promoTypeStyling].desktop}
`;

const desktopImg = css`
  position: absolute;
  align-items: ${({ isBadgeImg }) => (isBadgeImg ? "center" : "flex-end")};
  bottom: 0;
  width: 339px;
  ${({ promoTypeStyling }) => radialGradient[promoTypeStyling].desktop}
`;

export const BannerContainer = styled.div`
  display: flex;
  background-size: cover;
  position: relative;
  width: 1024px;
  align-items: stretch;
  overflow: hidden;
  ${({ isRnTablet }) =>
    isRnTablet &&
    css`
      margin-top: 40px;
    `};

  @media only screen and (hover: hover) and (pointer: fine) and (width >= 1024px) {
    width: 1024px;
    padding: ${({ isEditorialBanner }) =>
      isEditorialBanner ? "0" : "72px 34px 0 24px"};
    background-color: #102337;

    &::before {
      content: "";
      background-image: url(${({ promoTypeStyling }) =>
        marqueeImage[promoTypeStyling].desktop});
      background-repeat: no-repeat;
      background-position: top left;
      background-size: contain;
      inset: 0;
      position: absolute;
      z-index: 1;
    }
  }

  @media only screen and (hover: none) and (pointer: coarse) and (width >= 1024px) {
    max-width: 944px;
    margin: ${({ isRnTablet }) => (isRnTablet ? "40px" : "0")} auto 0 auto;
    height: 312px;
    padding: ${({ isEditorialBanner }) =>
      isEditorialBanner ? "0" : "57px 0 30px 92px"};
    ${tabletImgPromo}
  }

  @media screen and (width >= 768px) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  @media only screen and (width <= 1023px) and (width >= 768px) {
    height: 346px;
    padding: ${({ isEditorialBanner }) =>
      isEditorialBanner ? "0" : "72px 0 72px 80px"};
    width: 100%;
    ${tabletImgPromo}
  }

  @media only screen and (width <= 767px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 12px;
  }
`;

export const BannerTopGradient = styled.div`
  @media only screen and (width >= 768px) {
    background: linear-gradient(180deg, #102337 0%, rgb(17 43 68 / 0%) 100%);
    height: 220px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  @media only screen and (width <= 767px) {
    display: none;
  }
`;

export const BannerImageWrapper = styled.div`
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;

  @media only screen and (hover: hover) and (pointer: fine) and (width >= 1024px) {
    right: 34px;
    ${desktopImg}
  }

  @media only screen and (hover: none) and (pointer: coarse) and (width >= 1024px) {
    right: 36px;
    ${tabletImg}
  }

  @media only screen and (width <= 1023px) and (width >= 768px) {
    right: 0;
    ${desktopImg}
  }

  @media only screen and (width <= 767px) {
    min-height: 178px;
    max-height: 322px;
    width: 100%;
    padding: 12px 12px 0;
    background-image: url(${({ promoTypeStyling }) =>
      marqueeImage[promoTypeStyling].desktop});
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;

    &::after {
      content: "";
      top: 0;
      left: 50%;
      position: absolute;
      z-index: 1;
      ${badgeMobileImg}
    }
  }
`;

export const BannerImage = styled.img`
  @media only screen and (width >= 1024px) {
    max-width: ${({ isBadgeImg }) => (isBadgeImg ? "220px" : "351px")};
  }

  @media only screen and (width >= 768px) and (width <= 1023px) {
    max-width: ${({ isBadgeImg }) => (isBadgeImg ? "220px" : "322px")};
  }

  @media only screen and (width >= 768px) {
    object-fit: ${({ isBadgeImg }) => (isBadgeImg ? "none" : "cover")};
    max-height: ${({ isBadgeImg }) => (isBadgeImg ? "220px" : "322px")};
  }

  @media only screen and (width <= 767px) {
    display: none;
  }
`;

export const BannerDivider = styled.div`
  @media only screen and (width <= 767px) {
    width: 100%;
    height: 19px;
    margin-bottom: 12px;
    background: radial-gradient(
      46.15% 46.15% at 50% 0%,
      #1e4b79 0%,
      rgb(30 75 121 / 0%) 100%
    );
  }
`;

export const BannerMobileImage = styled.img`
  @media only screen and (width <= 767px) {
    object-fit: contain;
    z-index: 100;
    max-height: ${({ isBadgeImg }) => (isBadgeImg ? "132px" : "322px")};
    max-width: ${({ isBadgeImg }) => (isBadgeImg ? "153px" : "351px")};
  }

  @media only screen and (width >= 768px) {
    display: none;
  }
`;

export const BannerContentWrapper = styled.div`
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media only screen and (width <= 767px) {
    width: 100%;
    padding: 0 12px;
  }

  @media only screen and (hover: hover) and (pointer: fine) and (width >= 1024px) {
    padding-bottom: 25px;
  }
`;
