import React from "react";
import { get } from "lodash";
import { getRefereeStatus } from "@tvg-mar/utils/referralCenterUtils";
import { Paragraph } from "@tvg/design-system";
import { ReferralStatus } from "@tvg/ts-types/ReferFriend";
import { RefereeStatusContainer } from "./styled-components";

export interface Props {
  referralStatus: ReferralStatus;
  qaLabel?: string;
}

const RefereeStatus = ({
  referralStatus,
  qaLabel = "refereeStatus"
}: Props) => {
  const { accountId, name } = get(referralStatus, "refereeReferralData");
  const { color, text } = getRefereeStatus(referralStatus);
  return (
    <RefereeStatusContainer data-qa-label={`${accountId}-${qaLabel}`}>
      <Paragraph
        qaLabel={`${qaLabel}Status`}
        fontFamily="condensedRegular"
        color={color}
        fontSize="xs"
      >
        {text}
      </Paragraph>
      <Paragraph
        qaLabel={`${qaLabel}Name`}
        fontFamily="medium"
        color="grey.900"
      >
        {name}
      </Paragraph>
    </RefereeStatusContainer>
  );
};

export default RefereeStatus;
