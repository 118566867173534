import React from "react";

const DEFAULT_DEVICE = {
  platform: "web",
  mobile: true
};

const deviceContext = React.createContext(DEFAULT_DEVICE);

export default deviceContext;
