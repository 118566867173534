import styled, { css } from "styled-components/native";
import { TouchableHighlight, Text } from "react-native";
import { StyledProps, DisabledProps } from "./types";

export const StyledPressable = styled(TouchableHighlight)<
  StyledProps & DisabledProps
>`
  min-height: 42px;
  background-color: ${({ theme }) => theme.colors.white[900]};
  padding: ${({ theme }) => theme.space["space-4"]};
  justify-content: center;
  align-items: left;
  ${({ isHovered, isDisabled, theme }) =>
    isHovered &&
    css`
      background-color: ${theme.colors.blue_accent["000"]};
      cursor: ${isDisabled ? "default" : "pointer"};
    `}
  ${({ isPressed, theme }) =>
    isPressed &&
    css`
      background-color: ${theme.colors.blue_accent[100]};
    `}
  ${({ isDisabled, theme }) =>
    isDisabled &&
    css`
      background-color: ${theme.colors.white[500]};
    `};
  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      background-color: ${theme.colors.blue_accent["000"]};
      border-bottom-color: ${theme.colors.blue["000"]};
      border-bottom-width: 1px;
      border-left-color: ${theme.colors.blue_accent[500]};
      border-left-width: 2px;
    `}
`;

export const StyledTextTitle = styled(Text)<StyledProps & DisabledProps>`
  ${({ theme, isSelected, isPressed, isDisabled }) => css`
    font-family: ${isSelected || isPressed
      ? theme.fonts.medium
      : theme.fonts.regular};
    font-size: ${theme.fontSizes.s};
    color: ${((isSelected || isPressed) && theme.colors.blue_accent[500]) ||
    (isDisabled && theme.colors.grey[600]) ||
    theme.colors.grey[900]};
  `}
`;

export const StyledTextDescription = styled(Text)<StyledProps>`
  ${({ theme, isSelected, isPressed }) => css`
    font-family: ${isSelected || isPressed
      ? theme.fonts.medium
      : theme.fonts.regular};
    font-size: ${theme.fontSizes.xs};
    color: ${theme.colors.grey[700]};
  `}
`;
