import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontBold, fontNormal, fontMedium } from "../../_static/Typography";

export const LeaderBoardsStandingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${buildColor("white", "100")};

  @media only screen and (width <= 499px) {
    min-height: 161px;
  }

  @media only screen and (width >= 500px) {
    border-radius: 4px;
    flex-grow: 1;
  }
`;

export const LeaderBoardStandingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 4px 4px 0 0;
  position: relative;
  padding-bottom: 48px;

  > [data-qa-label="scrollWrapper"] {
    overflow-y: hidden;
  }
`;

export const LeaderBoardsStandingsHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 12px;
  justify-content: space-between;
  border-radius: 4px 4px 0 0;
`;

export const LeaderBoardsStandingsTitle = styled.span`
  font-family: ${fontBold};
  font-size: 16px;
  line-height: 18.75px;
  margin-bottom: 4px;
  color: ${buildColor("grey", "900")};
`;

export const LeaderBoardsStandingsSubTitle = styled.span`
  font-family: ${fontNormal};
  font-size: 12px;
  line-height: 17px;
  color: ${buildColor("grey", "800")};
`;

export const TableWrapper = styled.div`
  padding-top: 0;
`;

export const Table = styled.table`
  width: 100%;
  background-color: ${buildColor("white", "100")};

  > tr {
    margin-bottom: 12px;
  }

  > tr:first-child {
    td {
      padding-top: 12px;
      height: 45px;
    }
  }
`;

export const TableHead = styled.thead`
  > tr {
    td {
      padding-top: 12px;
      height: 45px;
    }
  }
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  height: 18px;
  width: 100%;
  background-color: ${({ isTopStandings }) =>
    isTopStandings
      ? buildColor("white", "100")
      : buildColor("blue_accent", "000")};
  ${({ showTopBorder }) =>
    showTopBorder &&
    css`
      border-top: 1px solid ${buildColor("blue", "100")};

      td {
        padding-top: 12px;
        height: 45px;
      }
    `};
  ${({ addBottomPadding }) =>
    addBottomPadding &&
    css`
      td {
        padding-bottom: 12px;
        height: 45px;
      }
    `};
`;

const tableCell = css`
  height: 33px;
  color: ${({ isHighlight }) =>
    isHighlight ? buildColor("grey", "900") : buildColor("grey", "800")};
  font-size: 14px;
`;

export const TablePosition = styled.td`
  font-family: ${({ isHighlight }) => (isHighlight ? fontMedium : fontNormal)};
  padding-left: ${({ showPadding }) => (showPadding ? "16px" : "12px")};

  @media only screen and (width >= 1024px) {
    width: 170px;
  }

  @media only screen and (width >= 500px) and (width <= 1023px) {
    width: 97px;
  }

  @media only screen and (width <= 499px) {
    width: 76px;
  }

  ${tableCell}
`;

export const TableName = styled.td`
  font-family: ${({ isHeader, isHighlight }) =>
    isHeader || isHighlight ? fontMedium : fontNormal};

  @media only screen and (width >= 1024px) {
    width: 228px;
  }

  @media only screen and (width >= 500px) and (width <= 1023px) {
    width: 154px;
  }

  @media only screen and (width <= 499px) {
    width: 133px;
  }

  ${tableCell}
`;

export const TableState = styled.td`
  font-family: ${({ isHeader, isHighlight }) =>
    isHeader || isHighlight ? fontMedium : fontNormal};
  width: 35px;
  ${tableCell}
`;

export const TableWinnings = styled.td`
  text-align: right;
  padding-right: 12px;
  font-family: ${({ isHeader, isHighlight }) =>
    isHeader || isHighlight ? fontMedium : fontNormal};

  @media only screen and (width >= 1024px) {
    width: 212px;
  }

  @media only screen and (width >= 500px) and (width <= 1023px) {
    width: 127px;
  }

  @media only screen and (width <= 499px) {
    width: 133px;
  }

  ${tableCell}
`;

export const Processing = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 162px;
  height: 100%;

  & > div:last-child {
    margin-top: 8px;
    font-size: 12px;
  }
`;
