import React, { Fragment } from "react";
import { get } from "lodash";
import errorTypes from "@tvg-mar/utils/errorMessaging";

import ErrorNotification from "../../_molecules/MessageTypes/errorNotification";
import InfoNotification from "../../_molecules/MessageTypes/infoNotication";
import SuccessNotification from "../../_molecules/MessageTypes/successNotification";

export const renderErrorMsg = (error, qaLabel = "statusNotification") => {
  const text = errorTypes(error);

  return <ErrorNotification qaLabel={`${qaLabel}Error`} text={text} />;
};

export const renderInfoMsg = (
  text,
  showInfoNotification,
  qaLabel = "statusNotification"
) =>
  showInfoNotification && (
    <InfoNotification text={text} qaLabel={`${qaLabel}Info`} />
  );

export const renderSuccessMsg = (
  isOpted,
  optinTriggered,
  successText,
  qaLabel = "statusNotification"
) => (
  <Fragment>
    {isOpted && (
      <SuccessNotification
        qaLabel={`${qaLabel}Success`}
        optinTriggered={optinTriggered}
        text={successText}
      />
    )}
  </Fragment>
);

const StatusNotifications = (props) => {
  switch (props.type) {
    case "success":
      return renderSuccessMsg(
        get(props, "isOpted", false),
        get(props, "optinTriggered", false),
        get(props, "successText"),
        get(props, "qaLabel", "statusNotification")
      );
    case "error":
      return renderErrorMsg(
        get(props, "errorType"),
        get(props, "qaLabel", "statusNotification")
      );
    default:
      return renderInfoMsg(
        get(props, "infoText"),
        get(props, "showInfoNotification", false),
        get(props, "qaLabel", "statusNotification")
      );
  }
};

StatusNotifications.defaultProps = {
  type: "error",
  isOpted: false,
  optinTriggered: false,
  errorType: "An error has occurred",
  successText: "",
  infoText: "",
  qaLabel: "statusNotification"
};

export default StatusNotifications;
