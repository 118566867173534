import styled from "styled-components";
import { fontNormal, fontBold } from "../../_static/Typography";

export const PromosBannerContentContainer = styled.div`
  position: relative;
  display: flex;
  padding: 0 10px;

  @media only screen and (width >= 900px) {
    width: 615px;
  }

  @media only screen and (width >= 768px) {
    flex-direction: row;
    height: 214px;
    margin: auto 0;
  }

  @media only screen and (width <= 767px) {
    width: 100%;
    height: 137px;
    flex-direction: column;
  }

  @media only screen and (width <= 499px) {
    height: 100%;
  }
`;

export const PromosBannerContentWrapper = styled.div`
  h2,
  h4 {
    line-height: 1.1em;
    max-height: ${({ hasSecondDescription }) =>
      hasSecondDescription ? "inherit" : "2.35em"};
    overflow: hidden;
  }

  h2 {
    letter-spacing: 0;
    text-transform: none;
    font-family: ${fontBold};
    font-weight: bold;
  }

  h3 {
    font-weight: normal;
    font-family: ${fontNormal};
    margin-bottom: 12px;
  }

  h4 {
    font-weight: normal;
    text-transform: none;
    margin-bottom: 16px;
    font-family: ${fontNormal};
  }

  a,
  button {
    width: 141px;
    height: 48px;

    span.span {
      font-size: 14px;
      font-weight: bold;
      color: #384048;
    }
  }

  @media only screen and (width >= 768px) {
    h2,
    h3,
    h4 {
      max-width: 400px;
    }
  }

  @media only screen and (width >= 944px) {
    h2,
    h3,
    h4 {
      max-width: 560px;
    }
  }

  @media only screen and (width <= 767px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h2 {
      font-size: 18px;
    }

    h3 {
      font-size: 14px;
      letter-spacing: 0.5px;
      margin: 2% 0;
    }

    h4 {
      font-size: 16px;
    }

    a,
    button {
      width: 100%;
      height: 40px;
    }
  }

  @media only screen and (width <= 499px) {
    h2 {
      font-size: 20px;
    }

    h4 {
      margin-bottom: 16px;
      margin-top: 14px;
      font-size: 16px;
    }
  }
`;

export const PromoBannerButtonWrapper = styled.div`
  @media only screen and (width <= 499px) {
    padding-bottom: 12px;
  }
`;

export const SecondDescription = styled.span`
  display: block;
  width: 100%;
  margin-top: 8px;
`;
