const useFakeInput = () => {
  setTimeout(() => {
    const fakeInput = document.createElement("input");
    fakeInput.type = "text";
    document.body.insertBefore(fakeInput, document.body.firstChild);
    fakeInput.focus();

    setTimeout(() => {
      document.body.removeChild(fakeInput);
      window.focus();
    }, 10);
  });
};

export default useFakeInput;
