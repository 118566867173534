import React from "react";
import withRouter from "@tvg/utils/withCustomRouter";
import buildRaceUrl from "@tvg/formatter/url";

import InternalLink from "../../_atoms/InternalLink";
import { NextRaceButtonContainer, NextRaceText } from "./styled-components";

const NextRaceButton = ({
  location,
  trackCode,
  trackName,
  raceNumber,
  isMobilePersistent,
  qaLabel
}) => (
  <NextRaceButtonContainer
    data-qa-label={qaLabel}
    isMobilePersistent={isMobilePersistent}
  >
    <InternalLink
      pathName={buildRaceUrl(trackCode, trackName, +raceNumber)}
      linkType="url"
      target="_self"
      location={location}
      qaLabel={`${qaLabel}InternalLink`}
    >
      <NextRaceText data-qa-label={`${qaLabel}Text`}>
        {`Bet Next Race - ${trackCode}`}
        {raceNumber > 0 && ` R${raceNumber}`}
      </NextRaceText>
    </InternalLink>
  </NextRaceButtonContainer>
);

NextRaceButton.defaultProps = {
  trackCode: "",
  trackName: "",
  raceNumber: 0,
  isMobilePersistent: false,
  qaLabel: "nextRaceButton"
};

export default withRouter(NextRaceButton);
