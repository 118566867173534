import styled, { css } from "styled-components";

const CommonFaderStyles = css`
  position: absolute;
  width: ${(props) => props.fadersWidth}px;
  z-index: 20;
  ${(props) =>
    props.centerFaders
      ? css`
          top: 5%;
          height: 90%;
        `
      : css`
          top: 0;
          height: 100%;
        `};
`;

export const ScrollWrapperContainer = styled.div`
  position: relative;
  overflow-x: ${({ isFullWidth }) => (isFullWidth ? "hidden" : "auto")};
  z-index: ${(props) => (props.showFaders ? 1 : 0)};
  ${(props) =>
    props.showFaders &&
    css`
      ${props.hasContentLeft &&
      css`
        &::before {
          content: "";
          left: 0;
          background-image: linear-gradient(
            to left,
            ${props.fadeFrom},
            ${props.fadeTo}
          );
          ${CommonFaderStyles};
        }
      `};
      ${props.hasContentRight &&
      css`
        &::after {
          content: "";
          right: 0;
          background-image: linear-gradient(
            to right,
            ${props.fadeFrom},
            ${props.fadeTo}
          );
          ${CommonFaderStyles};
        }
      `};
      ${props.stretch && `min-width: 100%;`}
    `};
`;

export default ScrollWrapperContainer;
