import styled, { css } from "styled-components";
import type { StrechedProps, StyledProps, DisabledProps } from "./types";

export const StyledButton = styled.div<
  StyledProps & StrechedProps & DisabledProps
>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 48px;
  background-color: var(--fd-colors-background-surface);
  border-bottom: ${({ noBorder }) => (noBorder ? "0" : "1px")}
    --fd-border-styles-border-style-solid-style var(--fd-colors-border-default) !important;
  padding: 0 var(--fd-space-space-2);

  ${({ isStretched }) =>
    isStretched &&
    css`
      flex-grow: 1;
    `}

  &:hover {
    background-color: var(--fd-colors-background-hover);
    cursor: ${({ isDisabled }) => (isDisabled ? "default" : "pointer")};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      &:hover {
        p: {
          color: var(--fd-colors-link-default-hover);
        }
      }
    `}

  &:active {
    background-color: var(--fd-colors-border-active);

    p {
      color: var(--fd-colors-content-on-dark);
    }
  }
`;

export const StyledTitle = styled.p<StyledProps & DisabledProps>`
  color: var(--fd-colors-content-default);
  font-family: Roboto-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: 125%;

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: var(--fd-colors-link-default-hover);
    `}

  &:active {
    color: var(--fd-colors-content-on-dark);
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: var(--fd-colors-content-disabled);
    `}
`;

export const StyledViewIconContainer = styled.div`
  margin-left: ${({ theme }) => theme.space["space-1"]};
`;

export const StyledDescription = styled.p<StyledProps & DisabledProps>`
  color: var(--fd-colors-content-subtle);
  margin-top: var(--fd-space-space-05);
  font-family: Roboto-Regular, sans-serif;
  font-size: 12px;
  font-style: normal;
  line-height: 125%;

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: var(--fd-colors-link-default-hover);
    `}

  &:active {
    color: var(--fd-colors-content-on-dark);
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: var(--fd-colors-content-disabled);
    `}
`;

export const StyledViewTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2px;
  background-color: transparent !important;
`;
