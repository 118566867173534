import { get } from "lodash";
import mediator from "@tvg/mediator";
import tvgConf from "@tvg/conf";

import { decodeURLParams } from "./pathHandlingUtils";

export const handleNavigationDataLayerUpdate = (label, origin, url) => {
  const microAppName =
    origin === "refer a friend" ? "Refer a Friend" : "Promos";
  const eventType =
    origin === "refer a friend" ? "RAF_NAVIGATION_CLICK" : "PROMOS_CTA_CLICK";
  const microApp =
    tvgConf().device === "desktop" ? microAppName : "non-Microapp";

  mediator.base.dispatch({
    type: eventType,
    payload: {
      gaEventLabel: label,
      module: origin,
      microApp,
      destinationUrl: url
    }
  });
};

export const handleSiteClickDataLayerUpdate = (data) => {
  const params =
    data.urlLink.indexOf("?") > -1 ? decodeURLParams(data.urlLink) : {};
  const promoCode = get(params, "promocode", undefined);

  mediator.base.dispatch({
    type: "PROMOS_ALL_CTA_CLICK",
    payload: {
      gaEventAction: get(data, "linkLabel", undefined),
      gaEventLabel: promoCode
    }
  });
};

export const handleOptinDataLayerUpdate = (tag, promoId) => {
  mediator.base.dispatch({
    type: "PROMOS_RET_CTA_CLICK",
    payload: {
      gaEventLabel: promoId,
      tag
    }
  });
};

export default {
  handleNavigationDataLayerUpdate,
  handleSiteClickDataLayerUpdate,
  handleOptinDataLayerUpdate
};
