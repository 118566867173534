import styled, { css, keyframes } from "styled-components";

import buildColor from "../../_static/ColorPalette";
import { fontNormal, fontMedium } from "../../_static/Typography";

const slideIn = keyframes`
  from { 
    bottom: 0;
    opacity: 1; 
  }
  
  to { 
    bottom: 12px;
    opacity: 1; 
  }
`;

const easeInOut = keyframes`
  0% { opacity: 1; }
  6.25% { opacity: 1; }
  93.75% { opacity: 1; }
  100% { opacity: 0; }
`;

const desktopSlideIn = keyframes`
  from {
    top: 0;
    opacity: 0;
  }
  
  to {
    top: 42px;
    opacity: 1;
  }
`;

const animation = css`
  @media only screen and (width >= 768px) {
    animation-name: ${desktopSlideIn};
    animation-timing-function: ease-in-out;
    animation-duration: 1000ms;
    opacity: 1;
  }

  @media only screen and (width <= 767px) {
    z-index: 200;
    width: calc(100% - 24px);
    margin: 0 12px;
    position: fixed;
    bottom: 12px;
    left: 0;
    padding: 12px;
    border-radius: 4px;
    animation-name: ${slideIn}, ${easeInOut};
    animation-timing-function: ease-in-out, ease-in-out;
    animation-duration: 1000ms, 10000ms;
    animation-delay: 0ms, 1000ms;
    opacity: 0;
  }
`;

const container = css`
  width: 100%;
  display: flex;
  padding: 12px;
`;

const title = css`
  margin-left: 12px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;

  @media only screen and (width <= 767px) {
    margin-left: 8px;
  }
`;

export const SuccessContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SuccessContainer = styled.div`
  background-color: ${buildColor("green", "000")};

  @media only screen and (width >= 768px) {
    box-shadow:
      0 1px 0 ${buildColor("green", "200")},
      0 -1px 0 ${buildColor("green", "200")};
  }

  @media only screen and (width <= 767px) {
    box-shadow:
      0 4px 4px rgb(0 0 0 / 10%),
      0 4px 6px rgb(0 0 0 / 5%);
    border: 1px solid ${buildColor("green", "200")};
  }

  ${container}
  ${animation}
  ${({ addBottomMargin }) =>
    addBottomMargin &&
    css`
      @media only screen and (width <= 767px) {
        bottom: 76px;
      }
    `}
  ${({ borderTopRadius }) =>
    borderTopRadius &&
    css`
      @media only screen and (width >= 768px) {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    `}
`;

export const SuccessTitle = styled.span`
  font-family: ${fontMedium};
  color: ${buildColor("green", "600")};
  ${title}
`;

export const SuccessText = styled.span`
  font-family: ${fontNormal};
  margin-top: 4px;
  color: ${buildColor("green", "600")};
  ${title}
`;

export const ErrorContainer = styled.div`
  background-color: ${buildColor("orange", "000")};
  pointer-events: none;
  ${container}
  ${animation}
  ${({ addBottomMargin }) =>
    addBottomMargin &&
    css`
      @media only screen and (width <= 767px) {
        bottom: 76px;
      }
    `}
  ${({ borderTopRadius }) =>
    borderTopRadius &&
    css`
      @media only screen and (width >= 768px) {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    `}

  @media only screen and (width >= 768px) {
    box-shadow:
      0 1px 0 ${buildColor("orange", "100")},
      0 -1px 0 ${buildColor("orange", "100")};
  }

  @media only screen and (width <= 767px) {
    box-shadow:
      0 4px 4px rgb(0 0 0 / 10%),
      0 4px 6px rgb(0 0 0 / 5%);
    border: 1px solid ${buildColor("orange", "200")};
  }
`;

export const ErrorText = styled.span`
  font-family: ${fontNormal};
  color: ${buildColor("orange", "700")};
  ${title}
`;

export const InfoContainer = styled.div`
  background-color: ${buildColor("blue_accent", "000")};
  ${container}
  ${({ borderTopRadius }) =>
    borderTopRadius &&
    css`
      @media only screen and (width >= 768px) {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    `}
  ${({ isAnimated }) => isAnimated && animation}
  ${({ isAnimated, addBottomMargin }) =>
    isAnimated &&
    addBottomMargin &&
    css`
      @media only screen and (width <= 767px) {
        bottom: 76px;
      }
    `}
  ${({ showBorders }) =>
    showBorders
      ? css`
          border-top: 1px solid ${buildColor("blue_accent", "200")};
          border-bottom: 1px solid ${buildColor("blue_accent", "200")};
        `
      : css`
          @media only screen and (width >= 768px) {
            box-shadow:
              0 1px 0 ${buildColor("blue_accent", "000")},
              0 -1px 0 ${buildColor("blue_accent", "000")};
          }

          @media only screen and (width <= 767px) {
            box-shadow:
              0 4px 4px rgb(0 0 0 / 10%),
              0 4px 6px rgb(0 0 0 / 5%);
            border: 1px solid ${buildColor("blue_accent", "200")};
          }
        `}
`;

export const InfoText = styled.span`
  font-family: ${({ isBoldTitle }) => (isBoldTitle ? fontMedium : fontNormal)};
  color: ${buildColor("blue_accent", "600")};
  ${title}
`;

export const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InfoSubText = styled.span`
  font-family: ${fontNormal};
  margin-top: 4px;
  color: ${buildColor("blue_accent", "600")};
  ${title}
`;
