import styled from "styled-components";

export const SegmentText = styled.span`
  color: var(--fd-colors-system-neutral-content-accent);
  font-size: 14px;
  font-weight: 500;
  line-height: 125%;
  user-select: none;
  font-family: "Roboto-Medium", sans-serif;
  transition: all 0.2s ease-in-out;
`;

export const SegmentTab = styled.button`
  align-items: center;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 4px;
  height: 100%;
  justify-content: center;
  outline: inherit;
  padding: 0;
  transition: all 0.2s ease-in-out;
  z-index: 1;

  &[aria-selected="true"] ${SegmentText}, &[aria-selected="true"] path {
    color: var(--fd-colors-content-default);
    stroke: var(--fd-colors-content-default);
  }
`;
