import styled, { css } from "styled-components";
import { padding } from "styled-system";

export const StyledContainer = styled.div<{ isFullHeight?: boolean }>`
  ${({ isFullHeight }) =>
    isFullHeight &&
    css`
      flex-grow: 1;
    `};
  ${padding};
`;
