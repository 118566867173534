import React, { Fragment, useState, useEffect } from "react";
import mediator from "@tvg/mediator";
import tvgConf from "@tvg/conf";

import pathUtils from "@tvg-mar/utils/pathHandlingUtils";

import { SocialButton, SocialLink } from "../../_atoms/Buttons";
import ToolTip from "../../_atoms/ToolTip";
import { ShareLinkContainer, ShareLinkSpan } from "./styled-components";
import buildColor from "../../_static/ColorPalette";

const nativeProducts = ["iosnative", "androidnative"];

const gaShareEvent = (label) => {
  mediator.base.dispatch({
    type: "SOCIAL_LINK_CLICK",
    payload: {
      gaEventLabel: label
    }
  });
};

const openExternalToIOS = (url) => {
  mediator.ios.dispatch({
    type: "OPEN_EXTERNAL_APP",
    payload: { openExternalApp: url }
  });
};

const openLoginModal = () => {
  if (
    nativeProducts.includes(tvgConf().product) &&
    typeof window !== "undefined" &&
    window.appId !== "referral"
  ) {
    window.handleNativeMessages("OPEN_LOGIN_MODAL_WEBVIEW", {
      currentUrl: window.location.href
    });
  } else {
    mediator.base.dispatch({
      type: "OPEN_LOGIN"
    });
  }
};

const handleClick = (event, props) => {
  const { isLogged, isEligible, text, shareLink, type, id } = props;
  console.log("click", isEligible);
  if (!isLogged) {
    event.preventDefault();
    openLoginModal();
  } else if (!isEligible) {
    console.log("RUN", event.preventDefault);
    event.preventDefault();
    event.stopPropagation();
  } else {
    gaShareEvent(text);

    if (id === "invite") {
      mediator.base.dispatch({
        type: "OPEN_EMAIL_REFERRAL_MODAL"
      });
    } else if (
      (["ios2", "androidwrapper", "tvgandroid"].includes(tvgConf().product) ||
        nativeProducts.includes(tvgConf().product)) &&
      type === "link"
    ) {
      event.preventDefault();
      if (nativeProducts.includes(tvgConf().product)) {
        pathUtils.handleExternalIOSNativeAppPath(shareLink, event);
      }
      openExternalToIOS(shareLink);
    }
  }
};

const useToolTip = () => {
  const [isCopied, setCopiedStatus] = useState(false);
  let toggleToolTip;

  const showToolTip = () => {
    setCopiedStatus(true);
  };

  useEffect(() => {
    toggleToolTip = setTimeout(() => setCopiedStatus(false), 4000);

    return () => {
      clearTimeout(toggleToolTip);
    };
  }, [isCopied]);

  return { isCopied, showToolTip };
};

const ShareLink = (props) => {
  const { showToolTip, isCopied } = useToolTip();

  const renderToolTip = () => (
    <Fragment>
      <ToolTip
        backgroundColor={buildColor("green", "000")}
        borderColor={buildColor("green", "200")}
        textColor={buildColor("green", "600")}
        text="Copied"
        enableTransition
        qaLabel={`${props.qaLabel}ToolTip`}
      />
    </Fragment>
  );

  return (
    <ShareLinkContainer data-qa-label={props.qaLabel}>
      {isCopied &&
        props.isLogged &&
        props.type === "button" &&
        props.id === "copyLink" &&
        renderToolTip()}
      {props.type === "button" ? (
        <SocialButton
          id={props.id}
          onClick={(event) => {
            if (props.isEligible) {
              showToolTip();
            }
            handleClick(event, props);
            props.copyLink(props.type);
          }}
          qaLabel={`${props.qaLabel}SocialButton`}
        >
          {props.children}
        </SocialButton>
      ) : (
        <SocialLink
          onClick={(event) => handleClick(event, props)}
          id={props.id}
          anchorLink={props.shareLink}
          target={props.target}
          qaLabel={`${props.qaLabel}SocialLink`}
        >
          {props.children}
        </SocialLink>
      )}
      <ShareLinkSpan data-qa-label={`${props.qaLabel}LinkText`}>
        {props.text}
      </ShareLinkSpan>
    </ShareLinkContainer>
  );
};

ShareLink.defaultProps = {
  id: "",
  isLogged: false,
  isEligible: true,
  type: "",
  text: "",
  target: null,
  shareLink: "",
  message: "",
  hashTag: "",
  subjectLine: "",
  userEmail: "",
  promoId: 0,
  qaLabel: "shareLink"
};

export default ShareLink;
