export const successFeaturesRequest = (featureToggles) => ({
  type: "FEATURES_GET_SUCCESS",
  payload: {
    featureToggles
  }
});

export const failFeaturesRequest = (err) => ({
  type: "FEATURES_GET_FAIL",
  payload: err,
  error: true
});

export const featuresResponse = (features) => ({
  type: "FEATURES_RESPONSE",
  payload: {
    features
  }
});

export const successMessagesRequest = (messagesData) => ({
  type: "MESSAGES_GET_SUCCESS",
  payload: {
    messages: messagesData
  }
});

export const failMessagesRequest = (err) => ({
  type: "MESSAGES_GET_FAIL",
  payload: err,
  error: true
});
