import styled, { css } from "styled-components";
import { formatPeriod } from "@tvg/formatter/dates";
import { fontNormal, fontMedium } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

const MTPTime = styled.time.attrs((props) => ({
  dateTime: props.postTime.toString()
}))`
  display: inline-flex;
  flex-direction: ${(props) => (props.multipleLine ? "column" : "row")};
  align-items: center;
  justify-content: center;
  min-height: ${(props) =>
    !props.paddingless && (props.multipleLine ? "40px" : "32px")};
  padding: ${(props) => !props.multipleLine && !props.paddingless && "0 16px"};
  min-width: ${(props) => props.multipleLine && "56px"};
  height: 100%;
  border-radius: ${(props) => props.darken && "2px"};
  font-size: 12px;
  line-height: ${(props) => (props.bigger ? "16px" : "14px")};
  background-color: ${(props) => props.darken && buildColor("black", "30")};
  color: ${(props) => props.darken && buildColor("white", "100")};
  ${(props) =>
    props.showRaceDate && "text-align: center; text-transform: uppercase;"};

  &::before {
    ${(props) =>
      props.mtp <= props.mtpCloseThreshold &&
      !props.isRaceOff &&
      "content: '';"};
    display: block;
    width: 8px;
    height: 8px;
    margin-right: ${(props) => !props.multipleLine && "4px"};
    margin-bottom: ${(props) => props.multipleLine && "4px"};
    border-radius: 50%;
    background-color: ${buildColor("red", "500")};
  }

  &::after {
    display: inline-block;
    content: ${(props) =>
      !props.isRaceOff &&
      !props.showRaceDate &&
      props.mtp > props.mtpThreshold &&
      `"${formatPeriod(props.postTime).toString()}"`};
    margin-left: ${(props) => !props.multipleLine && "3px"};
  }

  @media (pointer: coarse) {
    line-height: ${(props) => (props.bigger ? "15px" : "13px")};
  }
`;

export const RaceClosedStatus = styled.span`
  width: 32px;
  height: 16px;
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  border-radius: 1px;
  background-color: ${(props) =>
    props.status === "RO"
      ? buildColor("grey", "200")
      : buildColor("green", "600")};
  color: ${(props) =>
    props.status === "RO"
      ? buildColor("black", "100")
      : buildColor("white", "100")};
  padding-top: 1px;
  font-family: ${fontMedium};
`;

const getMTPStyle = (mtp, isOnBiggestWinner) => {
  let mtpStyle = `color: ${
    !isOnBiggestWinner ? buildColor("grey", "700") : buildColor("blue", "300")
  }; 
  font-family: ${fontNormal}`;

  if (mtp <= 5)
    mtpStyle = `color: ${buildColor("orange", "600")}; 
    font-family: ${fontMedium};`;
  else if (mtp < 60)
    mtpStyle = `color: ${
      !isOnBiggestWinner ? buildColor("grey", "900") : buildColor("blue", "200")
    }; 
    font-family: ${fontMedium}`;

  return mtpStyle;
};

const getLegacyMTPStyle = (mtp, isOnBiggestWinner) => {
  let mtpStyle = `color: ${
    !isOnBiggestWinner ? buildColor("grey", "800") : buildColor("blue", "300")
  };
  font-family: ${fontNormal};`;
  if (mtp <= 10) {
    mtpStyle = `color: ${buildColor("red", "600")};
    font-family: ${fontMedium};`;
  } else if (mtp < 60) {
    mtpStyle = `color: ${
      !isOnBiggestWinner ? buildColor("grey", "900") : buildColor("blue", "200")
    };
    font-family: ${fontMedium};`;
  }

  return mtpStyle;
};

export const PlainMTP = styled.time.attrs((props) => ({
  dateTime: props.postTime
}))`
  display: ${(props) => (props.isMyBets ? "flex" : "inline")};
  height: ${(props) => (props.isMyBets ? "auto" : "16px")};
  font-size: 14px;
  ${({ mtp, isOnBiggestWinner, isMTPNewRules }) => {
    const returnCSS = isMTPNewRules
      ? getMTPStyle(mtp, isOnBiggestWinner)
      : getLegacyMTPStyle(mtp, isOnBiggestWinner);
    return returnCSS;
  }}
`;

export const PlainMTPText = styled.span`
  display: block;
  font-family: ${({ isRaceOff, isMyBets }) =>
    isRaceOff || isMyBets ? fontMedium : fontNormal};
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  ${(props) => {
    if (props.isRaceOff) {
      return css`
        color: ${buildColor("green", "600")};
      `;
    }
    if (props.isResulted) {
      return css`
        color: ${buildColor("grey", "700")};
      `;
    }
    return css`
      color: ${buildColor("grey", "800")};
    `;
  }}
  white-space: pre;
`;

export default MTPTime;
