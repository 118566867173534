import mediator from "@tvg/mediator";

export const onBackButtonClickGtm = () => {
  mediator.base.dispatch({
    type: "RAF_EMAIL_REFERRAL_ACTION",
    payload: {
      action: "Back Arrow Clicked",
      category: "Refer A Friend",
      label: "Return To Referrals Page",
      module: "Email Referral"
    }
  });
};

export const onAddAnotherEmailClickGtm = () => {
  mediator.base.dispatch({
    type: "RAF_EMAIL_REFERRAL_ACTION",
    payload: {
      action: "Field Opened",
      category: "Refer A Friend",
      label: "Add Another Email",
      module: "Email Referral"
    }
  });
};

export const onEmailFieldCompletedGtm = () => {
  mediator.base.dispatch({
    type: "RAF_EMAIL_REFERRAL_ACTION",
    payload: {
      action: "Field Completed",
      category: "Refer A Friend",
      label: "Friends Email",
      module: "Email Referral"
    }
  });
};

export const onReferredByFieldCompletedGtm = () => {
  mediator.base.dispatch({
    type: "RAF_EMAIL_REFERRAL_ACTION",
    payload: {
      action: "Field Completed",
      category: "Refer A Friend",
      label: "Referred By",
      module: "Email Referral"
    }
  });
};

export const onSuccessfullySendInvitesGtm = (invites: number) => {
  mediator.base.dispatch({
    type: "RAF_EMAIL_REFERRAL_ACTION",
    payload: {
      action: "Send Invites Success",
      category: "Refer A Friend",
      label: invites.toString(),
      module: "Email Referral"
    }
  });
};

export const onSuccessScreenButtonClickGtm = (label: string) => {
  mediator.base.dispatch({
    type: "RAF_EMAIL_REFERRAL_ACTION",
    payload: {
      action: "CTA Clicked",
      category: "Refer A Friend",
      label,
      module: "Email Referral"
    }
  });
};

export const onOpenReferralGtm = () => {
  mediator.base.dispatch({
    type: "RAF_EMAIL_REFERRAL_ACTION",
    payload: {
      action: "Referral Opened",
      category: "Refer A Friend",
      module: "Referral Centre"
    }
  });
};

export const onCloseReferralGtm = () => {
  mediator.base.dispatch({
    type: "RAF_EMAIL_REFERRAL_ACTION",
    payload: {
      action: "Referral Closed",
      category: "Refer A Friend",
      module: "Referral Centre"
    }
  });
};

export const onSuccessfullySendReminderGtm = () => {
  mediator.base.dispatch({
    type: "RAF_EMAIL_REFERRAL_ACTION",
    payload: {
      action: "Send Referral Reminder Success",
      category: "Refer A Friend",
      module: "Referral Centre"
    }
  });
};
