import React, { memo, useState } from "react";
import BetUtils from "@tvg/utils/betSelection";
import { ButtonGroup, Container } from "./styled-components";
import {
  BetButtonsProps,
  BetTypeCodesEnum,
  BetTypeCodesReversedEnum
} from "./types";
import { Button } from "./components";

const getIcon = (
  columnCount: number,
  isActive: boolean,
  isKey: boolean,
  position: number,
  isLegBetType?: boolean
) => {
  if (isKey) {
    const keyVariantsIcon = position === 0 ? "key" : "plus";
    return isActive ? "tick" : keyVariantsIcon;
  }

  if (columnCount > 1 && !isLegBetType) {
    return undefined;
  }

  return isActive ? "tick" : "plus";
};

export const BetButtons = memo(
  ({ wagerType, onPress, activeButtons, legSelected = 0 }: BetButtonsProps) => {
    if (!wagerType) {
      return null;
    }
    const [isHoveringAll, setIsHoveringAll] = useState(false);
    const { isKey = false, specialGroup = false } = wagerType || {};
    const buttonNames = BetUtils.getWagerTypesNames(wagerType);
    const columnCount = wagerType?.columnCount || 1;
    const buttonLabel = columnCount > 1 && !specialGroup ? "exotic" : "simple";
    const betLegTypes = ["Pick", "Daily", "Grand", "Placed"];
    const isLegBetType =
      betLegTypes.some((type) => wagerType.group.name.includes(type)) &&
      columnCount > 1;
    const hasMultipleLegs = isLegBetType || (!isLegBetType && legSelected > 0);

    const getMaxWidth = () =>
      !((columnCount === 1 && !isLegBetType) || isLegBetType);

    const getColumnCount = () => (isLegBetType ? 1 : columnCount);

    const getButtontext = (column: number) => {
      if (isLegBetType && legSelected === 0) return "Win";
      if (hasMultipleLegs && legSelected > 0) return "Select";
      return buttonNames[column];
    };

    const getActiveButtonsState = (position: number) =>
      hasMultipleLegs ? activeButtons[legSelected] : activeButtons[position];

    return (
      <Container>
        <ButtonGroup
          hasMaxWidth={getMaxWidth()}
          isSimpleBetButtons={specialGroup}
        >
          {Array(getColumnCount())
            .fill(null)
            .map((_, i) => (
              <Button
                key={i.toString()}
                text={getButtontext(i)}
                icon={getIcon(
                  columnCount,
                  getActiveButtonsState(i),
                  isKey,
                  i,
                  isLegBetType
                )}
                onPress={() =>
                  onPress(
                    buttonNames[i],
                    activeButtons[i],
                    hasMultipleLegs ? legSelected : i
                  )
                }
                qaLabel={`bet-button-${buttonLabel}-${buttonNames[i]?.toLowerCase()}`}
                isSelected={getActiveButtonsState(i)}
                isFullWidth={getMaxWidth()}
                isFirstChild={i === 0 || isLegBetType}
                isLastChild={i === columnCount - 1 || isLegBetType}
                isHoveringAll={isHoveringAll}
              />
            ))}
        </ButtonGroup>
        {columnCount > 1 && specialGroup && (
          <Button
            ml="space-4"
            text="Across the board"
            onPress={() =>
              onPress(
                "Across the board",
                activeButtons.every((isActive) => isActive)
              )
            }
            qaLabel="bet-button-across-the-board"
            isSelected={activeButtons.every((isActive) => isActive)}
            isLastChild
            isFirstChild
            hasShadow
            onMouseEnter={() => setIsHoveringAll(true)}
            onMouseLeave={() => setIsHoveringAll(false)}
          />
        )}
      </Container>
    );
  }
);

export { BetButtonsProps, BetTypeCodesEnum, BetTypeCodesReversedEnum };
