import styled from "styled-components";

export const RefereeStatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 12px;

  & > div:first-of-type {
    text-transform: uppercase;
  }

  & > div:nth-of-type(2) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
`;
