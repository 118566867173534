import React, { createElement } from "react";

import FeaturedSlots from "../_organisms/FeaturedSlots";
import PromoSlots from "../_organisms/PromoSlots";
import Banner from "../_organisms/Banner";
import PromosBanner from "../_organisms/PromosBanner";
import EverGreen from "../_static/PromosEvergreenContent";
import LegalsContent from "../_static/LegalsContent";
import PromosLegalContent from "../_static/PromosLegalContent";
import SeoContent from "../_static/PromoSeoContent";
import PromosBodyContent from "../_organisms/PromosBodyContent";
import BodyContent from "../_organisms/BodyContent";
import Footer from "../_atoms/Footer";
import RichText from "../_molecules/RichText";
import TableContent from "../_organisms/TableContent";
import InfoContent from "../_organisms/InfoContent";
import LeaderBoardStandings from "../_organisms/LeaderBoardStandings";
import ReferralCenter from "../_organisms/ReferralCenter";

let COMPONENTS_DICTIONARY = Object.create(null);

COMPONENTS_DICTIONARY = {
  banner: PromosBanner,
  banner_v2_0: Banner,
  top_promos: FeaturedSlots,
  all_promos: PromoSlots,
  evergreen_content: EverGreen,
  legal_content: PromosLegalContent,
  legal_content_v2_0: LegalsContent,
  seo_content: SeoContent,
  content_section: PromosBodyContent,
  content_section_v2_0: BodyContent,
  footer: Footer,
  rich_text: RichText,
  table_content_v2_0: TableContent,
  info_section_v2_0: InfoContent,
  leader_board: LeaderBoardStandings,
  referral_center: ReferralCenter
};

export default (blok) => {
  if (typeof COMPONENTS_DICTIONARY[blok.component] !== "undefined") {
    return React.createElement(COMPONENTS_DICTIONARY[blok.component], {
      key: blok._uid,
      content: blok
    });
  }

  return React.createElement(
    () => <div>The component {blok.component} has not been created yet.</div>,
    { key: blok._uid }
  );
};
