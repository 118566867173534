import React, { memo } from "react";
import { isEqual, get, find } from "lodash";
import ProcessingOverlay from "@tvg/atomic-ui/_static/ProcessingOverlay";
// @ts-ignore
import { setLink } from "@tvg-mar/utils/socialLinksUtils";
// @ts-ignore
import Components from "../../_dictionary";
// @ts-ignore
import StatusNotifications from "../../_organisms/StatusNotifications";
import type { Props } from "./types";
import {
  ReferPageContainer,
  ReferCodeInput,
  ProcessingContainer,
  FlexContainer,
  FlexContentWrapper,
  LegalContentWrapper
} from "./styled-components";

const ReferFriendPage = ({
  isLogged,
  isOpted,
  isEligible,
  content,
  referCode,
  referPath,
  isOptinError,
  optinTypeError,
  userEmail,
  isLoading,
  isLoadingReferrals,
  promoId,
  enableOverlayReferralStatus,
  referralsEmptyTitle,
  referralsEmptyDescription,
  referralCenterOpening,
  referrals = [],
  totalBonusAmount = 0,
  totalCompletedReferrals = 0,
  qaLabel = "referLanding",
  isRnTablet
}: Props) => {
  const banner = get(content, "body[0]");
  const bloks = get(content, "body[1].bloks", []);
  const referralCenter = find(bloks, ["component", "referral_center"]);
  const howToPlay = find(bloks, ["component", "info_section_v2_0"]);
  const legalContent = find(bloks, ["component", "legal_content_v2_0"]);
  return (
    <ReferPageContainer data-qa-label={qaLabel}>
      {isLoading ? (
        <ProcessingContainer data-qa-label={`${qaLabel}ProcessingContainer`}>
          <ProcessingOverlay qaLabel={`${qaLabel}ProcessingContainer`} />
        </ProcessingContainer>
      ) : (
        <>
          <ReferCodeInput
            readOnly
            id="refer-input"
            data-qa-label={`${qaLabel}referCodeInput`}
            type="text"
            value={setLink(referCode, referPath)}
          />
          {banner &&
            Components({
              ...banner,
              isLogged,
              isOpted,
              isEligible,
              referCode,
              referPath,
              userEmail,
              promoId,
              isRnTablet
            })}
          {isOptinError || !isEligible ? (
            <StatusNotifications
              type="error"
              errorType={!isEligible ? "NotEligibleException" : optinTypeError}
              qaLabel={`${qaLabel}statusNotificationsError`}
            />
          ) : (
            <StatusNotifications
              type="success"
              status={isOpted}
              qaLabel={`${qaLabel}statusNotificationsSuccess`}
            />
          )}
          <FlexContainer data-qa-label={`${qaLabel}eligibleContainer`}>
            {isLogged && isEligible && !isOptinError && referralCenter && (
              <FlexContentWrapper
                data-qa-label={`${qaLabel}referralCenterContainer`}
              >
                {Components({
                  ...referralCenter,
                  isLoadingReferrals,
                  enableOverlayReferralStatus,
                  referralsEmptyTitle,
                  referralsEmptyDescription,
                  referralCenterOpening,
                  referrals,
                  totalBonusAmount,
                  totalCompletedReferrals
                })}
              </FlexContentWrapper>
            )}
            {howToPlay && (
              <FlexContentWrapper
                data-qa-label={`${qaLabel}howToPlayContainer`}
              >
                {Components({
                  ...howToPlay,
                  isModule:
                    isLogged && isEligible && !isOptinError && referralCenter
                })}
              </FlexContentWrapper>
            )}
          </FlexContainer>
          <LegalContentWrapper>
            {legalContent && Components({ ...legalContent })}
          </LegalContentWrapper>
        </>
      )}
    </ReferPageContainer>
  );
};

const areEqual = (prevProps: Props, nextProps: Props) =>
  prevProps.isLogged === nextProps.isLogged &&
  prevProps.isOpted === nextProps.isOpted &&
  prevProps.isEligible === nextProps.isEligible &&
  prevProps.isServiceError === nextProps.isServiceError &&
  prevProps.userEmail === nextProps.userEmail &&
  prevProps.referCode === nextProps.referCode &&
  prevProps.isOptinError === nextProps.isOptinError &&
  prevProps.optinTypeError === nextProps.optinTypeError &&
  prevProps.isLoadingReferrals === nextProps.isLoadingReferrals &&
  isEqual(prevProps.referrals, nextProps.referrals) &&
  isEqual(prevProps.content, nextProps.content);

export default memo(ReferFriendPage, areEqual);
