import styled, { css } from "styled-components";
import { space, layout, compose } from "styled-system";
import { Theme } from "@tvg/design-system";

const styledUtils = compose(space, layout);

export const Container = styled.div<{
  showOnlySelections?: boolean;
  theme: Theme;
}>`
  overflow: hidden;
  ${styledUtils}
  border-radius: 2px;
  ${({ showOnlySelections }) =>
    !showOnlySelections &&
    css`
      border: 1px solid var(--fd-colors-border-subtle);
    `}

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;
export const Header = styled.div<{
  theme: Theme;
}>`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 1px;
  background-color: var(--fd-colors-background-layer);
  border-bottom-color: var(--fd-colors-border-subtle);
  padding: 6px 12px;
`;

export const ContentActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--fd-colors-background-surface);
`;

export const Content = styled.div<{
  hasButtons: boolean;
  theme: Theme;
  isMyBets: boolean;
}>`
  padding: 12px;
  max-width: ${({ hasButtons }) => (hasButtons ? "calc(100% - 72px)" : "100%")};
  ${({ isMyBets }) =>
    isMyBets &&
    css`
      width: 100%;
    `};
`;

export const Actions = styled.div<{
  theme: Theme;
}>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 6px 12px 6px 0;
`;

export const VerticalParagraphLeft = styled.div`
  text-align: left;
`;

export const VerticalParagraphRight = styled.div`
  text-align: right;
`;
