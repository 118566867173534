export const chevronRight = {
  id: "chevronRight",
  shapes: [
    {
      path: "M596.992 512l-248.832-248.96c-5.77-5.787-9.337-13.773-9.337-22.592s3.567-16.805 9.338-22.593l-0.001 0.001 45.248-45.248c5.794-5.808 13.805-9.401 22.656-9.401s16.862 3.593 22.655 9.401l0.001 0.001 316.8 316.8c5.789 5.791 9.369 13.789 9.369 22.624s-3.58 16.833-9.369 22.624l-316.8 316.8c-5.794 5.808-13.805 9.401-22.656 9.401s-16.862-3.593-22.655-9.401l-0.001-0.001-45.248-45.312c-5.789-5.791-9.369-13.789-9.369-22.624s3.58-16.833 9.369-22.624l248.96-248.896z",
      fill: null
    }
  ]
};

export const optedInTag = {
  id: "optedInTag",
  shapes: [
    {
      path: "M169.6 192v264.321c0 22.195 9.221 43.393 25.458 58.525l377.82 352.107c6.304 5.875 16.129 5.702 22.222-0.391l264.665-264.665c6.089-6.089 6.267-15.905 0.402-22.211l-351.743-378.17c-15.135-16.272-36.356-25.515-58.578-25.515h-264.246c-8.837 0-16 7.163-16 16zM73.6 192c0-61.856 50.144-112 112-112h264.246c48.889 0 95.576 20.335 128.872 56.134l351.743 378.17c41.053 44.138 39.81 112.851-2.814 155.475l-264.665 264.665c-42.654 42.654-111.426 43.865-155.555 2.739l-377.82-352.107c-35.722-33.291-56.008-79.926-56.008-128.755v-264.321zM320.452 416c-44.183 0-80-35.817-80-80s35.817-80 80-80c44.183 0 80 35.817 80 80s-35.817 80-80 80z",
      fill: null
    },
    {
      path: "M1024 256c0 141.385-114.615 256-256 256s-256-114.615-256-256c0-141.385 114.615-256 256-256s256 114.615 256 256z",
      fill: "#38AB4F"
    },
    {
      path: "M720.59 299.647l135.764-135.765c6.248-6.248 16.379-6.248 22.627 0l11.314 11.314c6.248 6.248 6.248 16.379 0 22.627l-158.392 158.392c-6.248 6.248-16.379 6.248-22.627 0l-67.882-67.882c-6.248-6.248-6.248-16.379 0-22.627l11.314-11.314c6.248-6.248 16.379-6.248 22.627 0l45.255 45.255z",
      fill: "#FFFFFF"
    }
  ]
};

export const errorOutline = {
  id: "errorOutline",
  shapes: [
    {
      path: "M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M8,1.5 C4.41014913,1.5 1.5,4.41014913 1.5,8 C1.5,11.5898509 4.41014913,14.5 8,14.5 C11.5898509,14.5 14.5,11.5898509 14.5,8 C14.5,4.41014913 11.5898509,1.5 8,1.5 Z M5.70190296,4.64124279 L8,6.93933983 L10.298097,4.64124279 C10.4933592,4.44598064 10.8099417,4.44598064 11.0052038,4.64124279 L11.3587572,4.99479618 C11.5540194,5.19005833 11.5540194,5.50664082 11.3587572,5.70190296 L9.06066017,8 L11.3587572,10.298097 C11.5540194,10.4933592 11.5540194,10.8099417 11.3587572,11.0052038 L11.0052038,11.3587572 C10.8099417,11.5540194 10.4933592,11.5540194 10.298097,11.3587572 L8,9.06066017 L5.70190296,11.3587572 C5.50664082,11.5540194 5.19005833,11.5540194 4.99479618,11.3587572 L4.64124279,11.0052038 C4.44598064,10.8099417 4.44598064,10.4933592 4.64124279,10.298097 L6.93933983,8 L4.64124279,5.70190296 C4.44598064,5.50664082 4.44598064,5.19005833 4.64124279,4.99479618 L4.99479618,4.64124279 C5.19005833,4.44598064 5.50664082,4.44598064 5.70190296,4.64124279 Z",
      fill: "#C92C2C"
    }
  ]
};

export const arrowBack = {
  id: "arrowBack",
  shapes: [
    {
      path: "M321.28 575.744l184.96 185.024c5.789 5.791 9.369 13.789 9.369 22.624s-3.58 16.833-9.369 22.624l-45.248 45.248c-5.791 5.789-13.789 9.369-22.624 9.369s-16.833-3.58-22.624-9.369l-316.8-316.8c-5.77-5.787-9.337-13.773-9.337-22.592s3.567-16.805 9.338-22.593l-0.001 0.001 316.8-316.8c5.791-5.789 13.789-9.369 22.624-9.369s16.833 3.58 22.624 9.369l45.248 45.248c5.789 5.791 9.369 13.789 9.369 22.624s-3.58 16.833-9.369 22.624l-184.768 184.768h590.464c17.673 0 32 14.327 32 32v0 64c0 17.673-14.327 32-32 32v0h-590.656z",
      fill: null
    }
  ]
};

export const referFriend = {
  id: "referFriend",
  shapes: [
    {
      path: "M8.00223 7.49727C6.20731 7.49727 4.75223 6.04281 4.75223 4.24863C4.75223 2.45446 6.20731 1 8.00223 1C9.79716 1 11.2522 2.45446 11.2522 4.24863C11.2522 6.04281 9.79716 7.49727 8.00223 7.49727ZM8.00223 5.9979C8.96873 5.9979 9.75223 5.21473 9.75223 4.24863C9.75223 3.28254 8.96873 2.49937 8.00223 2.49937C7.03573 2.49937 6.25223 3.28254 6.25223 4.24863C6.25223 5.21473 7.03573 5.9979 8.00223 5.9979ZM4.19899 9.7445C4.20377 9.74286 4.20853 9.74107 4.21326 9.73913C4.21172 9.73976 4.21018 9.7404 4.20865 9.74104L4.19899 9.7445ZM12.0195 8.61815L14.8479 11.4466C15.0432 11.6418 15.0432 11.9584 14.8479 12.1537L12.0195 14.9821C11.8242 15.1774 11.5076 15.1774 11.3124 14.9821L10.9588 14.6286C10.7636 14.4333 10.7636 14.1167 10.9588 13.9214L12.3375 12.5428H7.95883C7.68268 12.5428 7.45883 12.3189 7.45883 12.0428V11.5428C7.45883 11.2666 7.68268 11.0428 7.95883 11.0428H12.3228L10.9588 9.67881C10.7636 9.48354 10.7636 9.16696 10.9588 8.9717L11.3124 8.61815C11.5076 8.42288 11.8242 8.42288 12.0195 8.61815ZM8.06778 8.24926C8.34393 8.24926 8.56778 8.47312 8.56778 8.74926V9.24863C8.56778 9.52477 8.34393 9.74863 8.06778 9.74863H4.39616C4.24421 9.74869 4.1005 9.81779 4.00563 9.93649C3.82492 10.1626 3.70343 10.3876 3.64116 10.6117C3.59599 10.7741 3.20905 12.1165 2.48034 14.6386C2.40947 14.8839 2.16521 15.0364 1.91374 14.9923C1.79103 14.9708 1.67311 14.9474 1.55999 14.9219L1.47981 14.9043L1.40105 14.8879C1.16046 14.8393 0.995389 14.6255 1.0001 14.3885L1.01001 14.2988C1.01127 14.2925 1.01265 14.2863 1.01415 14.2801C1.60722 11.8313 1.94051 10.4747 2.01401 10.2103C2.26011 9.32492 2.52271 8.68324 3.34961 8.3429C3.50031 8.28111 3.95512 8.24926 4.11808 8.24926H8.06778Z",
      fill: "#626D78",
      fillRule: "evenodd",
      clipRule: "evenodd"
    }
  ]
};

export const add = {
  id: "add",
  shapes: [
    {
      path: "M7.25 12.5C7.25 12.7761 7.47386 13 7.75 13H8.25C8.52614 13 8.75 12.7761 8.75 12.5V8.75H12.5C12.7761 8.75 13 8.52614 13 8.25V7.75C13 7.47386 12.7761 7.25 12.5 7.25H8.75V3.5C8.75 3.22386 8.52614 3 8.25 3H7.75C7.47386 3 7.25 3.22386 7.25 3.5V7.25H3.5C3.22386 7.25 3 7.47386 3 7.75V8.25C3 8.52614 3.22386 8.75 3.5 8.75H7.25V12.5Z",
      fill: "#626D78"
    }
  ]
};

export const contests = {
  id: "contests",
  shapes: [
    {
      path: "M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5ZM8.25 3.5C8.52615 3.5 8.75 3.72386 8.75 4V4.31059C9.423 4.46069 10.0257 4.81218 10.4162 5.317C10.5007 5.42621 10.4627 5.58104 10.3449 5.65306L9.49168 6.17468C9.37388 6.2467 9.21951 6.2079 9.11739 6.11496C8.91809 5.93364 8.59652 5.77361 8.17712 5.7365C7.17052 5.64745 6.8887 6.11563 6.8659 6.37343C6.84268 6.63582 6.90308 6.78279 6.9988 6.88234C7.11123 6.99928 7.38986 7.17271 8.04494 7.23067C8.89197 7.3056 9.57986 7.55476 10.0402 8.03352C10.5172 8.52965 10.6358 9.15144 10.586 9.71425C10.4946 10.7469 9.7393 11.4194 8.75 11.6445V12C8.75 12.2761 8.52615 12.5 8.25 12.5H7.75C7.47385 12.5 7.25 12.2761 7.25 12V11.6541C6.56 11.5094 5.94016 11.1539 5.54147 10.6385C5.45699 10.5293 5.49504 10.3745 5.61284 10.3024L6.46602 9.78082C6.58382 9.70879 6.73818 9.7476 6.84031 9.84052C7.0396 10.0219 7.36118 10.1819 7.78057 10.219C8.78717 10.308 9.06899 9.83986 9.0918 9.58206C9.11501 9.31969 9.05461 9.1727 8.95889 9.07315C8.84647 8.95622 8.56783 8.78279 7.91276 8.72484C7.06573 8.6499 6.37785 8.40073 5.91751 7.92198C5.44049 7.42584 5.32195 6.80405 5.37173 6.24124C5.46439 5.19392 6.23999 4.51709 7.25 4.30168V4C7.25 3.72386 7.47385 3.5 7.75 3.5H8.25Z",
      fill: "#626D78",
      fillRule: "evenodd",
      clipRule: "evenodd"
    }
  ]
};

export const warning = {
  id: "warning",
  shapes: [
    {
      path: "M1.82248 14.25H14.1775C14.5524 14.25 14.794 13.8527 14.6214 13.5198L8.44388 1.60605C8.25732 1.24626 7.74268 1.24626 7.55612 1.60605L1.3786 13.5198C1.20602 13.8527 1.44757 14.25 1.82248 14.25Z",
      fill: "#FAD5AF"
    },
    {
      path: "M1.3786 13.5198L0.712785 13.1746H0.712785L1.3786 13.5198ZM7.55612 1.60605L8.22194 1.95129L8.22194 1.95129L7.55612 1.60605ZM8.44388 1.60605L7.77806 1.95129V1.95129L8.44388 1.60605ZM14.6214 13.5198L13.9556 13.8651H13.9556L14.6214 13.5198ZM14.1775 13.5H1.82248V15H14.1775V13.5ZM2.04442 13.8651L8.22194 1.95129L6.89031 1.26081L0.712785 13.1746L2.04442 13.8651ZM7.77806 1.95129L13.9556 13.8651L15.2872 13.1746L9.10969 1.26081L7.77806 1.95129ZM8.75 10V6H7.25V10H8.75ZM8.75 12.5V11H7.25V12.5H8.75ZM8.22194 1.95129C8.12866 2.13118 7.87134 2.13118 7.77806 1.95129L9.10969 1.26081C8.6433 0.361339 7.3567 0.36134 6.89031 1.26081L8.22194 1.95129ZM1.82248 13.5C2.00993 13.5 2.13071 13.6987 2.04442 13.8651L0.712785 13.1746C0.281341 14.0067 0.885202 15 1.82248 15V13.5ZM14.1775 15C15.1148 15 15.7187 14.0067 15.2872 13.1746L13.9556 13.8651C13.8693 13.6987 13.9901 13.5 14.1775 13.5V15Z",
      fill: "#BD6909"
    }
  ]
};

export const promoOptedIn = {
  id: "promoOptedIn",
  shapes: [
    {
      path: "M8.43472 1.75H13.2487C13.801 1.75 14.2487 2.19771 14.2487 2.75V7.56397C14.2487 7.84229 14.1327 8.10802 13.9286 8.29725L7.79721 13.9825C7.40299 14.348 6.79032 14.3364 6.41018 13.9563L2.04241 9.58851C1.66226 9.20836 1.6507 8.5957 2.01623 8.20148L7.70144 2.07008C7.89067 1.86599 8.1564 1.75 8.43472 1.75Z",
      fill: "#B1DEBA"
    },
    {
      path: "M6.00017 6.99805L8.00017 8.99805L12.0002 4.99805M13.2487 1.75H8.43472C8.1564 1.75 7.89067 1.86599 7.70144 2.07008L2.01623 8.20148C1.6507 8.5957 1.66226 9.20836 2.04241 9.58851L6.41018 13.9563C6.79032 14.3364 7.40299 14.348 7.79721 13.9825L13.9286 8.29725C14.1327 8.10802 14.2487 7.84229 14.2487 7.56397V2.75C14.2487 2.19771 13.801 1.75 13.2487 1.75Z",
      fill: "#B1DEBA",
      strokeColor: "#2D873F",
      strokeWidth: 1.5,
      strokeLinejoin: "round"
    }
  ]
};

export const copyLink = {
  id: "copyLink",
  shapes: [
    {
      path: "M11.7341 7.21094C11.3474 6.82422 10.9255 6.54297 10.4684 6.29688C10.2927 6.22656 10.1169 6.26172 9.97626 6.40234L9.83563 6.54297C9.51923 6.82422 9.34344 7.21094 9.30829 7.59766C9.27313 7.77344 9.3786 7.94922 9.51923 8.05469C9.76532 8.16016 10.152 8.40625 10.3278 8.61719C11.488 9.77734 11.488 11.6406 10.3278 12.8008L7.6911 15.4375C6.53094 16.5977 4.66766 16.5977 3.50751 15.4375C2.34735 14.2773 2.34735 12.4141 3.50751 11.2539L5.12469 9.63672C5.23016 9.53125 5.26532 9.39062 5.23016 9.25C5.15985 8.89844 5.08954 8.30078 5.05438 7.91406C5.05438 7.5625 4.59735 7.38672 4.35126 7.63281C3.92938 8.05469 3.26141 8.72266 2.13641 9.84766C0.20282 11.7812 0.20282 14.9102 2.13641 16.8086C4.03485 18.7422 7.16376 18.7422 9.09735 16.8086C11.9802 13.9258 11.8395 14.0664 12.0505 13.7852C13.6325 11.8867 13.527 9.00391 11.7341 7.21094ZM17.2184 1.72656C15.32 -0.207031 12.1911 -0.207031 10.2575 1.72656C7.37469 4.60938 7.51532 4.46875 7.30438 4.75C5.72235 6.64844 5.82782 9.53125 7.62079 11.3242C8.00751 11.7109 8.42938 11.9922 8.88641 12.2383C9.06219 12.3086 9.23798 12.2734 9.3786 12.1328L9.51923 11.9922C9.83563 11.7109 10.0114 11.3242 10.0466 10.9375C10.0817 10.7617 9.97626 10.5859 9.83563 10.4805C9.58954 10.375 9.20282 10.1289 9.02704 9.91797C7.86688 8.75781 7.86688 6.89453 9.02704 5.73438L11.6638 3.09766C12.8239 1.9375 14.6872 1.9375 15.8474 3.09766C17.0075 4.25781 17.0075 6.12109 15.8474 7.28125L14.2302 8.89844C14.1247 9.00391 14.0895 9.14453 14.1247 9.28516C14.195 9.63672 14.2653 10.2344 14.3005 10.6211C14.3005 10.9727 14.7575 11.1484 15.0036 10.9023C15.4255 10.4805 16.0934 9.8125 17.2184 8.6875C19.152 6.75391 19.152 3.625 17.2184 1.72656Z",
      fill: "#FAFCFF"
    }
  ]
};

export const textIcon = {
  id: "text",
  shapes: [
    {
      path: "M17.3901 1.25C16.7438 1.25 12.0771 1.25 3.39014 1.25C2.4253 1.25 1.64014 2.09125 1.64014 3.125V13.125C1.64014 14.1588 2.4253 15 3.39014 15H6.57747L8.73814 18.4713C8.84664 18.645 9.02864 18.75 9.22347 18.75C9.4183 18.75 9.6003 18.6463 9.7088 18.4713L11.8695 15H17.3901C18.355 15 19.1401 14.1588 19.1401 13.125C19.1401 7.0737 19.1401 3.74037 19.1401 3.125C19.1401 2.20194 18.3597 1.25 17.3901 1.25ZM17.3485 12.5C17.3485 12.845 17.086 13.125 16.7651 13.125H11.5568C11.362 13.125 11.18 13.2287 11.0715 13.4037L9.22347 16.3265L7.37547 13.4037C7.26697 13.2287 7.08497 13.125 6.89014 13.125H4.01514C3.6943 13.125 3.4318 12.845 3.4318 12.5V3.75C3.4318 3.405 3.6943 3.125 4.01514 3.125H16.7651C17.086 3.125 17.3485 3.405 17.3485 3.75V12.5Z",
      fill: "#FAFCFF",
      fillRule: "evenodd",
      clipRule: "evenodd"
    }
  ]
};

export const faceBook = {
  id: "faceBook",
  shapes: [
    {
      path: "M14.1836 11.375L14.6758 8.14062H11.5469V6.03125C11.5469 5.11719 11.9688 4.27344 13.375 4.27344H14.8164V1.49609C14.8164 1.49609 13.5156 1.25 12.2852 1.25C9.71875 1.25 8.03125 2.83203 8.03125 5.64453V8.14062H5.14844V11.375H8.03125V19.25H11.5469V11.375H14.1836Z",
      fill: "#FAFCFF"
    }
  ]
};

export const twitter = {
  id: "twitter",
  shapes: [
    {
      path: "M17.1367 7.59375C17.8398 7.06641 18.4727 6.43359 18.9648 5.69531C18.332 5.97656 17.5938 6.1875 16.8555 6.25781C17.6289 5.80078 18.1914 5.09766 18.4727 4.21875C17.7695 4.64062 16.9609 4.95703 16.1523 5.13281C15.4492 4.39453 14.5 3.97266 13.4453 3.97266C11.4062 3.97266 9.75391 5.625 9.75391 7.66406C9.75391 7.94531 9.78906 8.22656 9.85938 8.50781C6.80078 8.33203 4.05859 6.85547 2.23047 4.64062C1.91406 5.16797 1.73828 5.80078 1.73828 6.50391C1.73828 7.76953 2.37109 8.89453 3.39062 9.5625C2.79297 9.52734 2.19531 9.38672 1.70312 9.10547V9.14062C1.70312 10.9336 2.96875 12.4102 4.65625 12.7617C4.375 12.832 4.02344 12.9023 3.70703 12.9023C3.46094 12.9023 3.25 12.8672 3.00391 12.832C3.46094 14.3086 4.83203 15.3633 6.44922 15.3984C5.18359 16.3828 3.60156 16.9805 1.87891 16.9805C1.5625 16.9805 1.28125 16.9453 1 16.9102C2.61719 17.9648 4.55078 18.5625 6.66016 18.5625C13.4453 18.5625 17.1367 12.9727 17.1367 8.08594C17.1367 7.91016 17.1367 7.76953 17.1367 7.59375Z",
      fill: "#FAFCFF"
    }
  ]
};

export const email = {
  id: "email",
  shapes: [
    {
      path: "M16.4715 3C15.9261 3 11.6534 3 3.74427 3C2.83518 3 2.10791 3.72727 2.10791 4.72727V16.2727C2.10791 17.1818 2.83518 18 3.74427 18H6.65336H11.4715H16.4715C17.3806 18 18.1079 17.2727 18.1079 16.2727C18.1079 10.7273 18.1079 5.27273 18.1079 4.72727C18.017 3.90909 17.2897 3 16.4715 3ZM4.28973 4.72727H15.9261C16.1988 4.72727 16.4715 5 16.4715 5.27273V6.72727L10.1079 9.45455L3.74427 6.72727V5.27273C3.74427 5 4.017 4.72727 4.28973 4.72727ZM15.8352 16.2727H11.1079H6.83518H4.28973C4.017 16.2727 3.74427 16 3.74427 15.7273V9C5.017 9.54545 6.92609 10.3636 9.38064 11.4545C9.83518 11.6364 10.2897 11.6364 10.7443 11.4545C13.1988 10.3636 15.1079 9.54545 16.3806 9V15.6364C16.3806 16 16.1079 16.2727 15.8352 16.2727Z",
      fill: "#FAFCFF",
      fillRule: "evenodd",
      clipRule: "evenodd"
    }
  ]
};

export const promo = {
  id: "promo",
  shapes: [
    {
      path: "M13.2484 1.75195H8.43448C8.15616 1.75195 7.89043 1.86794 7.70119 2.07203L2.01598 8.20343C1.65046 8.59765 1.66202 9.21032 2.04216 9.59046L6.40993 13.9582C6.79008 14.3384 7.40275 14.3499 7.79696 13.9844L13.9284 8.29921C14.1325 8.10997 14.2484 7.84424 14.2484 7.56592V2.75195C14.2484 2.19967 13.8007 1.75195 13.2484 1.75195ZM10.4993 6.24872C10.9131 6.24872 11.2486 5.91322 11.2486 5.49936C11.2486 5.0855 10.9131 4.75 10.4993 4.75C10.0854 4.75 9.74992 5.0855 9.74992 5.49936C9.74992 5.91322 10.0854 6.24872 10.4993 6.24872Z",
      fill: "none",
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeColor: "#626D78",
      strokeWidth: 1.5,
      strokeLinejoin: "round"
    }
  ]
};

export const arrowRight = {
  id: "arrowRight",
  shapes: [
    {
      path: "M12.1893 8.74999H2C1.72386 8.74999 1.5 8.52613 1.5 8.24999V7.74999C1.5 7.47384 1.72386 7.24999 2 7.24999H12.1893L8.82322 3.88386C8.62796 3.6886 8.62796 3.37202 8.82322 3.17676L9.17677 2.8232C9.37203 2.62794 9.68862 2.62794 9.88388 2.8232L14.5303 7.46965C14.8232 7.76254 14.8232 8.23742 14.5303 8.53031L9.88388 13.1768C9.68862 13.372 9.37203 13.372 9.17677 13.1768L8.82322 12.8232C8.62796 12.6279 8.62796 12.3114 8.82322 12.1161L12.1893 8.74999Z",
      fill: "#384048"
    }
  ]
};

export const cup = {
  id: "cup",
  shapes: [
    {
      path: "M4.25003 1.75H11.7501L11.75 3.5C11.75 3.5 11.75 3.58977 11.7454 3.75C11.726 4.41424 11.6262 6.28929 11.113 7.99826C10.5998 9.70717 9.6732 11.25 8.00003 11.25C6.32686 11.25 5.40022 9.70717 4.88702 7.99826C4.3738 6.2893 4.27406 4.41424 4.25468 3.75C4.25 3.58977 4.25 3.5 4.25 3.5L4.25003 1.75Z",
      fill: "none",
      fillOpacity: 0.1,
      fillRule: "evenodd",
      clipRule: "evenodd"
    },
    {
      path: "M8.00003 11.25L8 14.25M8.00003 11.25C6.32686 11.25 5.40022 9.70717 4.88702 7.99826M8.00003 11.25C9.6732 11.25 10.5998 9.70717 11.113 7.99826M4.25 3.5L4.25003 1.75H11.7501L11.75 3.5C11.75 3.5 11.75 3.58977 11.7454 3.75M4.25 3.5C4.25 3.5 4.25001 5.87707 4.88702 7.99826M4.25 3.5C4.25 3.5 4.25 3.58977 4.25468 3.75M8 14.25H5M8 14.25H11M4.25468 3.75H1.75C1.75 3.75 1.75 7.90092 4.88702 7.99826M4.25468 3.75C4.27406 4.41424 4.3738 6.2893 4.88702 7.99826M11.113 7.99826C14.25 7.90089 14.25 3.75 14.25 3.75H11.7454M11.113 7.99826C11.6262 6.28929 11.726 4.41424 11.7454 3.75",
      fill: "none",
      strokeColor: "#626D78",
      strokeWidth: 1.5,
      strokeLinejoin: "round"
    }
  ]
};

export const popOut = {
  id: "popOut",
  shapes: [
    {
      path: "M2.75 11.75V4.25C2.75 3.42157 3.42157 2.75 4.25 2.75H11.75C12.5784 2.75 13.25 3.42157 13.25 4.25V11.75C13.25 12.5784 12.5784 13.25 11.75 13.25H4.25C3.42157 13.25 2.75 12.5784 2.75 11.75Z",
      fill: "none"
    },
    {
      path: "M9.5 2.75006H4.25C3.42157 2.75006 2.75 3.42163 2.75 4.25006V11.7501C2.75 12.5785 3.42157 13.2501 4.25 13.2501H11.75C12.5784 13.2501 13.25 12.5785 13.25 11.7501V6.50006M9 7.00006L14.998 1.00201M11 0.750061L15.2501 0.75L15.25 5.00006",
      fill: "none",
      strokeColor: "#384048",
      strokeWidth: 1.5,
      strokeLineJoin: "round"
    }
  ]
};

export const bets = {
  id: "bets",
  shapes: [
    {
      path: "M12.25 1.75H3.75C3.19772 1.75 2.75 2.19772 2.75 2.75V14.5L5 13.5L6.5 14.5L8 13.5L9.5 14.5L11 13.5L13.25 14.5V2.75C13.25 2.19772 12.8023 1.75 12.25 1.75Z",
      fill: "none",
      fillOpacity: 0.1
    },
    {
      path: "M5 5.75H8M5 8.75H11M3.75 1.75H12.25C12.8023 1.75 13.25 2.19772 13.25 2.75V14.5L11 13.5L9.5 14.5L8 13.5L6.5 14.5L5 13.5L2.75 14.5V2.75C2.75 2.19772 3.19772 1.75 3.75 1.75Z",
      fill: "none",
      strokeColor: "#626D78",
      strokeWidth: 1.5,
      strokeLinejoin: "round"
    }
  ]
};

export const chevronDown = {
  id: "chevronDown",
  shapes: [
    {
      path: "M7.46875 11.5304L2.8223 6.88394C2.62704 6.68868 2.62704 6.3721 2.8223 6.17683L3.17586 5.82328C3.37112 5.62802 3.6877 5.62802 3.88296 5.82328L7.99908 9.9394L12.1152 5.82328C12.3105 5.62802 12.627 5.62802 12.8223 5.82328L13.1759 6.17683C13.3711 6.3721 13.3711 6.68868 13.1759 6.88394L8.52941 11.5304C8.23652 11.8233 7.76164 11.8233 7.46875 11.5304Z",
      fill: "#384048",
      fillRule: "evenodd",
      clipRule: "evenodd"
    }
  ]
};

export const chevronUp = {
  id: "chevronUp",
  shapes: [
    {
      path: "M3 10L8 5L13 10",
      fill: "none",
      strokeColor: "#384048",
      strokeWidth: 1.5,
      strokeLineJoin: "round"
    }
  ]
};

export const info = {
  id: "info",
  shapes: [
    {
      path: "M15.2506 8.00422C15.2506 12.0085 12.0046 15.2545 8.00032 15.2545C3.99608 15.2545 0.75 12.0085 0.75 8.00422C0.75 3.99998 3.99608 0.753906 8.00032 0.753906C12.0046 0.753906 15.2506 3.99998 15.2506 8.00422Z",
      fill: "#AFCCFA"
    },
    {
      path: "M8.00031 12.0014V7.00143M8.00031 5.50143V4.00143M15.2506 8.00422C15.2506 12.0085 12.0046 15.2545 8.00032 15.2545C3.99608 15.2545 0.75 12.0085 0.75 8.00422C0.75 3.99998 3.99608 0.753906 8.00032 0.753906C12.0046 0.753906 15.2506 3.99998 15.2506 8.00422Z",
      fill: "none",
      strokeColor: "#3574D4",
      strokeWidth: 1.5
    }
  ]
};

export const quoteSymbol = {
  id: "quoteSymbol",
  shapes: [
    {
      path: "M5.30408 4L7.77307 5.21073C6.73222 6.60026 6.19365 8.01022 6.15734 9.44061V12H2V9.70115C2 8.74074 2.31467 7.71903 2.94402 6.63602C3.58548 5.54278 4.37216 4.66411 5.30408 4ZM11.531 4L14 5.21073C12.9592 6.60026 12.4206 8.01022 12.3843 9.44061V12H8.22693V9.70115C8.22693 8.74074 8.5416 7.71903 9.17095 6.63602C9.81241 5.54278 10.5991 4.66411 11.531 4Z",
      fill: "#87B2F5"
    }
  ]
};
