import React from "react";
import { CircularBackground, Bar } from "./styled-components";

const CircularLoader = ({ size, borderColor, backgroundColor }) => (
  <CircularBackground size={size}>
    <Bar
      size={size}
      borderColor={borderColor}
      backgroundColor={backgroundColor}
    />
  </CircularBackground>
);

export default CircularLoader;
