import styled, { css } from "styled-components";

import buildColor from "../../_static/ColorPalette";

const TabGroupWrapper = styled.ul`
  list-style: none;
  display: flex;
  width: 100%;
  flex: 0 0 auto;
  overflow: auto;
  background-color: ${buildColor("white", "100")};
  box-shadow: ${(props) =>
    `inset 0 -1px 0 0 ${buildColor(
      props.borderColor,
      props.borderColorVariant
    )}`};

  & > li {
    flex: ${(props) => (props.stretch ? 1 : 0)};
    ${(props) =>
      props.type === "PILL" &&
      css`
        margin-right: 8px;

        &:first-child {
          padding-left: 12px;
        }

        &:last-child {
          margin-right: 0;
          padding-right: 12px;
        }
      `};
  }
`;

export default TabGroupWrapper;
