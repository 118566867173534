import React from "react";
import { Paragraph } from "@tvg/design-system";
import { HeaderContainer } from "./styled-components";

export interface Props {
  addExtraPadding?: boolean;
  qaLabel?: string;
}

const ReferralHeader = ({
  addExtraPadding,
  qaLabel = "referralHeader"
}: Props) => (
  <HeaderContainer data-qa-label={qaLabel} addExtraPadding={addExtraPadding}>
    <Paragraph
      qaLabel={`${qaLabel}SignUp`}
      fontFamily="condensedRegular"
      color="grey.700"
      fontSize="xs"
    >
      Sign Up
    </Paragraph>
    <Paragraph
      qaLabel={`${qaLabel}Deposit`}
      fontFamily="condensedRegular"
      color="grey.700"
      fontSize="xs"
    >
      Deposit
    </Paragraph>
    <Paragraph
      qaLabel={`${qaLabel}Bonus`}
      fontFamily="condensedRegular"
      color="grey.700"
      fontSize="xs"
    >
      Bonus
    </Paragraph>
  </HeaderContainer>
);

export default ReferralHeader;
