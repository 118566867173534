import type { SpaceProps, LayoutProps } from "styled-system";
import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";
import { FavoriteRunners } from "@tvg/ts-types/Race";
// TO DO - TO BE REMOVED
import { GestureResponderEvent } from "react-native";
import { StoryblokPlaceName } from "@tvg/sh-lib-promos-onboarding/types/promoOnboardingComponents";
import type { Dispatch, SetStateAction } from "react";
import { Breed, type SaddleSelectionsProps } from "../../../src";
import type {
  HeaderBetStatusType,
  WagerCardHeaderProps
} from "../WagerCardHeader/types";

type BetStatusType = "NONE" | "PLACED" | "ERROR" | "PROCESSING";

type BetRaceStatusType =
  | "open"
  | "raceOff"
  | "win"
  | "lost"
  | "attention"
  | "unknown";

type BetTypeCellHeaderPickTypes =
  | "onApproxPayout"
  | "isBetWinner"
  | "isCanceled"
  | "winningsAmount"
  | "shouldShowPotentialReturn"
  | "showMinMaxWillPays"
  | "minWillPays"
  | "maxWillPays"
  | "onHideShowLegsGTM"
  | "setShowContent"
  | "shouldShowHideLegButton"
  | "showContent"
  | "wagerId"
  | "wonIllustration";

enum BetStatusCodesEnum {
  ACTIVE = "A",
  REFUNDED = "R",
  LOSER = "L",
  WINNER = "W",
  CANCELED = "C"
}

interface BetTypeCellProps extends SpaceProps, LayoutProps {
  /** Bet Amount */
  betAmount: number;
  /** Bet type name */
  betTypeName: string;
  /** Bet Ticket value */
  betTicket: number;
  /** Number Wagerable Runners */
  numWagerableRunners?: string[] | undefined;
  /** Approximated Payout */
  approximatedPayout: string;
  /** Bet type */
  type: WagerTypeCodesEnum;
  /** Type of race */
  breed?: Breed;
  /** Saddle selections */
  selections: SaddleSelectionsProps[];
  /** Complete Selection Array */
  completeSelection?: SaddleSelectionsProps[][];
  /** Status of the bet */
  betStatus: BetStatusType | HeaderBetStatusType;
  /** Repeat bet handler */
  onRepeatBetHandler?: (event: GestureResponderEvent) => void;
  /** Cancel bet handler */
  onCancelBetHandler?: (event: GestureResponderEvent) => void;
  /** Custom data-qa-label attribute applied to element */
  qaLabel?: string;
  /** Is leg bet * */
  isLeg: boolean;
  /** If include scratch in selections */
  scratches?: string[][];
  /** Is Key bet */
  isKey?: boolean;
  /** Is Box bet */
  isBox?: boolean;
  /** Is Wheel bet */
  isWheel?: boolean;
  /** Current race number */
  raceNumber?: string;
  /** No header and no border */
  showOnlySelections?: boolean;
  betStatusCode?: BetStatusCodesEnum;
  racesStatus?: BetRaceStatusType[];
  winAmountBet?: number;
  /** Is the bet refund amount */
  betRefund?: number;
  /** Is the component being rendered in the myBets screen */
  isMyBets?: boolean;
  /** Is the bet repeatable */
  isRepeatable?: boolean;
  /** If is on handicaping */
  onHandicaping?: boolean;
  /** if bet is opted in */
  isOptedIn?: boolean;
  /** if footer should be shown */
  isFooterShown?: boolean;
  /** Props for my bets header */
  headerMyBetsProps?: Pick<WagerCardHeaderProps, BetTypeCellHeaderPickTypes>;
  /** Props for notifications */
  notificationProps?: WagerCardNotificationProps;
  /** Props for pick bet rebet */
  pickBetRebetProps?: PickBetRebetProps;
  /** Props for promo onboards */
  promoOnboardingProps?: PromoOnboardingProps;
  /** Props for footer */
  wagerCardFooterProps?: WagerCardFooterProps;
  /** Details controler */
  wagerDetails?: boolean[];
  onDetailsView?: (raceSelected: number) => void;
  shouldShowRefundTag?: boolean;
  enableMyBetsBehavior?: boolean;
  favoriteRunnerByLeg?: FavoriteRunners;
  showReplacedRunners?: boolean;
}

interface PromoOnboardingProps {
  placeToRender?: StoryblokPlaceName;
  customMessage?: string;
  isShown?: boolean;
}

interface CustomBetSelectionsViewProps {
  /** Complete Selection Array */
  completeSelection: SaddleSelectionsProps[][] | undefined;
  /** Number Wagerable Runners */
  numWagerableRunners?: string[] | undefined;
  /** Bet type */
  type: WagerTypeCodesEnum;
  /** Runners Scratches */
  scratches?: string[][];
  /** Type of race */
  breed?: Breed;
  /** Key Bet  */
  isKey?: boolean;
  /** Box Bet  */
  isBox?: boolean;
  /** Wheel Bet  */
  isWheel?: boolean;
  /** Leg Bet  */
  isLeg?: boolean;
  /** Exotic Bet  */
  isExoticBet?: boolean;
  /** Current race number */
  currentRaceNumber?: string;
  wagerDetails?: boolean[];
  racesStatus?: BetRaceStatusType[];
  onDetailsView?: (raceSelected: number) => void;
  enableMyBetsBehavior?: boolean;
  favoriteRunnerByLeg?: FavoriteRunners | null;
  showReplacedRunners?: boolean;
}

interface PickBetRebetProps {
  shouldShowRebet: boolean;
  isContentDisplayed: boolean;
  rebetWagerTypeName: string;
  onClickBet: () => void;
}

interface WagerCardNotificationProps {
  showScratchedNotification: boolean;
  shouldNotHideScratchNotification: boolean;
  onDismissPress: Dispatch<SetStateAction<boolean>>;
  onNotificationPress?: () => void;
  scratchedTitle: string;
  scratchedLegText: string;
  wagerId: string;
  enableMyBetsBehavior?: boolean;
}

interface WagerCardFooterProps {
  hasSocialShare?: boolean;
  hasRepeatButton?: boolean;
  hasCancelButton?: boolean;
  isDetailsOpened?: boolean;
  isBetActive?: boolean;
  onPressShareWager: () => void;
  onRepeatPress: () => void;
  onDetailsPress: () => void;
  onCancelWager: () => void;
  isXsellBlacklistedBet: boolean;
}

export type {
  SaddleSelectionsProps,
  WagerCardHeaderProps,
  WagerCardNotificationProps,
  PickBetRebetProps,
  PromoOnboardingProps,
  WagerCardFooterProps,
  CustomBetSelectionsViewProps,
  BetTypeCellProps,
  BetRaceStatusType
};
export { BetStatusCodesEnum };
