export const getUserData = (userData) => ({
  type: "USER_DATA_UPDATE",
  payload: userData
});

export const setPriorLoginStatus = (returningUser) => ({
  type: "USER_PRIOR_LOGIN_STATUS",
  payload: { returningUser }
});

export const successUserPromos = (userPromos) => ({
  type: "USER_PROMOS_SUCCESS",
  payload: { optedInPromos: userPromos }
});

export const loadingUserPromos = () => ({
  type: "USER_PROMOS_LOADING"
});

export const clearUserPromos = () => ({
  type: "USER_PROMOS_CLEAR"
});
