import styled from "styled-components";
import { margin, layout, type LayoutProps } from "styled-system";
import type { SectionHeaderSize } from "./types";

export const HeaderContainer = styled.div<{
  height: number;
  paddingX: number;
  size?: SectionHeaderSize;
}>`
  align-items: center;
  background-color: var(--fd-colors-background-surface);
  border-bottom: ${({ size }) => (size === "large" ? "1px" : "0")} solid
    var(--fd-colors-border-subtle);
  border-radius: 4px 4px 0 0;
  display: flex;
  height: ${({ height }) => height}px;
  padding: 0 ${({ paddingX }) => paddingX}px;
  box-sizing: border-box;

  ${margin}
`;

export const Loading = styled.div<LayoutProps>`
  background-color: var(--fd-colors-content-loading-default);
  ${layout}
`;
