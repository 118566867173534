import React from "react";

import ContentTitle from "../../_atoms/ContentTitle";
import IconLink from "../../_molecules/IconLink";
import {
  PromosEvergreenContainer,
  PromosEvergreenIconsContainer
} from "./styled-components";

const PromosEvergreenContent = ({ content, qaLabel = "promosEvergreen" }) => (
  <PromosEvergreenContainer data-qa-label={qaLabel}>
    <ContentTitle
      content={{ header: content.header }}
      qaLabel={`${qaLabel}HeaderTitle`}
    />
    <PromosEvergreenIconsContainer data-qa-label={`${qaLabel}Icons`}>
      {content.iconLinks.map((link) => (
        <IconLink key={link._uid} content={link} qaLabel={`${qaLabel}Icon`} />
      ))}
    </PromosEvergreenIconsContainer>
  </PromosEvergreenContainer>
);

export default PromosEvergreenContent;
