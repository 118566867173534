import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontNormal } from "../../_static/Typography";

export const PaginatorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ alignLeft }) =>
    alignLeft ? "flex-start" : "space-between"};
  ${({ alignRight }) =>
    alignRight &&
    css`
      justify-content: flex-end;
    `}
  padding: 16px 12px;
  box-shadow: 0 -1px 0 ${buildColor("blue", "100")};
  font-size: 14px;
  font-family: ${fontNormal};
  ${({ alwaysAtBottom }) =>
    alwaysAtBottom &&
    css`
    position: absolute;
    bottom 0;
    width: 100%;
  `}
`;

const Button = css`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const PrevButton = styled.div`
  ${Button}

  & > svg {
    transform: rotate(180deg);
    margin-right: 4px;
  }
`;

export const NextButton = styled.div`
  ${Button}

  & > svg {
    margin-left: 4px;
  }

  ${({ isLoadingStandingsPageError }) =>
    isLoadingStandingsPageError &&
    css`
      color: ${buildColor("grey", "500")};
      cursor: default;

      & > svg > path {
        fill: ${buildColor("grey", "500")};
      }
    `}
`;

export const Label = styled.div`
  color: ${buildColor("grey", "700")};
`;
