import styled, { css } from "styled-components";
import { space, width, compose } from "styled-system";

const buttonStyles = compose(space, width);

export const FilterCellsContainer = styled.div<{
  size: "s" | "m" | "l";
  isStretched: boolean;
}>`
  display: inline-flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: ${({ size }) => (size === "l" ? "6px" : "4px")};
  outline: 1px solid var(--fd-colors-component-selection-control-border-base);
  outline-offset: -1px;
  background: var(--fd-colors-background-surface);
  box-shadow: 0 2px 4px 0 var(--fd-shadows-elevations-bottom-low);
  ${({ isStretched }) =>
    isStretched &&
    css`
      width: 100%;
    `}
  ${buttonStyles};
`;
