export const transparent = {
  name: "Transparent",
  value: "rgba(255, 255, 255, 0)"
};

export const black = {
  name: "Black",
  value: "#000000"
};

export const blackGhost = {
  name: "Blackghost",
  value: "rgba(0, 0, 0, 0.3)"
};

export const blackGhostHover = {
  name: "BlackghostHover",
  value: "#333333"
};

export const blackGhostActive = {
  name: "BlackghostHover",
  value: "rgba(51, 51, 51, 0.5)"
};

export const blackOverlay = {
  name: "BlackOverlay",
  value: "rgba(0, 0, 0, 0.7)"
};

export const blue = {
  name: "Blue",
  value: "#27609b"
};

export const blueBright = {
  name: "Blue Bright",
  value: "#5c9de0"
};

export const blueBrighter = {
  name: "Blue Brighter",
  value: "#3E96EE"
};

export const blueBrightHover = {
  name: "Blue Bright Hover",
  value: "#68b1fb"
};

export const blueBrightActive = {
  name: "Blue Bright Active",
  value: "#558ec8"
};

export const blueDark = {
  name: "Blue Dark",
  value: "#194879"
};

export const blueDarker = {
  name: "Blue Darker",
  value: "#253e58"
};

export const blueCool = {
  name: "Blue Cool",
  value: "#528cc7"
};

export const blueDarkest = {
  name: "Blue Darkest",
  value: "#1c3247"
};

export const blueGhost = {
  name: "Blue Ghost",
  value: "#cce6ff"
};

export const blueGreyish = {
  name: "Blue Greyish",
  value: "#e6e9ef"
};

export const blueLight = {
  name: "Blue Light",
  value: "#93afcd"
};

export const blueLightGreyish = {
  name: "Blue Light Greyish",
  value: "#63778c"
};

export const blueLighter = {
  name: "Blue Lighter",
  value: "#cfdae2"
};

export const blueLighterGhost = {
  name: "Blue Lighter",
  value: "#fafbfc"
};

export const blueLightest = {
  name: "Blue Lightest",
  value: "#dfe7f0"
};

export const blueMidnight = {
  name: "Blue Midnight",
  value: "#0f1f30"
};

export const blueWhitish = {
  name: "Blue Whitish",
  value: "#f6f7fa"
};

export const darkBlueGrey = {
  name: "Dark Blue Grey",
  value: "#0f1f30"
};

export const green = {
  name: "Green",
  value: "#38ab50"
};

export const greenGhost = {
  name: "Green Ghost",
  value: "#bae8c4"
};

export const greenLighter = {
  name: "Green Lighter",
  value: "#cfedd5"
};

export const greenLightest = {
  name: "Green Lightest",
  value: "#eaf8ed"
};

export const greenHover = {
  name: "Green Hover",
  value: "#44c55f"
};

export const greenActive = {
  name: "Green Active",
  value: "#339348"
};

export const grey = {
  name: "Grey",
  value: "#666666"
};

export const greyDark = {
  name: "Grey Dark",
  value: "#333333"
};

export const greyMedium = {
  name: "Grey Medium",
  value: "#999999"
};

export const greyLight = {
  name: "Grey Light",
  value: "#cccccc"
};

export const greyLighter = {
  name: "Grey Lighter",
  value: "#dddddd"
};

export const greyLightest = {
  name: "Grey Lightest",
  value: "#f6f6f6"
};

export const red = {
  name: "Red",
  value: "#de3232"
};

export const redGhost = {
  name: "Red Ghost",
  value: "#fedfdf"
};

export const redHover = {
  name: "Red Hover",
  value: "#f73942"
};

export const redActive = {
  name: "Red Active",
  value: "#c42b23"
};

export const white = {
  name: "White",
  value: "#ffffff"
};

export const whiteActive = {
  name: "White Active",
  value: "#e7eaf0"
};

export const whiteGhost = {
  name: "White Ghost",
  value: "rgba(255, 255, 255, 0.2)"
};

export const whiteGhostless = {
  name: "White Ghostless",
  value: "rgba(255, 255, 255, 0.7)"
};

export const whiteGhostHover = {
  name: "White Ghost Hover",
  value: "rgba(255, 255, 255, 0.4)"
};

export const whiteGhostActive = {
  name: "White Ghost Active",
  value: "rgba(255, 255, 255, 0.1)"
};

export const whiteGhostActiveAlt = {
  name: "White Ghost Active",
  value: "rgba(255, 255, 255, 0.05)"
};

export const greyGhost = {
  name: "Grey Ghost Active",
  value: "rgba(51, 51, 51, 0.75)"
};

export const yellow = {
  name: "Yellow",
  value: "#ffb80c"
};

export const yellowGhost = {
  name: "Yellow Ghost",
  value: "#ffecbf"
};

export const yellowHover = {
  name: "Yellow Hover",
  value: "#ffc743"
};

export const yellowActive = {
  name: "Yellow Active",
  value: "#e7a60e"
};

export const yellowFaded = {
  name: "Yellow Faded",
  value: "#ffe39e"
};
