import { noop } from "lodash";

import useFakeInput from "./useFakeInput";

export const toggleBodyClass = (
  show,
  bodyPositionY,
  setBodyPositionY,
  device
) => {
  const isNotDesktop = /mobile|tablet/.test(device);
  if (typeof window !== "undefined" && document.body) {
    if (show) {
      setBodyPositionY(window.pageYOffset);
      document.body.style.top = `-${window.pageYOffset}px`;
    } else {
      window.scrollTo(0, bodyPositionY);
      document.body.style.top = "";
    }
    return isNotDesktop && document.body.classList.toggle("modal", show);
  }

  return undefined;
};

export const closeModalWrapper = (
  callback = noop,
  {
    useFakeInput: propsUseFakeInput,
    device,
    isLastModal,
    setIsOpen,
    bodyPositionY,
    setBodyPositionY
  }
) => {
  if (propsUseFakeInput) {
    useFakeInput();
  }

  window.focus();
  setIsOpen(false);
  callback();

  if (isLastModal) {
    toggleBodyClass(false, bodyPositionY, setBodyPositionY, device);
  }
};
