import React, { PureComponent } from "react";

import HeaderText from "./styled-components";

class ContentTitle extends PureComponent {
  static defaultProps = {
    content: {
      qaLabel: "",
      className: undefined,
      fontSize: undefined,
      lineHeight: undefined
    },
    qaLabel: "contentTitle"
  };

  render() {
    const { content, qaLabel } = this.props;

    return (
      <HeaderText
        className={content.className}
        data-qa-label={content.qaLabel || qaLabel}
        fontSize={content.fontSize}
        lineHeight={content.lineHeight}
      >
        {content.header}
      </HeaderText>
    );
  }
}

export default ContentTitle;
