import styled from "styled-components/native";
import { TouchableHighlight, Text, View } from "react-native";
import { StrechedProps, StyledProps, DisabledProps } from "./types";

export const StyledPressable = styled(TouchableHighlight)<
  StyledProps & StrechedProps & DisabledProps
>`
  height: 48px;
  background-color: ${({ theme }) => theme.colors.white[900]};
  border-bottom-color: ${({ theme }) => theme.colors.blue[100]};
  border-bottom-width: ${({ noBorder }) => (noBorder ? "0" : "1px")};
  padding-left: ${({ theme }) => theme.space["space-4"]};
  padding-right: ${({ theme }) => theme.space["space-4"]};
  justify-content: center;
  align-items: center;
  ${({ isStretched }) =>
    isStretched &&
    `
    flex-grow: 1;
  `}

  ${({ isHovered, isDisabled, theme, hoverColor }) =>
    isHovered &&
    `background-color: ${hoverColor || theme.colors.blue_accent["000"]};
    cursor: ${isDisabled ? "default" : "pointer"}
  `}
  
  ${({ isPressed, theme, hasPressedColor }) =>
    isPressed &&
    hasPressedColor &&
    `
    background-color: ${theme.colors.blue_accent[100]};
  `}
  
  ${({ isDisabled, theme }) =>
    isDisabled &&
    `
    background-color: ${theme.colors.white[500]};
  `};
`;

export const StyledTextTitle = styled(Text)<StyledProps & DisabledProps>`
  color: ${({ theme, textTitleColor }) =>
    textTitleColor || theme.colors.grey[900]};
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-family: ${({ theme }) => theme.fonts.medium};
  ${({ isSelected, theme, textTitleSelectedColor }) =>
    isSelected &&
    `
    color: ${textTitleSelectedColor || theme.colors.blue_accent[500]}
  `}

  ${({ isPressed, theme, hasPressedColor }) =>
    isPressed &&
    hasPressedColor &&
    `
    color: ${theme.colors.blue_accent[500]}
  `}

  ${({ isDisabled, theme }) =>
    isDisabled &&
    `
    color: ${theme.colors.grey[600]};
  `}

  ${({ isTitleUppercase }) => isTitleUppercase && `text-transform: uppercase;`}
`;

export const StyledViewIconContainer = styled(View)`
  margin-left: ${({ theme }) => theme.space["space-1"]};
`;

export const StyledTextDescription = styled(Text)<StyledProps & DisabledProps>`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-family: ${({ theme }) => theme.fonts.regular};
  color: ${({ theme }) => theme.colors.grey[800]};
  margin-top: 2px;
  ${({ isSelected, theme }) =>
    isSelected &&
    `
    color: ${theme.colors.blue_accent[400]}
  `}

  ${({ isPressed, theme }) =>
    isPressed &&
    `
    color: ${theme.colors.blue_accent[400]}
  `}
  
  ${({ isDisabled, theme }) =>
    isDisabled &&
    `
    color: ${theme.colors.grey[500]};
  `}
`;

export const StyledViewTitleContainer = styled(View)`
  flex-direction: row;
  margin-bottom: 2px;
`;
