import React from "react";
import { get } from "lodash";

import BannerContent from "../../_molecules/BannerContent";
import ShareLinks from "../ShareLinks";
import {
  BannerContainer,
  BannerImageWrapper,
  BannerImage,
  BannerMobileImage,
  BannerDivider,
  BannerContentWrapper,
  BannerTopGradient
} from "./styled-components";

const Banner = ({ content, qaLabel }) => {
  const {
    config,
    pictureUrl,
    pictureAlt,
    mobilePictureUrl,
    links,
    isLogged = false,
    userEmail = "",
    referCode = "",
    referPath = "",
    isEligible = true,
    promoId = 0,
    isOpted = false,
    isBadgeImg = false,
    isRnTablet = false
  } = content;

  const isEditorialBanner = get(config, "pageSelected", "edit") === "edit";

  const promoType = {
    ret: isOpted ? "optedIn" : "promo",
    acq: "promo",
    all: isEditorialBanner ? "editorial" : "promo"
  };

  const renderLinks = () => {
    if (config.displaySocialLinks && links && links.length > 0) {
      return (
        <ShareLinks
          links={links}
          userEmail={userEmail}
          isLogged={isLogged}
          referCode={referCode}
          referPath={referPath}
          isEligible={isEligible}
          promoId={promoId}
        />
      );
    }

    return null;
  };

  return (
    <BannerContainer
      data-qa-label={qaLabel}
      backgroundColor={content.backgroundColor}
      promoTypeStyling={get(promoType, config.segment, "promo")}
      isEditorialBanner={isEditorialBanner}
      isRnTablet={isRnTablet}
    >
      <BannerTopGradient data-qa-label={`${qaLabel}TopGrandient`} />
      <BannerImageWrapper
        data-qa-label={`${qaLabel}ImageWrapper`}
        promoTypeStyling={get(promoType, config.segment, "promo")}
        isBadgeImg={isBadgeImg}
      >
        <BannerImage
          data-qa-label={`${qaLabel}Image`}
          src={pictureUrl}
          alt={pictureAlt}
          isBadgeImg={isBadgeImg}
        />
        <BannerMobileImage
          data-qa-label={`${qaLabel}MobileImage`}
          src={mobilePictureUrl}
          alt={pictureAlt}
          isBadgeImg={isBadgeImg}
        />
      </BannerImageWrapper>
      <BannerDivider data-qa-label={`${qaLabel}Divider`} />
      <BannerContentWrapper data-qa-label={`${qaLabel}ContentWrapper`}>
        <BannerContent {...content} qaLabel={`${qaLabel}Content`} />
        {renderLinks()}
      </BannerContentWrapper>
    </BannerContainer>
  );
};

Banner.defaultProps = {
  content: {
    config: {
      path: {
        id: "",
        url: "",
        cached_url: "",
        linktype: "url"
      },
      plugin: "banner_plugin",
      segment: "all",
      ctaLabel: "",
      forOptin: false,
      promoId: "",
      promoCode: "",
      displayCTA: false,
      linkLabel: "",
      enablePromoCode: false,
      requiresAuth: false,
      displaySocialLinks: false
    },
    categoryTitle: "",
    categoryTitleColor: "",
    title: "",
    titleColor: "",
    description: "",
    descriptionColor: "",
    secondDescription: "",
    component: "banner",
    pictureAlt: "",
    pictureUrl: "",
    mobilePictureUrl: "",
    backgroundColor: "",
    isMobilePersistent: false,
    links: [],
    isLogged: false,
    isOpted: false,
    isEligible: true,
    referCode: "",
    referPath: "",
    isOptinError: false,
    isServiceError: false,
    optinTypeError: "",
    userEamil: "",
    promoId: 0,
    ismobile: false,
    isRnTablet: false
  },
  qaLabel: "banner"
};

export default Banner;
