import React from "react";
import { get } from "lodash";
import formatCurrency from "@tvg/formatter/currency";
import { formatDateToMMDDYYYY } from "@tvg/formatter/dates";
import { replaceCAPIVariables } from "@tvg/utils/capiUtils";
import {
  DialogMessage,
  IconWithBackground,
  IconNames
} from "@tvg/design-system";
import { ReferralStatus } from "@tvg/ts-types/ReferFriend";
import ReferCounter from "../../_molecules/ReferCounter";
import ReferralsHeader from "../../_molecules/ReferralsHeader";
import RefereeRowMask from "../../_static/Masks/refereeRow";
import RefereeRow from "../RefereeRow";
import {
  ReferralCenterContainer,
  ContentWrapper,
  DescriptionText,
  ReferrerInfo,
  ReferralsTableWrapper,
  ReferralsTable,
  CountersWrapper,
  HeaderText
} from "./styled-components";

interface Content {
  title: string;
  startDate: string;
  maxInvites: number;
  totalCompletedReferrals: number;
  totalBonusAmount: number;
  isLoadingReferrals: boolean;
  enableOverlayReferralStatus: boolean;
  referralsEmptyTitle: string;
  referralsEmptyDescription: string;
  referralCenterOpening: string;
  referrals: ReferralStatus[];
}

interface Props {
  content: Content;
  qaLabel?: string;
}

const ReferralCenter = ({ content, qaLabel = "referralCenter" }: Props) => (
  <ReferralCenterContainer data-qa-label={qaLabel}>
    <HeaderText
      tag="h3"
      fontFamily="bold"
      qaLabel={`${qaLabel}Title`}
      paddingY={14}
      paddingX="space-4"
    >
      {content.title}
    </HeaderText>
    <ContentWrapper data-qa-label={`${qaLabel}ContentWrapper`}>
      <ReferrerInfo data-qa-label={`${qaLabel}ReferrerInfo`}>
        <CountersWrapper data-qa-label={`${qaLabel}CountersWrapper`}>
          <ReferCounter
            description="Completed Referrals"
            value={get(content, "totalCompletedReferrals", 0)}
            isLoading={get(content, "isLoadingReferrals", false)}
            qaLabel={`${qaLabel}ReferCounterCompleted`}
          />
          <ReferCounter
            description="Total Earned"
            value={formatCurrency(
              get(content, "totalBonusAmount", 0),
              "USD",
              0
            )}
            isLoading={get(content, "isLoadingReferrals", false)}
            maskWidth={117}
            isCurrency
            qaLabel={`${qaLabel}ReferCounterTotal`}
          />
        </CountersWrapper>
        <DescriptionText data-qa-label={`${qaLabel}Description`}>
          {replaceCAPIVariables(content.referralCenterOpening, {
            startDate: formatDateToMMDDYYYY(content.startDate)
          })}
        </DescriptionText>
      </ReferrerInfo>
      <ReferralsTableWrapper
        data-qa-label={`${qaLabel}ReferralsTableWrapper`}
        hasEmptyContent={
          content.referrals.length === 0 && !content.isLoadingReferrals
        }
      >
        {get(content, "isLoadingReferrals", false) ||
        get(content, "referrals.length", 0) > 0 ? (
          <ReferralsTable data-qa-label={`${qaLabel}ReferralsTable`}>
            <ReferralsHeader
              addExtraPadding={get(
                content,
                "enableOverlayReferralStatus",
                false
              )}
              qaLabel={`${qaLabel}ReferralsHeader`}
            />
            {get(content, "isLoadingReferrals", false) && (
              <RefereeRowMask
                isClickable={get(content, "enableOverlayReferralStatus", false)}
              />
            )}
            {get(content, "referrals", []).map((referral) => (
              <RefereeRow
                key={get(referral, "refereeReferralData.accountId")}
                referralStatus={referral}
                isClickable={get(content, "enableOverlayReferralStatus", false)}
                qaLabel={`${qaLabel}RefereeRow`}
              />
            ))}
          </ReferralsTable>
        ) : (
          <DialogMessage
            title={content.referralsEmptyTitle}
            description={content.referralsEmptyDescription}
            paddingX="space-4"
            paddingY="space-7"
            qaLabel={`${qaLabel}DialogMessage`}
            illustration={
              <IconWithBackground
                mainCircleBackground="blue.000"
                mainIcon={{
                  name: "referFriend" as IconNames,
                  lineColor: "grey.800",
                  backgroundColor: "blue.000"
                }}
                badgeIcon={{
                  name: "info" as IconNames,
                  lineColor: "blue_accent.600",
                  backgroundColor: "blue_accent.200"
                }}
                qaLabel={`${qaLabel}IconWithBackground`}
              />
            }
          />
        )}
      </ReferralsTableWrapper>
    </ContentWrapper>
  </ReferralCenterContainer>
);

export default ReferralCenter;
