import React, { PureComponent } from "react";
import withRouter from "@tvg/utils/withCustomRouter";
import Parser, { domToReact } from "html-react-parser";
import { v4 as generateUUID } from "uuid";
import mediator from "@tvg/mediator";

import Text from "@tvg/atomic-ui/_atom/Text";

import PromosLegalsContainer from "./styled-components";
import {
  RenderRichTextInlineComp,
  RenderRichTextParagraph
} from "../richTextEditorResolver";
import ExternalLink from "../../_atoms/ExternalLink";

class PromosLegalsContent extends PureComponent {
  static defaultProps = {
    content: {
      legalItem: "",
      promoCode: ""
    },
    qaLabel: "promos-legal-content"
  };

  handleClick = () => {
    mediator.base.dispatch({
      type: "PROMOS_ALL_CTA_CLICK",
      payload: {
        gaEventAction: "click",
        gaEventLabel: "see terms and conditions"
      }
    });
  };

  render() {
    const { content, location, qaLabel } = this.props;
    const legalContent = content.legalItem;
    let legalText = null;
    const isPromoHub =
      location.pathname === "/promos" ||
      (location.hash === "#promos" && location.search === "");

    const listOfContents = [];
    if (typeof legalContent !== "string") {
      legalContent.content.forEach((blok) => listOfContents.push(blok));
    } else {
      legalText = (
        <Text tag="span" qaLabel={`${qaLabel}LegalText`}>
          {Parser(legalContent)}
        </Text>
      );
    }

    // Options to replace nodes within the returned rich text through Parser tool
    const options = {
      replace: (domNode) => {
        if (domNode.name === "p") {
          return (
            <Text tag="span" qaLabel={`${qaLabel}LegalTextParagraph`}>
              {domToReact(domNode.children, options)}
            </Text>
          );
        }

        if (domNode.name === "a") {
          if (domNode.attribs.linktype !== "story") {
            return (
              <ExternalLink
                url={domNode.attribs.href}
                text={domNode.children[0].data}
                onClick={this.handleClick}
                qaLabel={`${qaLabel}ExternalLink`}
              />
            );
          }
        }
        return domNode;
      }
    };

    return (
      <PromosLegalsContainer
        data-qa-label={qaLabel}
        className={isPromoHub ? "legalsTopOffset" : ""}
      >
        <Text tag="div" qaLabel={`${qaLabel}TextWrapper`}>
          <Text tag="span" qaLabel={`${qaLabel}Aware`}>
            AWARE - Always Wager Responsibly - National Gambling Helpline:
            1-800-522-4700.&nbsp;
          </Text>
          {legalText}
          {listOfContents.map((blok) => {
            if (blok.type === "paragraph") {
              const actualText = RenderRichTextParagraph(blok);
              const uniqueId = generateUUID();
              return (
                <Text
                  tag="span"
                  key={uniqueId}
                  qaLabel={`${uniqueId}-${qaLabel}Description`}
                >
                  {Parser(actualText, options)}
                </Text>
              );
            }
            return RenderRichTextInlineComp(blok);
          })}
        </Text>
      </PromosLegalsContainer>
    );
  }
}

export default withRouter(PromosLegalsContent);
