import styled, { css } from "styled-components";
import styleDS from "styled-components/native";
import { fontNormal } from "@tvg/atomic-ui/_static/Typography";
import { buildColor, Header } from "@tvg/design-system";

export const ReferralCenterContainer = styled.div`
  background-color: ${buildColor("white", "900")};

  @media only screen and (min-width: 768px) {
    border-radius: 4px;
  }
`;

export const HeaderText = styleDS(Header)`
  display: block;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${buildColor("blue", "100")};
`;

export const DescriptionText = styled.p`
  padding: 12px;
  font-size: 14px;
  line-height: 17px;
  font-family: ${fontNormal};
  color: ${buildColor("grey", "800")};

  @media only screen and (min-width: 768px) {
    padding: 20px;
  }

  @media only screen and (hover: hover) and (pointer: fine) and (min-width: 1024px) {
    order: 0;
    padding: 20px 0 20px 20px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (hover: hover) and (pointer: fine) and (min-width: 1024px) {
    flex-direction: row;
  }
`;

export const ReferrerInfo = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (hover: hover) and (pointer: fine) and (min-width: 1024px) {
    flex: 1;
    justify-content: space-between;
  }
`;

export const CountersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  box-shadow: inset 0 -1px 0 ${buildColor("blue", "100")};

  & > div:last-of-type {
    text-align: right;
  }

  @media only screen and (min-width: 768px) {
    padding: 20px;
  }

  @media only screen and (hover: hover) and (pointer: fine) and (min-width: 1024px) {
    order: 1;
    box-shadow: none;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 0 20px 20px;

    & > div:last-of-type {
      text-align: left;
    }
  }
`;

export const ReferralsTableWrapper = styled.div<{
  hasEmptyContent?: boolean;
}>`
  position: relative;
  margin: 0 12px 12px;
  ${({ hasEmptyContent }) =>
    hasEmptyContent &&
    css`
      border-radius: 4px;
      border: 1px solid ${buildColor("blue", "100")};
    `};

  @media only screen and (min-width: 768px) {
    margin: 0 20px 20px;
  }

  @media only screen and (hover: hover) and (pointer: fine) and (min-width: 1024px) {
    width: 400px;
    margin: 20px;
    ${({ hasEmptyContent }) =>
      hasEmptyContent &&
      css`
        & > div {
          top: 50%;
          transform: translateY(-50%);
        }
      `};
  }
`;

export const ReferralsTable = styled.div`
  border-radius: 4px;
  border: 1px solid ${buildColor("blue", "100")};
  box-shadow: 0 1px 3px rgba(17, 43, 68, 0.12);

  & > div:last-of-type {
    box-shadow: none;
    border-radius: 0 0 4px 4px;
  }
`;
