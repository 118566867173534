export enum RegionType {
  REGULAR = "regular",
  TRACK = "track",
  STATE = "State"
}

export interface Region {
  description: string;
  id: number;
  name: string;
  type: RegionType;
}
