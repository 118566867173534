import React, { useState, useEffect, useCallback } from "react";
import { get, has, noop } from "lodash";
import { useSelector, DefaultRootState } from "react-redux";
import { useNavigate } from "@tvg/custom-hooks";
import { AxiosResponse } from "axios";
import EmailReferralTemplate from "@tvg/atomic-ui/_templates/EmailReferral";
import EmailReferralResultTemplate from "@tvg/atomic-ui/_templates/EmailReferralResult";
import { getMessages } from "@tvg/sh-lib-capi-messages/redux/selectors";
import parseCapiMessage from "@tvg/utils/capiUtils";
import promoService from "@tvg/api/pro";
import mediator from "@tvg/mediator";
import { Device } from "@tvg/ts-types/Device";
import type { UserInfo } from "@tvg/ts-types/User";
import type { RefereeEmailField } from "@tvg/ts-types/ReferFriend";
import { UnaryFn } from "@tvg/ts-types/Functional";
import { isTvg5 } from "@tvg/utils/generalUtils";
import useDynamicReferees from "./hooks/useDynamicReferees";
import {
  onBackButtonClickGtm,
  onAddAnotherEmailClickGtm,
  onEmailFieldCompletedGtm,
  onReferredByFieldCompletedGtm,
  onSuccessfullySendInvitesGtm,
  onSuccessScreenButtonClickGtm
} from "./utils/gtm";
import { Props, ResultDataModal } from "./types";

const setNativeStatusBarColor = (color: "light" | "dark") => {
  if (typeof window !== "undefined" && has(window, "handleNativeMessages")) {
    // @ts-ignore
    window.handleNativeMessages("UPDATE_STATUS_BAR_COLOR", color);
  }
};

export const EmailReferral = ({
  device,
  isNative = false,
  onNavigationBackClick = noop,
  onNavigationBackDisable = noop
}: Props) => {
  const history = useNavigate();
  const { fields, appendReferee, removeReferee, updateReferee, isValid } =
    useDynamicReferees();
  const [updatedReferredBy, setUpdatedReferredBy] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [resultData, setResultData] = useState<ResultDataModal>();
  const capiMessages = useSelector(getMessages);
  const userInfo = useSelector<DefaultRootState, UserInfo>((store) =>
    get(store, "userData.user")
  );
  const maxInvites = Number(get(capiMessages, "emailReferralMaxInvites", ""));
  const errorMessage = parseCapiMessage(capiMessages, "emailReferralError", {
    title: "Error",
    description: ""
  });
  const successMessage = parseCapiMessage(
    capiMessages,
    "emailReferralSuccess",
    {
      singular: {
        title: "",
        description: ""
      },
      plural: {
        title: "",
        description: ""
      }
    }
  );
  useEffect(() => {
    if (isNative && device !== Device.TABLET) {
      setNativeStatusBarColor("light");
    }
    if (typeof onNavigationBackDisable === "function") {
      onNavigationBackDisable(false);
    }
  }, []);
  useEffect(() => {
    if (updatedReferredBy && fields.length === 1 && fields[0].value === "") {
      updateReferee({
        ...fields[0],
        isInvalid: true,
        warningMessage: "Enter at least one email address"
      });
    }
  }, [updatedReferredBy]);
  const handleSendInvites = useCallback(() => {
    setLoading(true);
    if (typeof onNavigationBackDisable === "function") {
      onNavigationBackDisable(true);
    }
    const referees = fields.map((field) => field.value);
    const referredBy =
      updatedReferredBy ||
      `${get(userInfo, "firstName", "")} ${get(userInfo, "lastName", "")}`;
    promoService
      .postReferralEmails(userInfo.accountNumber, referees, referredBy)
      .then((response: AxiosResponse) => {
        if (response && response.status === 200) {
          setResultData({
            type: "success",
            ...(fields.length === 1
              ? successMessage.singular
              : successMessage.plural)
          });
          onSuccessfullySendInvitesGtm(fields.length);
        } else {
          setResultData({
            type: "error",
            ...errorMessage
          });
        }
      })
      .catch(() => {
        setResultData({
          type: "error",
          ...errorMessage
        });
      })
      .finally(() => {
        setLoading(false);
        if (typeof window !== "undefined" && device === Device.MOBILE) {
          window.scrollTo(0, 0);
        }
      });
  }, [fields, userInfo, updatedReferredBy]);
  const handleAddEmailClick = useCallback(
    (value?: string) => {
      appendReferee(value);
      onAddAnotherEmailClickGtm();
    },
    [appendReferee]
  ) as UnaryFn<string | void, void>;
  const handleReferredByCompletion = useCallback(() => {
    if (updatedReferredBy) {
      onReferredByFieldCompletedGtm();
    }
  }, [updatedReferredBy]);
  const handleEmailFieldCompleted = useCallback(
    (field: RefereeEmailField) => {
      const updatedField = updateReferee(field, true);
      if (!!updatedField.value && !updatedField.isInvalid) {
        onEmailFieldCompletedGtm();
      }
    },
    [fields]
  );
  const handleBackClick = () => {
    if (resultData && resultData.type === "success") {
      onSuccessScreenButtonClickGtm("Back to referrals");
    } else {
      onBackButtonClickGtm();
    }
    if (typeof onNavigationBackClick === "function") {
      onNavigationBackClick();
      // @ts-ignore
      history.push(-1);
    }
    if (isNative && device !== Device.TABLET) {
      setNativeStatusBarColor("dark");
    }
  };
  const handleHomeClick = () => {
    onSuccessScreenButtonClickGtm("Go to homepage");

    if (
      isNative &&
      typeof window !== "undefined" &&
      has(window, "handleNativeMessages")
    ) {
      window.handleNativeMessages("NAVIGATION_GO_HOME", {});
    }

    if (device === Device.DESKTOP && !isNative && !isTvg5()) {
      mediator.base.dispatch({
        type: "TVG4_NAVIGATION",
        payload: { route: "/" }
      });
    } else {
      history.push("/");
    }

    if (typeof onNavigationBackClick === "function") {
      onNavigationBackClick();
    }
  };
  return !isLoading && resultData ? (
    <EmailReferralResultTemplate
      type={resultData.type}
      title={resultData.title}
      description={resultData.description}
      isHeaderVisible={device === Device.MOBILE}
      onNavigationBackClick={handleBackClick}
      onNavigationHomeClick={handleHomeClick}
    />
  ) : (
    <EmailReferralTemplate
      maxInvites={maxInvites}
      description={get(capiMessages, "emailReferralDescription", "")}
      termsAndConditions={get(
        capiMessages,
        "emailReferralTermsAndConditions",
        ""
      )}
      updatedReferredBy={updatedReferredBy}
      defaultReferredBy={`${get(userInfo, "firstName", "")} ${get(
        userInfo,
        "lastName",
        ""
      )}`}
      refereeFields={fields}
      isSendInvitesEnable={isValid}
      isHeaderVisible={device === Device.MOBILE}
      isLoading={isLoading}
      isAddFieldEnable={fields.length < maxInvites && !isLoading}
      onNavigationBackClick={handleBackClick}
      onChangeReferredBy={setUpdatedReferredBy}
      onEmailCompletion={handleEmailFieldCompleted}
      onReferredByCompletion={handleReferredByCompletion}
      onAddEmailField={handleAddEmailClick}
      onRemoveEmailField={removeReferee as UnaryFn<number | void, void>}
      onUpdateEmailField={updateReferee}
      onSendInvitesClick={handleSendInvites}
    />
  );
};

export default EmailReferral;
