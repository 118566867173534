import { isUndefined, isDifferent } from "./utils";

const createEventHandler = (val) => ({
  subscribers: [],
  lastValue: val
});

class Mediator {
  handlers;

  constructor() {
    this.handlers = {};
  }

  subscribe(type, handler) {
    this.handlers[type] = this.handlers[type] || createEventHandler();

    this.handlers[type].subscribers = [
      ...this.handlers[type].subscribers,
      handler
    ];

    return () => this.unsubscribe(type, handler);
  }

  subscribeTimes(times, type, handler) {
    let count = 0;
    const fnTimes = (current, max, val) => {
      count += 1;

      if (count === times) {
        this.unsubscribe(type, fnTimes);
      }

      return handler(val);
    };

    this.subscribe(type, fnTimes);

    return () => this.unsubscribe(type, fnTimes);
  }

  subscribeWithPast(type, handler) {
    const unsubscribeHandler = this.subscribe(type, handler);
    const eventHandler = this.handlers[type];

    if (!isUndefined(eventHandler.lastValue)) {
      handler(eventHandler.lastValue);
    }

    return unsubscribeHandler;
  }

  unsubscribe(type, handler) {
    const evtHandler = this.handlers[type];

    if (evtHandler) {
      evtHandler.subscribers = evtHandler.subscribers.filter(
        isDifferent(handler)
      );
    }

    return this;
  }

  dispatch(type, value) {
    const handler = this.handlers[type];

    if (handler && handler.subscribers.length) {
      handler.subscribers.map((fn) => fn(value));
      this.handlers[type].lastValue = value;
    } else {
      this.handlers[type] = createEventHandler(value);
    }

    return this;
  }

  query(type) {
    const handler = this.handlers[type];
    return handler && handler.lastValue ? handler.lastValue : undefined;
  }
}

export default Mediator;
