import React from "react";

const DEFAULT_OPTIN = {};

const DEFAULT_USER = {
  isLogged: false,
  returningUser: false,
  optedInPromos: DEFAULT_OPTIN
};

const userContext = React.createContext(DEFAULT_USER);

export default userContext;
