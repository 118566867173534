import React from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fal } from "@fortawesome/pro-light-svg-icons";

import PromosIconContainer from "./styled-components";

// Font Awesome library initialization
library.add(fal);

const getIconName = (name) => name.slice(3);

const renderIcon = (name) => {
  const iconName = getIconName(name);
  return <FontAwesomeIcon icon={["fal", iconName]} size="3x" />;
};

const PromosIcon = (props) => (
  <PromosIconContainer data-qa-label={props.qaLabel}>
    {renderIcon(props.icon.icon)}
  </PromosIconContainer>
);

PromosIcon.defaultProps = {
  qaLabel: "promosIcon"
};

export default PromosIcon;
