import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import {
  fontBold,
  fontMedium,
  fontNormal,
  titleMedium
} from "../../_static/Typography";
import {
  bluePatternsGhost,
  greenGrannyAppleGhost,
  greenTimberGhost,
  greenCamouflageGhost
} from "../../_static/Colors";
import buildColor from "../../_static/ColorPalette";

const allMaskColorStates = {
  default: {
    solid: buildColor("blue", "100"),
    ghost: bluePatternsGhost.value
  },
  opted: {
    solid: buildColor("green", "100"),
    ghost: greenGrannyAppleGhost.value
  }
};

const topMaskColorStates = {
  default: {
    solid: buildColor("tint", "yellow"),
    ghost: greenCamouflageGhost.value
  },
  opted: {
    solid: buildColor("tint", "green"),
    ghost: greenTimberGhost.value
  }
};

export const SlotContainer = styled.div.attrs({})`
  text-decoration: none;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  width: 326px;
  margin: 8px 6px 34px;

  &:active [data-qa-label="promo-slot-wrapper"] {
    background-color: ${buildColor("blue_accent", "100")};
  }

  @media only screen and (max-width: 599px) {
    position: relative;
    min-width: 296px;
    width: 100%;
    height: auto;
  }

  ${(props) =>
    props.ismobile === true &&
    css`
      min-width: 296px;
      @media only screen and (min-width: 600px) and (max-width: 1023px) {
        width: calc((100% - 24px) / 2);
      }
      @media only screen and (min-width: 865px) and (max-width: 1023px) {
        max-width: 326px;
        flex: 1;
      }
    `};

  @media only screen and (min-width: 600px) and (max-width: 1023px) {
    width: calc((100% - 24px) / 2);
  }
`;

export const SlotImageWrapper = styled.div`
  position: absolute;
  height: 128px;
  top: -16px;
  left: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  z-index: 100;
  overflow: hidden;

  > img {
    height: inherit;
  }
`;

export const SlotImageBackGround = styled.div`
  position: absolute;
  top: -15px;
  height: 112px;
`;

export const SlotCopyWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 16px;
`;

export const SlotCopyContent = styled.div`
  display: block;

  @media only screen and (max-width: 599px) {
    margin-bottom: 12px;
  }
`;

export const SlotImage = styled.img`
  display: block;
`;

export const SlotWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 314px;
  border: 1px solid ${buildColor("blue", "100")};
  box-shadow: 0 1px 2px 0 ${buildColor("blue", "000")};
  border-radius: 4px;
  background-color: ${buildColor("blue_accent", "000")};
  padding: 112px 12px 0;

  @media only screen and (max-width: 599px) {
    position: relative;
    height: auto;
  }
`;

export const SlotImageGradient = styled.div`
  ${(props) =>
    props.isTopPromo === true
      ? css`
          background: linear-gradient(
            -180deg,
            ${topMaskColorStates[props.maskColor].ghost} 0,
            ${topMaskColorStates[props.maskColor].solid} 100%
          );
          z-index: 50;
        `
      : css`
          background: linear-gradient(
            -180deg,
            ${allMaskColorStates[props.maskColor].solid} 0,
            ${allMaskColorStates[props.maskColor].ghost} 100%
          );
        `};
  height: 112px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px 4px 0 0;

  @media only screen and (max-width: 599px) {
    top: 0;
  }
`;

export const SlotImageShadow = styled.div`
  height: 12px;
  width: 100%;
  position: absolute;
  top: 112px;
  left: 0;
  background: radial-gradient(
    ellipse at 50% 10%,
    ${(props) => allMaskColorStates[props.maskColor].solid} 0,
    ${(props) => allMaskColorStates[props.maskColor].ghost} 65%
  );

  @media only screen and (max-width: 599px) {
    top: 112px;
  }
`;

export const SlotDescription = styled.p`
  font-family: ${fontNormal};
  color: ${buildColor("grey", "800")};
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 16px;
  max-height: 64px;
  text-overflow: ellipsis;
  overflow: hidden;
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  /* stylelint-enable */

  @media only screen and (max-width: 599px) {
    width: 100%;
    max-height: 80px;
  }
`;

export const SlotMetaTitle = styled.h6`
  font-family: ${titleMedium};
  display: block;
  color: ${buildColor("blue", "400")};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  height: 16px;
  letter-spacing: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media only screen and (max-width: 599px) {
    width: 100%;
  }
`;

export const SlotTitle = styled.h3`
  font-family: ${fontBold};
  color: ${buildColor("blue", "700")};
  font-size: 18px;
  font-weight: bold;
  margin: 4px 0 16px;
  letter-spacing: 0;
  line-height: 21px;
  max-height: 21px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
`;

export const SlotMoreWrapper = styled.div`
  border-top: 1px solid ${buildColor("blue", "000")};
  display: flex;
  flex-direction: row;
  height: 48px;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 599px) {
    width: 100%;
  }
`;

export const SlotMore = styled.span`
  color: ${buildColor("blue_accent", "500")};
  font-size: 14px;
  font-family: ${fontMedium};
  letter-spacing: 0;
`;

export const SlotStatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .cardOptedInTag {
    margin-right: 8px;
  }
`;
