import styled from "styled-components";
import { fontMedium } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const NextRaceText = styled.span`
  font-family: ${fontMedium};
  font-size: 14px;
  color: ${buildColor("white", "100")};
`;

export const NextRaceButtonContainer = styled.div`
  @media only screen and (width <= 600px) {
    ${({ isMobilePersistent }) =>
      !isMobilePersistent &&
      `
      display: none;
    `}
  }

  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  padding: 0 16px;
  border-radius: 2px;

  a {
    text-decoration: none;
    line-height: 44px;
  }

  background-color: ${buildColor("blue_accent", "500")};

  &:hover {
    background-color: ${buildColor("blue_accent", "600")};
  }

  &:active {
    background-color: ${buildColor("blue_accent", "700")};
  }
`;
