import styled, { css } from "styled-components";

import buildColor from "../../_static/ColorPalette";
import { fontNormal } from "../../_static/Typography";

const container = css`
  width: 100%;
  height: 64px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  margin-bottom: 24px;

  @media only screen and (max-width: 599px) {
    height: 40px;
    padding: 0 12px;
  }
`;

const text = css`
  margin-left: 12px;
  font-size: 18px;
  font-family: ${fontNormal};
  font-weight: normal;

  @media only screen and (max-width: 599px) {
    font-size: 14px;
    margin-left: 8px;
  }
`;

export const OptinSuccessContainer = styled.div`
  ${container}
  background-color: ${buildColor("green", "100")};
`;

export const OptinSuccessText = styled.span`
  ${text}
  color: ${buildColor("green", "900")};
`;

export const OptinErrorContainer = styled.div`
  ${container}
  background-color: ${buildColor("red", "000")};
  pointer-events: none;
`;

export const OptinErrorText = styled.span`
  ${text}
  color: ${buildColor("red", "600")};
`;
