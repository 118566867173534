import styled from "styled-components";
import { margin } from "styled-system";
import { Icon } from "../Icon";

export const TagContainer = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
`;

export const Wrapper = styled.button`
  position: relative;
  width: fit-content;
  width: 104px;
  border: none;
  background: none;
  cursor: pointer;
  ${margin};
`;

export const Container = styled.div`
  border: 1px solid var(--fd-colors-border-default);
  background: var(--fd-colors-background-layer);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;
  position: relative;
  padding: 16px;
  box-shadow: 0 2px 4px 0 rgba(10 10 10 / 10%);

  &[data-active="true"] {
    border: 1px solid var(--fd-colors-component-selection-control-border-active);
    background: var(--fd-colors-component-selection-control-background-hover);
  }
`;

export const IconCircle = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  border-radius: 50%;
  background: var(--fd-colors-system-info-background-subtle);

  &[data-active="true"] {
    background: var(--fd-colors-core-blue-l-4);
  }
`;

export const StyledIcon = styled(Icon)``;
