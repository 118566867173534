import styled, { css } from "styled-components";
import { space, width } from "styled-system";

import type { SegmentedControlProps } from "./types";

export const sizesHeightMap = {
  s: 32,
  m: 40,
  l: 52
} as const;

const getStartOrEndPosition = (
  segmentPosition: number,
  segmentsLength: number
) => {
  if (segmentPosition === 0) {
    return " + 4px";
  }
  if (segmentPosition === segmentsLength - 1) {
    return " - 4px";
  }
  return "";
};

export const StyledComponent = styled.div<{
  size: SegmentedControlProps["size"];
  numSegments: number;
}>`
  background-color: var(
    --fd-colors-component-selection-control-background-disabled
  );
  border-radius: 68px;
  display: flex;
  flex-direction: row;
  height: ${({ size }) =>
    sizesHeightMap[size as SegmentedControlProps["size"]]}px;
  justify-content: space-evenly;
  overflow: hidden;
  padding: 3px 4px;
  position: relative;
  transition: all 0.2s ease-in-out;

  ${({ numSegments }) =>
    Array.from(Array(numSegments).keys()).map(
      (position) => css`
        button:nth-child(${position + 1})[aria-selected="true"]
          ~ .segmented-control-pill {
          width: calc(100% / ${numSegments});
          left: calc(
            100% / ${numSegments} * ${position}${getStartOrEndPosition(position, numSegments)}
          );
        }
      `
    )}

  ${space};
  ${width};
`;
