import styled from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontNormal } from "../../_static/Typography";

export const ShareLinkContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ShareLinkSpan = styled.span`
  color: ${buildColor("blue", "000")};
  font-family: ${fontNormal};
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
`;
