import styled from "styled-components";

export const Pill = styled.div`
  background-color: var(
    --fd-colors-component-selection-control-background-base
  );
  border-radius: 68px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%);
  inset: 4px;
  position: absolute;
  transition: all 0.2s ease-in-out;
`;
