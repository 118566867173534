import { format, parseISO, addDays } from "date-fns";
import { pluralize } from "@tvg/utils/generalUtils";
import formatCurrency from "@tvg/formatter/currency";
import {
  ReferralStatus,
  ReferralStatusProgress
} from "@tvg/ts-types/ReferFriend";

export const getRefereeStatus = (status: ReferralStatus) => {
  const {
    depositDone,
    paidOut,
    expired,
    daysToExpire,
    refereeReferralData: { accountCreatedDate, bonusAmount }
  } = status;
  let resp = {
    color: "grey.800",
    text: ""
  };
  if (!expired && daysToExpire) {
    let color = "grey.800";
    if (daysToExpire > 10 && daysToExpire <= 15) {
      color = "orange.500";
    } else if (daysToExpire <= 10) {
      color = "red.500";
    }
    resp = {
      color,
      text: `${pluralize(daysToExpire, "day")} left`
    };
  }
  if (depositDone) {
    if (paidOut) {
      const bonus = bonusAmount ? formatCurrency(bonusAmount, "USD", 0) : 0;
      resp = {
        color: "green.600",
        text: bonusAmount ? `Paid Out ${bonus}` : "Paid Out"
      };
    } else {
      resp = {
        color: "blue_accent.500",
        text: "Processing"
      };
    }
  }
  if (expired && accountCreatedDate) {
    resp = {
      color: "grey.600",
      text: `Expired ${format(
        addDays(parseISO(accountCreatedDate), 30),
        "MM/dd/yy"
      )}`
    };
  }
  return resp;
};

export const getRefereeCurrentStep = (status: ReferralStatus) => {
  const { depositDone, expired, paidOut } = status;
  let step;
  if (expired) {
    step = ReferralStatusProgress.EXPIRED;
  } else if (!expired && depositDone) {
    step = paidOut
      ? ReferralStatusProgress.BONUS
      : ReferralStatusProgress.DEPOSIT;
  }
  return step ?? ReferralStatusProgress.SIGNUP;
};
