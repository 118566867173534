import styled, { css } from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontBold } from "../../_static/Typography";

export const TableContentWrapper = styled.div`
  background-color: ${buildColor("white", "900")};
  padding: 12px 0;

  & > div {
    box-shadow:
      inset 0 1px 0 ${buildColor("blue", "000")},
      0 1px 0 ${buildColor("blue", "000")};
  }

  @media only screen and (width >= 768px) and (width <= 1023px) {
    padding: 16px 12px 0;

    & > div {
      margin: 0 -12px;
    }
  }

  ${({ isWidget }) =>
    isWidget &&
    css`
      border-radius: 4px;
      width: 325px;
    `}

  ${({ borderLess }) =>
    borderLess &&
    css`
      & > div {
        box-shadow: none;
      }
    `}

  ${({ paddingLess }) =>
    paddingLess &&
    css`
      padding-bottom: 0;
    `}
`;

export const TableTitle = styled.h2`
  margin: 0 0 12px;
  padding: 0 12px;
  font-size: 16px;
  line-height: 22px;
  font-family: ${fontBold};

  @media only screen and (width >= 768px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  overflow: auto;
`;

const CommonCellStyles = css`
  box-shadow: 0 1px 0 ${buildColor("blue", "000")};
  font-size: ${({ fontSize }) => (fontSize ? "fontSize" : "12px")};
  line-height: 17px;
  padding: 12px 10px;
  min-width: 100px;
  vertical-align: top;

  a {
    color: ${buildColor("blue_accent", "500")};
    text-decoration: none;
  }

  &:first-child {
    padding-left: 12px;
  }

  &:last-child {
    padding-right: 12px;
  }

  @media only screen and (width <= 767px) {
    min-width: 93px;
  }

  @media only screen and (width >= 768px) and (width <= 1023px) {
    &:first-child {
      padding-left: 24px;
    }

    &:last-child {
      padding-right: 24px;
    }
  }
`;

export const Table = styled.table`
  ${(props) =>
    !props.hasScroll &&
    css`
      width: 100%;
    `}

  & th, & td {
    ${CommonCellStyles};
  }

  & th {
    text-align: left;
  }
`;

export const Td = styled.td`
  ${({ isBold, color, align, width }) => css`
    font-weight: ${isBold ? "bold" : "normal"};
    color: ${color || "inherit"};
    text-align: ${align || "left"};
    ${width && `width: ${width};`};
  `}
`;

export const Description = styled.div`
  margin-top: -12px;
  box-shadow: none !important;

  @media only screen and (width >= 768px) and (width <= 1023px) {
    margin-top: -24px !important;
    padding-bottom: 12px;
  }
`;

export const FooterNote = styled.div`
  margin-bottom: -12px;
  box-shadow: none !important;
`;
