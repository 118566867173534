import styled from "styled-components";
import { buildColor } from "@tvg/design-system";

export const EmailReferralContainer = styled.div``;

export const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: ${buildColor("blue", "700")};
  display: flex;
  align-items: center;
  padding: 4px 0;

  & > h1 {
    flex: 1;
    text-align: center;
    margin-right: 48px;
  }
`;

export const ContentWrapper = styled.div`
  background-color: ${buildColor("white", "900")};
  padding: 12px 12px 32px;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media only screen and (min-width: 768px) {
    padding: 12px 20px 20px;
  }
`;
