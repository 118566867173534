import React from "react";

import { ToolTipContainer, ToolTipText } from "./styled-components";

const ToolTip = ({
  backgroundColor,
  borderColor,
  textColor,
  text,
  enableTransition,
  qaLabel
}) => (
  <ToolTipContainer
    backgroundColor={backgroundColor}
    borderColor={borderColor}
    enableTransition={enableTransition}
    data-qa-label={qaLabel}
  >
    <ToolTipText data-qa-label={`${qaLabel}Text`} textColor={textColor}>
      {text}
    </ToolTipText>
  </ToolTipContainer>
);

ToolTip.defaultProps = {
  backgroundColor: "",
  borderColor: "",
  textColor: "",
  text: "",
  enableTransition: false,
  qaLabel: "toolTip"
};

export default ToolTip;
