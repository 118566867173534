import React, { PureComponent } from "react";
import { noop, isFunction, get } from "lodash";

import { formatCurrency } from "@tvg/formatter";
import {
  HeaderButton,
  NavigationTitleContainer,
  StatusIcon,
  Subtitle,
  Title,
  TitleContainer,
  TitleHolder,
  WidgetContainer,
  HeaderContainer,
  HeaderChildrenContainer,
  CounterContainer,
  NormalCounter,
  DollarCounter,
  CounterDescription
} from "./styled-components";
import { arrowBack, close, success } from "../../_static/Icons/icons";

import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";

export default class ModalHeader extends PureComponent {
  static defaultProps = {
    title: null,
    subtitle: null,
    titleType: "default",
    onClose: noop,
    onBack: null,
    hasRoundedCorners: true,
    widget: null,
    isSticky: false,
    hasCloseWithoutHeader: false,
    hasIcon: true,
    hasCloseButton: true,
    hasHeaderBorder: true,
    myBetsStandaloneToggle: false,
    scrollableRef: {
      current: null
    },
    hasBiggerHeader: false,
    device: "mobile",
    shouldRenderWhiteTitle: false,
    headerTitleChildren: null,
    isTitleCenter: false
  };

  constructor(props) {
    super(props);
    this.state = {
      isAtTop: true
    };
  }

  // ipp modal header needs to have a drop shadow when scrolled down
  componentDidMount() {
    this.addEventListener(
      this.props.titleType,
      this.props.scrollableRef.current
    );
  }

  componentDidUpdate(prevProps) {
    this.removeEventListener(
      prevProps.titleType,
      prevProps.scrollableRef.current
    );
    this.addEventListener(
      this.props.titleType,
      this.props.scrollableRef.current
    );
  }

  componentWillUnmount() {
    this.removeEventListener(
      this.props.titleType,
      this.props.scrollableRef.current
    );
  }

  getArrowColor = (titleType) => {
    if (titleType === "ipp") {
      return buildColor("grey", "900");
    }

    return titleType === "defaultBold"
      ? buildColor("white", "100")
      : buildColor("blue", "500");
  };

  setIsAtTop = () => {
    if (get(this.props, "scrollableRef.current.scrollTop") === 0) {
      this.setState({
        isAtTop: true
      });
    } else {
      this.setState({
        isAtTop: false
      });
    }
  };

  addEventListener = (titleType, element) => {
    if (titleType === "ipp" && element) {
      element.addEventListener("scroll", this.setIsAtTop, {
        capture: true
      });
    }
  };

  removeEventListener = (titleType, element) => {
    if (titleType === "ipp" && element) {
      element.removeEventListener("scroll", this.setIsAtTop);
    }
  };

  render() {
    let ModalTitle;
    switch (this.props.titleType) {
      case "success":
        ModalTitle = (
          <Title
            success
            myBetsStandaloneToggle={this.props.myBetsStandaloneToggle}
            hasSubtitle={!!this.props.subtitle}
          >
            {this.props.hasIcon && (
              <StatusIcon
                icon={success}
                color={buildColor("green", "600")}
                size={16}
              />
            )}
            {this.props.title}
          </Title>
        );
        break;
      case "error":
        ModalTitle = (
          <Title
            error
            myBetsStandaloneToggle={this.props.myBetsStandaloneToggle}
            hasSubtitle={!!this.props.subtitle}
          >
            {this.props.title}
          </Title>
        );
        break;
      case "warning":
        ModalTitle = (
          <Title
            warning
            myBetsStandaloneToggle={this.props.myBetsStandaloneToggle}
            hasSubtitle={!!this.props.subtitle}
          >
            {this.props.title}
          </Title>
        );
        break;
      case "navigation":
        ModalTitle = (
          <Title
            isNavigation
            myBetsStandaloneToggle={this.props.myBetsStandaloneToggle}
            hasSubtitle={!!this.props.subtitle}
          >
            {this.props.title}
          </Title>
        );
        break;
      case "subtitle":
        ModalTitle = (
          <Title
            subtitle
            myBetsStandaloneToggle={this.props.myBetsStandaloneToggle}
            hasSubtitle={!!this.props.subtitle}
          >
            {this.props.title}
          </Title>
        );
        break;
      case "ipp":
        ModalTitle = (
          <Title
            ipp
            myBetsStandaloneToggle={this.props.myBetsStandaloneToggle}
            hasBiggerHeader={this.props.hasBiggerHeader}
            hasSubtitle={!!this.props.subtitle}
            isTitleCenter={this.props.isTitleCenter}
          >
            {this.props.title}
          </Title>
        );
        break;
      case "info":
        ModalTitle = (
          <Title
            info
            hasSubtitle={!!this.props.subtitle}
            isTitleCenter={this.props.isTitleCenter}
          >
            {this.props.title}
          </Title>
        );
        break;
      default:
        ModalTitle = (
          <Title
            myBetsStandaloneToggle={this.props.myBetsStandaloneToggle}
            hasSubtitle={!!this.props.subtitle}
            shouldRenderWhiteTitle={this.props.shouldRenderWhiteTitle}
          >
            {typeof this.props.title === "string" &&
            this.props.title.includes("RACE SCHEDULE")
              ? this.props.title.replace("RACE SCHEDULE", "Race Schedule")
              : this.props.title}
          </Title>
        );
    }

    if (this.props.subtitle) {
      ModalTitle = (
        <TitleHolder>
          {ModalTitle}
          <Subtitle>{this.props.subtitle}</Subtitle>
        </TitleHolder>
      );
    }

    const hasBack =
      /default|defaultBold|ipp/.test(this.props.titleType) &&
      isFunction(this.props.onBack);

    return this.props.titleType === "navigation" ? (
      <TitleContainer
        data-qa-label="modal-header"
        titleType={this.props.titleType}
        isNavigation={this.props.titleType === "navigation"}
        roundedCorners={this.props.hasRoundedCorners}
        isSticky={this.props.isSticky}
        hasHeaderBorder={this.props.hasHeaderBorder}
        myBetsStandaloneToggle={this.props.myBetsStandaloneToggle}
        hasBiggerHeader={this.props.hasBiggerHeader}
        device={this.props.device}
        isTitleCenter={this.props.isTitleCenter}
      >
        <NavigationTitleContainer>
          <HeaderButton qaLabel="modal-closeBtn" onClick={this.props.onClose}>
            <Icon
              icon={arrowBack}
              color={buildColor("white", "100")}
              size={16}
            />
          </HeaderButton>
        </NavigationTitleContainer>
        <WidgetContainer>
          {ModalTitle}
          {this.props.widget}
        </WidgetContainer>
      </TitleContainer>
    ) : (
      <HeaderContainer
        showShadow={this.props.titleType === "ipp" && !this.state.isAtTop}
      >
        <TitleContainer
          data-qa-label="modal-header"
          titleType={this.props.titleType}
          roundedCorners={this.props.hasRoundedCorners}
          hasBack={hasBack}
          hasHeaderBorder={this.props.hasHeaderBorder}
          myBetsStandaloneToggle={this.props.myBetsStandaloneToggle}
          hasBiggerHeader={this.props.hasBiggerHeader}
          device={this.props.device}
          isTitleCenter={this.props.isTitleCenter}
        >
          {hasBack && (
            <HeaderButton qaLabel="modal-backBtn" onClick={this.props.onBack}>
              <Icon
                icon={arrowBack}
                color={this.getArrowColor(this.props.titleType)}
                size={16}
              />
            </HeaderButton>
          )}
          {ModalTitle}
          {this.props.headerTitleChildren && this.props.headerTitleChildren}
          {this.props.hasCloseButton && (
            <HeaderButton
              qaLabel="modal-closeBtn"
              onClick={this.props.onClose}
              hasBiggerHeader={this.props.hasBiggerHeader}
              hasMarginRight={this.props.device !== "mobile"}
              shouldRenderWhiteTitle={this.props.shouldRenderWhiteTitle}
              isTitleCenter={this.props.isTitleCenter}
            >
              <Icon
                icon={close}
                color={
                  this.props.titleType === "defaultBold"
                    ? buildColor("white", "100")
                    : buildColor("grey", "900")
                }
                size={18}
                qaLabel="modal-closeBtn-Icon"
              />
            </HeaderButton>
          )}
        </TitleContainer>
        {this.props.headerChildren && (
          <HeaderChildrenContainer>
            {this.props.headerChildren}
          </HeaderChildrenContainer>
        )}
      </HeaderContainer>
    );
  }
}
