import mediator from "@tvg/mediator";
import { NullaryFn } from "@tvg/ts-types/Functional";

export enum AWPath {
  Account = "/account",
  Terms = "/terms",
  Settings = "/account/settings/webview",
  Transactions = "/account/transactions",
  TaxInformation = "/account/tax-information",
  Deposit = "/account/deposit",
  Withdrawals = "/withdrawals",
  ResponsiblePlay = "/responsible-play",
  Verification = "/verification",
  AcceptTerms = "/accept-terms",
  ActivityStatement = "/account/activity-statement",
  MySpend = "/my-spend",
  TaxCenter = "/account/tax-center"
}

const AW_PAGE_TITLE: Record<AWPath, string> = {
  [AWPath.Account]: "Account",
  [AWPath.Terms]: "Terms and Conditions",
  [AWPath.Settings]: "Account Settings",
  [AWPath.Transactions]: "Transaction History",
  [AWPath.TaxInformation]: "Tax Information",
  [AWPath.TaxCenter]: "Tax Center",
  [AWPath.Deposit]: "Add Funds",
  [AWPath.Withdrawals]: "Withdraw",
  [AWPath.ResponsiblePlay]: "Responsible Gaming",
  [AWPath.Verification]: "Verification",
  [AWPath.AcceptTerms]: "Accept Terms",
  [AWPath.ActivityStatement]: "Activity Statement",
  [AWPath.MySpend]: "My Spend"
};

export const awPathAction: Record<AWPath, string> = {
  [AWPath.Account]: "TRIGGER_ACCOUNT",
  [AWPath.Terms]: "TRIGGER_TERMS",
  [AWPath.Settings]: "TRIGGER_VERIFICATION",
  [AWPath.Transactions]: "TRIGGER_TRANSACTIONS_HISTORY",
  [AWPath.TaxInformation]: "TRIGGER_TAX_INFO",
  [AWPath.Deposit]: "TRIGGER_DEPOSIT",
  [AWPath.Withdrawals]: "TRIGGER_WITHDRAWAL",
  [AWPath.ResponsiblePlay]: "TRIGGER_RESPONSIBLE_PLAY",
  [AWPath.Verification]: "TRIGGER_VERIFICATION",
  [AWPath.AcceptTerms]: "TRIGGER_ACCEPT_TERMS",
  [AWPath.ActivityStatement]: "TRIGGER_ACTIVITY_STATEMENT",
  [AWPath.MySpend]: "TRIGGER_MY_SPEND",
  [AWPath.TaxCenter]: "TRIGGER_TAX_CENTER"
};

export const AWPathsMap: Record<string, AWPath> = {
  "responsible-gaming": AWPath.ResponsiblePlay,
  "my-profile": AWPath.Settings,
  "my-account-summary": AWPath.Transactions,
  "tax-information": AWPath.TaxInformation,
  "account-summary": AWPath.Transactions,
  "activity-statement": AWPath.ActivityStatement,
  "my-spend": AWPath.MySpend,
  "account-balance": AWPath.Account,
  "tax-center": AWPath.TaxCenter
};

export const getAWPageTitle = (path: AWPath = AWPath.Account) =>
  AW_PAGE_TITLE[path] || AW_PAGE_TITLE[AWPath.Account];

export const openAWPage = (
  path: AWPath,
  options?: {
    onOpenAWPage?: NullaryFn<void>;
    forceWeb?: boolean;
  }
) => {
  mediator.base.dispatch({
    type: "OPEN_AW_PAGE",
    payload: {
      path,
      forceWeb: options?.forceWeb
    }
  });
  options?.onOpenAWPage?.();
};

export default getAWPageTitle;
