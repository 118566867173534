import { useState, useEffect } from "react";
import { has, isEmpty } from "lodash";

const useEligibilityCheck = (props) => {
  const { isLogged, eligiblePromos, promoId } = props;
  const promos = JSON.stringify(eligiblePromos);
  const [isEligible, setEligibility] = useState(true);

  useEffect(() => {
    const isUserEligible = has(eligiblePromos, `${promoId.toString()}`);

    if ((!isUserEligible || !isLogged) && !isEmpty(eligiblePromos)) {
      setEligibility(false);
    }

    if (isUserEligible && isLogged) {
      setEligibility(true);
    }
  }, [promos]);

  return { isEligible };
};

export default useEligibilityCheck;
