import styled from "styled-components";
import { fontMedium, fontNormal } from "@tvg/atomic-ui/_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const StepContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (min-width: 800px) and (max-width: 1023px) {
    width: 236px;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const StepIcon = styled.div`
  display: flex;
  flex-direction: column;
  height: 19px;
`;

export const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 12px;
`;

export const StepTitle = styled.span`
  font-family: ${fontMedium};
  font-size: 14px;
  line-height: 19px;
  text-align: left;
  color: ${buildColor("grey", "900")};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 4px;
  max-width: 205px;
`;

export const StepDescription = styled.span`
  font-family: ${fontNormal};
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: ${buildColor("grey", "800")};
  overflow: hidden;
  text-overflow: ellipsis;

  @media only screen and (min-width: 1024px) {
    max-width: 267px;
  }

  @media only screen and (min-width: 800px) and (max-width: 1023px) {
    max-width: 208px;
  }

  @media only screen and (max-width: 767px) {
    max-width: 323px;
  }
`;
