import { EnvironmentConfig, EventType, Event } from "../types";

const hybridConfig: EnvironmentConfig = {
  [EventType.Trigger]: {
    [Event.Login]: {
      eventName: "TRIGGER_LOGIN"
    },
    [Event.Join]: {
      eventName: "TRIGGER_JOIN",
      exceptionEventName: "LOGIN_CANCELLED"
    },
    [Event.Logout]: {
      eventName: "TRIGGER_LOGOUT"
    },
    [Event.OnRefreshSession]: {
      eventName: "REFRESH_SESSION"
    },
    [Event.FirstLoadComplete]: {
      eventName: "FIRST_LOAD_COMPLETE"
    },
    [Event.AcceptTerms]: {
      eventName: "TRIGGER_ACCEPT_TERMS"
    },
    [Event.Verification]: {
      eventName: "TRIGGER_VERIFICATION"
    },
    [Event.Deposit]: {
      eventName: "TRIGGER_DEPOSIT"
    }
  },
  [EventType.Subscribe]: {
    [Event.OpenAwPage]: {
      eventName: "OPEN_AW_PAGE"
    },
    [Event.RefreshSession]: {
      eventName: "REFRESH_SESSION_COMPLETED"
    },
    [Event.LoginComplete]: {
      eventName: "LOGIN_FLOW_COMPLETE",
      exceptionEventName: "LOGIN_CANCELLED"
    },
    [Event.LoginCancelled]: {
      eventName: "LOGIN_CANCELLED"
    },
    [Event.LogoutComplete]: {
      eventName: "CLEAR_SESSION"
    },
    [Event.AccountAndWalletClosed]: {
      eventName: "USER_CLOSED_MODAL"
    }
  }
};

export default hybridConfig;
