import styled from "styled-components";
import { fontBold } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const EligibleRacesContainer = styled.div`
  background-color: ${buildColor("white", "900")};

  @media only screen and (min-width: 1024px) {
    width: 325px;
    border-radius: 4px;
  }

  @media only screen and (min-width: 651px) and (max-width: 1023px) {
    padding: 12px;
  }

  @media only screen and (max-width: 414px) {
    width: 100%;
  }
`;

export const Processing = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 40px;
  padding: 20px;

  & > div:last-child {
    margin-top: 8px;
    font-size: 12px;
  }
`;

export const EligibleRacesTitle = styled.div`
  width: 100%;
  padding: 16px 12px 15px;
  border-bottom: 1px solid ${buildColor("blue", "100")};
  border-radius: 4px 4px 0 0;

  > h2 {
    font-family: ${fontBold};
    line-height: 18.75px;
    width: 100%;
    text-transform: none;
  }

  @media only screen and (min-width: 678px) and (max-width: 1023px) {
    padding: 12px 12px 12px 24px;
  }
`;

export const Races = styled.div`
  padding-left: 12px;
`;

export const NoRaces = styled.div`
  padding: 20px 12px;
  display: flex;
`;

export const NoRace = styled.div`
  padding: 16px 12px 16px 0;
  font-weight: bold;
`;

export const IconBackground = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${buildColor("grey", "100")};
  border-radius: 50%;
  position: relative;

  & > svg:last-child {
    position: absolute;
    top: 0;
    right: 0;

    & > path:first-child {
      fill: ${buildColor("blue_accent", "400")};
    }

    & > path:last-child {
      stroke: ${buildColor("white", "100")};
    }
  }

  @media only screen and (min-width: 1024px) {
    width: 50px;
  }
`;

export const WarningText = styled.div`
  margin-left: 12px;

  & > p {
    line-height: 18px;
  }

  & > p:first-child {
    margin-bottom: 4px;
  }

  & > p:last-child {
    color: ${buildColor("grey", "800")};
  }
`;

export const Race = styled.div`
  &:not(:last-child) {
    box-shadow: 0 1px 0 ${buildColor("blue", "100")};
  }

  & > * {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 12px 16px 0;
    text-decoration: none;
    color: inherit;
    font-weight: bold;

    & > div:first-child {
      flex-grow: 1;
    }

    & time {
      position: relative;
      top: -1px;
      font-size: 14px;
    }

    & time.race-date {
      top: 0;
    }

    & svg {
      margin-left: 3px;
    }
  }
`;

export const RaceNoLink = styled.div`
  height: 48px;
`;
