import styled, { css } from "styled-components";
import { compose, space, variant } from "styled-system";
import type { IconButtonProps, OverrideColorsProps } from "./types";

export const iconButtonSizes = {
  s: {
    width: "32px",
    height: "32px",
    minWidth: "32px",
    minHeight: "32px",
    padding: "6px",
    borderRadius: "16px"
  },
  m: {
    width: "44px",
    minWidth: "44px",
    height: "44px",
    minHeight: "44px",
    padding: "12px",
    borderRadius: "22px"
  },
  l: {
    width: "48px",
    height: "48px",
    minWidth: "48px",
    minHeight: "48px",
    padding: "14px",
    borderRadius: "24px"
  }
};

const buttonStyles = compose(
  space,
  variant({ prop: "size", variants: iconButtonSizes })
);

export const StyledButton = styled.button<
  Partial<IconButtonProps> & { colors: OverrideColorsProps }
>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--fd-radii-border-radius-010);
  background: ${({ colors }) => `var(${colors.default})`};
  transition: all 0.15s ease-in-out;
  ${({ hasBorder }) =>
    hasBorder &&
    `
    border: 1px solid var(--fd-colors---core-white-tint-020);
    box-shadow: 0px 1px 3px rgba(17,43,68,0.12);`}

  & svg path {
    stroke: ${({ colors }) => `var(${colors.iconColor})`};
  }

  &:hover {
    ${({ forceActive, colors }) =>
      !forceActive &&
      css`
        background: var(${colors.hover});

        & svg path {
          stroke: var(${colors.iconHoverColor});
        }
      `}
  }

  &:active {
    background: ${({ colors }) => `var(${colors.active})`};

    & svg path {
      stroke: ${({ colors }) => `var(${colors.iconActiveColor})`};
    }
  }

  ${({ forceActive, colors }) =>
    forceActive &&
    css`
      background: var(${colors.active});

      & svg path {
        stroke: var(${colors.iconActiveColor});
      }
    `}

  &:disabled {
    background: ${({ colors }) => `var(${colors.disabled})`};

    & svg path {
      stroke: ${({ colors }) => `var(${colors.iconDisabledColor})`};
    }
  }

  cursor: pointer;
  ${buttonStyles}
`;
