export const TAG_THEME_VARIANTS = {
  neutral: {
    color: "--fd-colors-system-neutral-content-accent",
    backgroundColor: "--fd-colors-system-neutral-background-subtle",
    iconName: "bets"
  },
  info: {
    color: "--fd-colors-system-info-content-accent",
    backgroundColor: "--fd-colors-system-info-background-subtle",
    iconName: "info"
  },
  positive: {
    color: "--fd-colors-system-positive-content-accent",
    backgroundColor: "--fd-colors-system-positive-background-subtle",
    iconName: "optedIn"
  },
  alert: {
    color: "--fd-colors-system-alert-content-accent",
    backgroundColor: "--fd-colors-system-alert-background-subtle",
    iconName: "promo"
  },
  warning: {
    color: "--fd-colors-system-warning-content-accent",
    backgroundColor: "--fd-colors-system-warning-background-subtle",
    iconName: "warning"
  },
  favorite: {
    color: "--fd-colors-content-on-light",
    backgroundColor: "--fd-colors-brand-tertiary-default",
    iconName: undefined
  }
} as const;

export const TAG_THEME_SIZES = {
  small: {
    fontSize: "12px",
    padding: "0 6px",
    paddingOnlyIcon: undefined,
    height: "16px "
  },
  medium: {
    fontSize: "14px",
    padding: "0 6px",
    paddingOnlyIcon: undefined,
    height: "20px"
  },
  large: {
    fontSize: "16px",
    padding: "0 6px",
    paddingOnlyIcon: "0 4px",
    height: "24px"
  }
} as const;
