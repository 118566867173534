import React, { PureComponent } from "react";
import { buildTextFn } from "./styled-components";

export const buildText = (textDataInit) => buildTextFn(textDataInit);

export default class Text extends PureComponent {
  static defaultProps = {
    tag: "span",
    children: null,
    fontSize: -1,
    bold: false,
    color: "inherit",
    align: "inherit",
    uppercase: false,
    capitalize: false,
    ellipsis: false,
    className: "",
    qaLabel: "",
    lineHeight: ""
  };

  render() {
    const Txt = buildText({
      tag: this.props.tag,
      fontSize: this.props.fontSize,
      color: this.props.color,
      bold: this.props.bold,
      align: this.props.align,
      uppercase: this.props.uppercase,
      capitalize: this.props.capitalize,
      ellipsis: this.props.ellipsis,
      qaLabel: this.props.qaLabel,
      lineHeight: this.props.lineHeight
    });

    return <Txt className={this.props.className}>{this.props.children}</Txt>;
  }
}
