import styled from "styled-components";
import { fontMedium } from "../../_static/Typography";
import buildColor from "../../_static/ColorPalette";

export const TagLabel = styled.span`
  margin-left: 4px;
  color: ${buildColor("green", "900")};
  font-family: ${fontMedium};
  font-size: 12px;
  font-weight: 500;
  height: 12px;
`;

export const TagContainer = styled.div`
  background-color: ${buildColor("green", "100")};
  height: 24px;
  min-width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
  width: max-content;
  border-radius: 12px;

  & > svg {
    padding-left: 5px;
  }

  & > ${TagLabel} {
    padding-right: 5px;
  }
`;
