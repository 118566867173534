export const initialState = {
  stories: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_REFER_STORY":
      return {
        ...state,
        stories: {
          ...state.stories,
          [action.payload.slug]: action.payload
        }
      };
    default: {
      return state;
    }
  }
};
