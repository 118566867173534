import { get, attempt, isError } from "lodash";
import mediator from "@tvg/mediator";
import tvgConf from "@tvg/conf";

const sendGTMPageView = (props, page) => {
  let registrationStatus = attempt(() =>
    localStorage.getItem("userLoginOnce") ? "Registered" : "Unregistered"
  );

  if (isError(registrationStatus)) {
    registrationStatus = "Unregistered";
  }

  let privateBrowser = attempt(() =>
    localStorage.getItem("privateMode") === "true" ? "Yes" : "No"
  );

  if (isError(privateBrowser)) {
    privateBrowser = "No";
  }

  const state =
    get(props, "homeState") === "" ? undefined : get(props, "homeState");

  mediator.base.dispatch({
    type: "RAF_PAGE_VIEW",
    payload: {
      siteVersion: tvgConf().gaConfig().siteVersion,
      productVersion: tvgConf().gaConfig().productVersion,
      accountId:
        get(props, "accountNumber") === ""
          ? undefined
          : get(props, "accountNumber"),
      logged: get(props, "isLogged", false) ? "Logged In" : "Logged Out",
      page,
      state,
      registrationStatus,
      sport: undefined,
      privateBrowser,
      graphVersion: "v2"
    }
  });
};

export default sendGTMPageView;
