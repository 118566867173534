import type { Dispatch } from "redux";
import { History } from "@tvg/ts-types/History";
import type {
  GeolocationError,
  GeolocationData,
  GeolocationStatus
} from "@tvg/sh-geolocation/src/types";

export type Environment = "dev" | "qa" | "staging" | "prod";

export interface Props {
  dispatch: Dispatch;
  geolocation: {
    state: string;
  };
  user: User;
  history: History;
  geolocationError: GeolocationError;
  geolocationStatus?: GeolocationStatus;
  allowedStates: Array<string>;
  enableRetryGeocomply?: boolean;
}

export interface User {
  userName: string;
  isLogged: boolean;
  isVerified: boolean;
  isTermsAccepted: boolean;
}

export type AuthDataXSell = {
  authToken?: string;
  loginToken?: string;
};

export type SessionDataXSell = AuthDataXSell & {
  createdDate?: number;
  serverTime?: number;
  geolocationData: GeolocationData;
};

export type SessionDataHybrid = {
  token: string;
  accountId: string;
  sessionID: string;
  message?: string;
};

export interface LoginCompleteDataType {
  error: boolean;
  payload: SessionDataHybrid | SessionDataXSell;
}

export interface EventEmitterInterface {
  dispatch: (data: unknown) => void;
  subscribe: (eventName: string, callback: unknown) => void;
}

export enum EventType {
  Trigger = "TRIGGER",
  Subscribe = "SUBSCRIBE"
}

export enum Event {
  // Trigger
  Login = "LOGIN",
  Join = "JOIN",
  Logout = "LOGOUT",
  OnRefreshSession = "ON_REFRESH_SESSION",
  AccountSummary = "ACCOUNT_SUMMARY",
  Deposit = "DEPOSIT",
  FirstLoadComplete = "FIRST_LOAD_COMPLETE",
  AcceptTerms = "ACCEPT_TERMS",
  Verification = "VERIFICATION",
  // Subscribe
  RefreshSession = "REFRESH_SESSION",
  LoginComplete = "LOGIN_COMPLETE",
  LoginCancelled = "LOGIN_CANCELLED",
  LogoutComplete = "LOGOUT_COMPLETE",
  OpenAwPage = "OPEN_AW_PAGE",
  AuthDetailsUpdated = "AUTH_DETAILS_UPDATED",
  AccountAndWalletClosed = "ACCOUNT_AND_WALLET_CLOSED"
}

export interface EventConfigType {
  eventName: string;
  exceptionEventName?: string;
}

type EventsMapped = Record<Event, EventConfigType>;

export type EnvironmentConfig = Record<EventType, Partial<EventsMapped>>;
