import React, { useState, useEffect } from "react";
import { noop } from "lodash";

import Icon from "../../_static/Icons";
import { arrowRight } from "../../_static/Icons/icons";

import {
  PaginatorWrapper,
  PrevButton,
  NextButton,
  Label
} from "./styled-components";

const Paginator = (props) => {
  const {
    showPrevButton,
    showNextButton,
    handleClick,
    firstPageLabel,
    isLoadingStandingsPageError = false,
    nextButtonEnabled = true,
    alwaysAtBottom = false,
    qaLabel = "paginator"
  } = props;

  const [page, setPage] = useState(1);

  const handlePrevClick = () => {
    setPage(page - 1);
  };

  const handleNextClick = () => {
    if (!isLoadingStandingsPageError && nextButtonEnabled) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    handleClick(page);
  }, [page]);

  return (
    <PaginatorWrapper
      alignLeft={!showNextButton && firstPageLabel}
      alignRight={!showPrevButton && !firstPageLabel}
      alwaysAtBottom={alwaysAtBottom}
      data-qa-label={qaLabel}
    >
      {showPrevButton && (
        <PrevButton
          onClick={handlePrevClick}
          data-qa-label={`${qaLabel}PrevButton`}
        >
          <Icon
            icon={arrowRight}
            size={16}
            view="0 0 16 16"
            qaLabel={`${qaLabel}PrevButtonIcon`}
          />
          Previous
        </PrevButton>
      )}
      {firstPageLabel && !showPrevButton && (
        <Label data-qa-label={`${qaLabel}FirstPageLabel`}>
          {firstPageLabel}
        </Label>
      )}
      {showNextButton && (
        <NextButton
          onClick={handleNextClick}
          isLoadingStandingsPageError={isLoadingStandingsPageError}
          data-qa-label={`${qaLabel}NextButton`}
        >
          Next
          <Icon
            icon={arrowRight}
            size={16}
            view="0 0 16 16"
            qaLabel={`${qaLabel}NextButtonIcon`}
          />
        </NextButton>
      )}
    </PaginatorWrapper>
  );
};

Paginator.defaultProps = {
  showPrevButton: false,
  showNextButton: false,
  handleClick: noop,
  qaLabel: "paginator"
};

export default Paginator;
