import React from "react";
import {
  InfoContainer,
  InfoText,
  InfoSubText,
  InfoContent
} from "./styled-components";
import Icon from "../../_static/Icons";
import { info } from "../../_static/Icons/icons";

const InfoNotification = ({
  text,
  subText,
  isBoldTitle,
  isAnimated,
  showBorders,
  className,
  addBottomMargin,
  borderTopRadius,
  qaLabel
}) => (
  <InfoContainer
    className={className}
    isAnimated={isAnimated}
    showBorders={showBorders}
    addBottomMargin={addBottomMargin}
    borderTopRadius={borderTopRadius}
    data-qa-label={qaLabel}
  >
    <Icon icon={info} size={18} view="0 0 18 18" />
    <InfoContent data-qa-label={`${qaLabel}Content`}>
      <InfoText data-qa-label={`${qaLabel}Text`} isBoldTitle={isBoldTitle}>
        {text}
      </InfoText>
      {subText && (
        <InfoSubText data-qa-label={`${qaLabel}SubText`}>{subText}</InfoSubText>
      )}
    </InfoContent>
  </InfoContainer>
);

InfoNotification.defaultProps = {
  text: "",
  subText: "",
  isBoldTitle: false,
  isAnimated: true,
  showBorders: false,
  className: "",
  addBottomMargin: false,
  borderTopRadius: false,
  qaLabel: "infoNotification"
};

export default InfoNotification;
