import React, { useContext } from "react";
import { get } from "lodash";
import Context from "@tvg-mar/promos-context";
import buildColor from "../../_static/ColorPalette";
import NextRaceButton from "../NextRaceButton";
import OptinButton from "../OptinButton";
import {
  BannerContentWrapper,
  BannerContentContainer,
  BannerButtonWrapper,
  FullTitle,
  FullDescriptionContent
} from "./styled-components";

const { PathContext } = Context;

const CallToActionButton = ({
  slug,
  config,
  isMobilePersistent = false,
  showNextRaceBtn = false,
  raceNumber = 0,
  trackCode = "",
  trackName = "",
  isOpted = false,
  qaLabel = "callToAction"
}) => {
  const {
    ctaLabel,
    forOptin,
    linkLabel,
    path,
    segment,
    promoCode,
    displayCTA,
    requiresAuth
  } = config;

  const buttonProps = {
    ctaLabel,
    forOptin,
    linkLabel,
    path,
    isForBanner: true,
    segment,
    promoCode,
    requiresAuth,
    isMobilePersistent:
      displayCTA && isMobilePersistent ? true : isMobilePersistent,
    isUpperCase: false
  };

  return (
    <BannerButtonWrapper
      promosPage={slug === "promos"}
      isMobilePersistent={isMobilePersistent}
      data-qa-label={qaLabel}
    >
      {showNextRaceBtn && isOpted ? (
        <NextRaceButton
          trackCode={trackCode}
          trackName={trackName}
          raceNumber={raceNumber}
          isMobilePersistent={isMobilePersistent}
          qaLabel={`${qaLabel}NextRaceButton`}
        />
      ) : (
        <OptinButton qaLabel={`${qaLabel}OptinButton`} content={buttonProps} />
      )}
    </BannerButtonWrapper>
  );
};

const DescriptionField = ({ description, descriptionColor, qaLabel }) => {
  const textColor =
    descriptionColor && descriptionColor !== ""
      ? descriptionColor
      : buildColor("yellow", "100");

  const renderContent = (content) => {
    return content.map((node) => {
      if (node.type === "text") {
        let textContent = node.text;

        if (node.marks) {
          node.marks.forEach((mark) => {
            if (mark.type === "bold") {
              textContent = <b>{textContent}</b>;
            }
          });
        }
        return <span>{textContent}</span>;
      }

      if (node.type === "hard_break") {
        return <br />;
      }

      return null;
    });
  };

  const renderBlock = (block, index) => {
    if (block.type === "paragraph") {
      return <p key={index}>{renderContent(block.content)}</p>;
    }

    if (block.type === "bullet_list") {
      return (
        <ul key={index} style={{ listStyleType: "disc", paddingLeft: "20px" }}>
          {block.content.map((listItem, idx) =>
            renderBlock(listItem, `${index}-${idx}`)
          )}
        </ul>
      );
    }

    if (block.type === "list_item") {
      return (
        <li key={index} style={{ marginBottom: "8px" }}>
          {block.content.map((contentBlock, idx) =>
            renderBlock(contentBlock, `${index}-${idx}`)
          )}
        </li>
      );
    }

    return null;
  };

  const formattedDescription = description?.content?.map((block, index) =>
    renderBlock(block, index)
  );

  return (
    <FullDescriptionContent color={textColor} qaLabel={qaLabel}>
      {formattedDescription}
    </FullDescriptionContent>
  );
};

DescriptionField.defaultProps = {
  descriptionColor: "",
  hasSecondDescription: false,
  qaLabel: "descriptionField"
};

const BannerContent = (props) => {
  const {
    config,
    isMobilePersistent = false,
    title,
    description,
    secondDescription,
    isOpted = false,
    isForReturningCustomers = false,
    isLogged = false,
    returningUser = false,
    isEligible = true,
    showNextRaceBtn = false,
    trackCode = "",
    trackName = "",
    raceNumber = 0,
    qaLabel = "banner"
  } = props;
  const { displayCTA } = config;
  const pathContext = useContext(PathContext);
  const hasSecondDescription = secondDescription && secondDescription !== "";
  const textSegmentColors = {
    optedStyling: {
      color: "green",
      categoryTitle: "500",
      description: "100"
    },
    promoStyling: {
      color: "yellow",
      categoryTitle: "500",
      description: "100"
    },
    editorialStyling: {
      color: "blue_accent",
      categoryTitle: "500",
      description: "000"
    }
  };

  const isEditorialBanner = get(config, "pageSelected", "edit") === "edit";

  const segmentStyling = {
    ret: isOpted
      ? textSegmentColors.optedStyling
      : textSegmentColors.promoStyling,
    acq: textSegmentColors.promoStyling,
    all: isEditorialBanner
      ? textSegmentColors.editorialStyling
      : textSegmentColors.promoStyling
  };

  const titleColor =
    props.titleColor && props.titleColor !== ""
      ? props.titleColor
      : buildColor("white", "100");

  const descriptionColor =
    props.descriptionColor && props.descriptionColor !== ""
      ? props.descriptionColor
      : buildColor(
          segmentStyling[config.segment].color,
          segmentStyling[config.segment].description
        );

  // Logic to handle the case of displaying CTA to only returning users
  const showCTA = isForReturningCustomers
    ? (isLogged || returningUser) && displayCTA
    : displayCTA;

  return (
    <BannerContentContainer
      data-qa-label={qaLabel}
      promosPage={pathContext.currentSlug === "promos"}
      isEditorial={isEditorialBanner}
    >
      <BannerContentWrapper
        hasSecondDescription={hasSecondDescription}
        isEditorial={isEditorialBanner}
        data-qa-label={`${qaLabel}Wrapper`}
      >
        <FullTitle color={titleColor} qaLabel={`${qaLabel}Title`}>
          {title}
        </FullTitle>
        {description && description !== "" && (
          <DescriptionField
            description={description}
            descriptionColor={descriptionColor}
            qaLabel={`${qaLabel}Description`}
          />
        )}
        {showCTA && isEligible && (
          <CallToActionButton
            slug={pathContext.currentSlug}
            config={config}
            isMobilePersistent={isMobilePersistent}
            hasSecondDescription={hasSecondDescription}
            showNextRaceBtn={showNextRaceBtn}
            trackCode={trackCode}
            raceNumber={raceNumber}
            trackName={trackName}
            isOpted={isOpted}
            qaLabel={`${qaLabel}CallToActionButton`}
          />
        )}
      </BannerContentWrapper>
    </BannerContentContainer>
  );
};

BannerContent.defaultProps = {
  content: {
    config: {
      path: {
        id: "",
        url: "",
        cached_url: "",
        linktype: "url"
      },
      plugin: "banner_plugin",
      segment: "all",
      ctaLabel: "",
      forOptin: false,
      promoId: "",
      promoCode: "",
      displayCTA: false,
      linkLabel: "",
      enablePromoCode: false,
      requiresAuth: false,
      displaySocialLinks: false
    },
    categoryTitle: "",
    categoryTitleColor: "",
    title: "",
    titleColor: "",
    description: "",
    descriptionColor: "",
    secondDescription: "",
    isMobilePersistent: false,
    links: [],
    ismobile: false
  },
  qaLabel: "banner"
};

export default BannerContent;
