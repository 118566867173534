import styled, { keyframes, css } from "styled-components";
import { fontNormal } from "../../_static/Typography";

const easeInOut = keyframes`
  0% { opacity: 0; }
  6.25% { opacity: 1; }
  93.75% { opacity: 1; }
  100% { opacity: 0; }
`;

export const ToolTipContainer = styled.div`
  transform: translate(-50%, 0%);
  left: 50%;
  top: -42px;
  position: absolute;
  display: inline-block;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 4px;
  padding: 7px;
  ${({ enableTransition }) =>
    enableTransition
      ? css`
          animation-timing-function: ease-in-out;
          animation: ${easeInOut} 4000ms;
          opacity: 0;
        `
      : null}
`;

export const ToolTipText = styled.span`
  font-family: ${fontNormal};
  font-size: 12px;
  line-height: 17px;
  color: ${({ textColor }) => textColor};
`;
