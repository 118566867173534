import styled from "styled-components";
import buildColor from "../ColorPalette";

export const CircularBackground = styled.div`
  position: absolute;
  display: block;
  height: ${({ size }) => (size === "small" ? `24px` : "38px")};
  width: ${({ size }) => (size === "small" ? `24px` : "38px")};
`;

export const Bar = styled.span`
  display: block;
  position: absolute;
  inset: 0;
  margin: auto;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    inset: 0;
    margin: auto;
    height: ${({ size }) => (size === "small" ? `18px` : "32px")};
    width: ${({ size }) => (size === "small" ? `18px` : "32px")};
    border-radius: 50%;
  }

  &::before {
    border: 3px solid
      ${({ backgroundColor }) => backgroundColor || buildColor("green", "800")};
    border-top: 3px solid
      ${({ borderColor }) => borderColor || buildColor("white", "100")};
    -webkit-animation: loader-2-3 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    animation: loader-2-3 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  }

  @keyframes loader-2-3 {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes loader-2-3 {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
