import axios from "axios";
import { get, has } from "lodash";
import TvgConf from "@tvg/conf";
import getProtocol from "@tvg/api/protocolSetter";

/**
 * TVG Geoloction instance
 */
export default class Geolocation {
  locationOptions;

  /**
   * Instantiates class with default configurations
   * @param config
   */
  constructor(
    config = {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge: 300000
    }
  ) {
    this.locationOptions = {
      enableHighAccuracy: config.enableHighAccuracy,
      timeout: config.timeout,
      maximumAge: config.maximumAge
    };
  }

  /**
   * Default region data
   * @type {{location: {latitude: number, longitude: number}, regions: [null]}}
   */
  static defaultRegionObject = {
    location: {
      latitude: 0,
      longitude: 0
    },
    regions: [
      {
        description: "",
        id: 0,
        name: "Default",
        type: "Default"
      }
    ],
    state: ""
  };

  /**
   * Determines if geolocation browser api is availiable
   * @returns {boolean|Geolocation}
   */
  static isGeolocationAvailable() {
    return typeof navigator === "object" && navigator.geolocation;
  }

  /**
   * Sets region data with location coordinates
   * and region obtained from GEO or default region
   * @param position
   * @param region
   * @returns {RegionData}
   */
  static setRegionData(position, region) {
    const { latitude, longitude } = get(position, "coords");
    const regionData = Geolocation.defaultRegionObject;

    regionData.location.latitude = latitude;
    regionData.location.longitude = longitude;

    regionData.regions = get(region, "regions");

    return regionData;
  }

  /**
   * Make request to GEO and resolves with region data
   * @param position
   * @returns {Promise|Promise.<RegionData>}
   */
  static requestGeoRegion(position, enableMockedLocation = false) {
    const { latitude, longitude } = position.coords;
    const requestMethod = "/regions/search/findByGpsCoordinate";
    const requestParams = `?latitude=${latitude || "null"}&longitude=${
      longitude || "null"
    }`;
    const requestUrl = `${getProtocol()}${TvgConf().config(
      "service.geo"
    )}${requestMethod}${requestParams}`;

    return axios
      .get(requestUrl, {
        withCredentials: true,
        headers: {
          "x-tvg-context": TvgConf().context(),
          "mock-successful-response": enableMockedLocation
        }
      })
      .then((response) => get(response, "data"));
  }

  /**
   * Gets geo location coordinates trough browser api
   * and resolves region data from GEO service
   * or default region with location obtained
   * @returns {Promise|Promise.<RegionData>}
   */
  requestLocation() {
    return new Promise((resolve, reject) =>
      Geolocation.isGeolocationAvailable()
        ? navigator.geolocation.getCurrentPosition(
            (position) =>
              Geolocation.requestGeoRegion(position)
                .catch(() => null)
                .then((regionData) =>
                  Geolocation.setRegionData(position, regionData)
                )
                .then(resolve),
            (error) => reject(error),
            this.locationOptions
          )
        : reject("Geolocation is not supported")
    );
  }

  /**
   * Set geo location coordinates
   * and resolves region data from GEO service
   * or default region with location obtained
   * @returns {Promise|Promise.<RegionData>}
   */
  static setLocation(latitude, longitude) {
    const position = {
      coords: {
        latitude,
        longitude
      }
    };
    return new Promise((resolve) =>
      Geolocation.requestGeoRegion(position)
        .then((regionData) => Geolocation.setRegionData(position, regionData))
        .catch(() => null)
        .then((regionData) => Geolocation.setRegionData(position, regionData))
        .then(resolve)
    );
  }

  /**
   * Make request to GEO to validate state with context
   * @param state:string
   * @returns {Promise|Promise.<boolean>}
   */
  static validateState(state) {
    const requestMethod = `/states/${state}`;
    const requestParams = "/blacklisted";
    const requestUrl = `${getProtocol()}${TvgConf().config(
      "service.geo"
    )}${requestMethod}${requestParams}`;

    return axios
      .get(requestUrl, {
        headers: { "x-tvg-context": TvgConf().context() }
      })
      .then((response) => {
        if (has(response, "data.blacklisted")) {
          return response.data.blacklisted;
        }
        throw new Error("Invalid response");
      });
  }
}
