import mediator from "@tvg/mediator";

export const initialState = {
  featureToggles: {},
  features: [],
  messages: []
};

export default function capiReducer(state = initialState, action) {
  switch (action.type) {
    case "FEATURES_GET_SUCCESS": {
      mediator.base.dispatch({ type: "FEATURE_TOGGLES_LOADED", payload: {} });
      return { ...state, featureToggles: action.payload.featureToggles };
    }
    case "FEATURES_GET_FAIL": {
      return { ...state };
    }
    case "FEATURES_CHANGE": {
      const featureToggles = {
        ...state.featureToggles,
        [action.payload.toggleName]: action.payload.newValue
      };

      return { ...state, featureToggles };
    }
    case "FEATURES_RESPONSE": {
      return { ...state, features: action.payload.features };
    }
    case "MESSAGES_GET_SUCCESS":
      return { ...state, messages: action.payload.messages };
    case "MESSAGES_GET_FAIL": {
      return { ...state };
    }
    default: {
      return state;
    }
  }
}
