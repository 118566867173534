import styled, { css } from "styled-components/native";
import { StyledButton } from "../button";

import {
  color,
  background,
  space,
  borderRadius,
  width,
  compose
} from "../../theming/styledSystem";

const styles = compose(color, background, space, borderRadius, width);

export const SortButton = styled(StyledButton)<{ justifyContent: string }>`
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent};
  ${styles};
`;

export const Container = styled.View<{ hasBorder: boolean }>`
  position: relative;
  ${({ hasBorder }) =>
    hasBorder &&
    css`
      border: 1px solid ${({ theme }) => theme.colorTokens.border.default};
      box-shadow: 0 2px 4px #112b4424;
    `}
`;
