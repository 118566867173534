import React, { PureComponent } from "react";
import { buildTitle } from "./styled-components";

export default class Title extends PureComponent {
  static defaultProps = {
    children: null,
    tag: "h1",
    ellipsis: false,
    uppercase: true,
    fontSize: -1,
    color: "inherit",
    fontFamilySecondary: false,
    className: "",
    qaLabel: ""
  };

  render() {
    let Tlt = buildTitle({
      tag: this.props.tag,
      fontSize: this.props.fontSize,
      color: this.props.color,
      uppercase: this.props.uppercase,
      ellipsis: this.props.ellipsis,
      fontFamilySecondary: this.props.fontFamilySecondary,
      qaLabel: this.props.qaLabel
    });

    if (this.props.tag !== "h1") {
      Tlt = Tlt.withComponent(this.props.tag);
    }

    return <Tlt className={this.props.className}>{this.props.children}</Tlt>;
  }
}

export { buildTitle };
