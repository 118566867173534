import styled from "styled-components";
import { titleFontSecondary } from "@tvg/atomic-ui/_static/Typography";

import buildColor from "../../_static/ColorPalette";
import { PromoIconContainer } from "../../_molecules/PromosInfoStep/styled-components";

export const PromosInfoContainer = styled.div`
  background-color: ${buildColor("white", "100")};
`;

export const PromosInfoTitleWrapper = styled.div`
  h6 {
    font-weight: 500;
    font-family: ${titleFontSecondary};
  }

  margin-bottom: 30px;
`;

export const PromosInfoStepsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: start;
  width: 100%;
  margin-bottom: 40px;
`;

export const PromosInfoAdditionalContentWrapper = styled.div`
  margin-bottom: 30px;
  font-family: ${titleFontSecondary};
  width: 80%;
`;

export const InfoStepsWrapper = styled.div`
  display: block;
  width: 100%;
  padding: 24px 0;
  background-color: ${buildColor("white", "100")};

  .promoInfoStepsTitle {
    margin-bottom: 24px;
  }
`;

export const InfoStepsContainer = styled.div`
  @media screen and (min-width: 768px) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: start;
    max-width: 850px;
    margin: 0 auto;

    > div:not(:nth-last-of-type(1)) ${PromoIconContainer}::before {
      content: "";
      width: 120px;
      position: absolute;
      top: calc(50% - 1px);
      left: 110px;
      border-top: 2px dashed rgb(233, 239, 245);
    }
  }
`;
