// use https://www.color-blindness.com/color-name-hue/ to find Hue & color name
// and use Hue & color name to name color exports
// ex: hue=>green color name=>zuccini export name will be greenZuccini

export const bluePatternsGhost = {
  name: "Blue Patterns Ghost",
  value: "rgba(214, 227, 240, 0)"
};

export const greenGrannyAppleGhost = {
  name: "Green Granny Apple Ghost",
  value: "rgba(206, 235, 212, 0)"
};

export const greenTimberGhost = {
  name: "Green Timber",
  value: "rgba(25, 69, 70, 0)"
};

export const greenCamouflageGhost = {
  name: "Green Camouflage",
  value: "rgba(118, 125, 107, 0)"
};
