import React, { createContext, useMemo, useState } from "react";
import type { TabsContextProps, TabsLayout, TabsProps } from "./types";
import {
  ContentContainer,
  StyledContainer,
  TabHeaderContainer
} from "./styled-components";

const initialState: TabsContextProps = {
  selectedValue: "",
  onChange: () => {},
  isStretched: false,
  tabsLayout: {},
  setTabsLayout: () => {},
  isFullHeight: false
};

export const TabsContext = createContext<TabsContextProps>(initialState);
TabsContext.displayName = "TabsContext";

export const Tabs = ({
  qaLabel,
  children,
  selectedValue,
  onChange,
  isFullHeight = false,
  headerPadding,
  headerWidth,
  ...rest
}: TabsProps) => {
  const content = useMemo(() => {
    const childrenElements = React.Children.toArray(children);
    return childrenElements.filter((val) => {
      if (React.isValidElement<{ value: string }>(val)) {
        return val.props.value === selectedValue;
      }
      return val;
    });
  }, [selectedValue, children]);

  const [tabsLayout, setTabsLayout] = useState<TabsLayout>({});

  let tabList;

  if (children[0]?.props.qaLabel === "tabList") {
    tabList = children[0];
  } else {
    throw new Error(
      "TabList child element is required to be used at first place"
    );
  }

  return (
    <StyledContainer
      isFullHeight={isFullHeight}
      {...rest}
      data-qa-label={qaLabel}
    >
      <TabsContext.Provider
        value={{
          selectedValue,
          onChange,
          tabsLayout,
          setTabsLayout,
          isFullHeight
        }}
      >
        <TabHeaderContainer
          headerPadding={headerPadding}
          headerWidth={headerWidth}
        >
          {tabList}
        </TabHeaderContainer>
        <ContentContainer isFullHeight={isFullHeight}>
          {content}
        </ContentContainer>
      </TabsContext.Provider>
    </StyledContainer>
  );
};

export type { TabsContextProps, TabsLayout, TabsProps };
