import styled from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontNormal } from "../../_static/Typography";

const ContentNoteText = styled.div`
  color: ${buildColor("grey", "600")};
  font-size: 12px;
  font-family: ${fontNormal};
  margin-top: 16px;
  line-height: 14px;

  p,
  span {
    color: ${buildColor("grey", "600")};
    font-size: 12px;
    font-family: ${fontNormal};
    line-height: 14px;
    margin-top: 16px;
  }
`;

export default ContentNoteText;
