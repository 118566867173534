import styled from "styled-components";
import { compose, variant } from "styled-system";

const styles = compose(
  variant({
    prop: "variant",
    variants: {
      dark: {
        backgroundColor: "var(--fd-colors-brand-secondary-shade)"
      },
      light: {
        backgroundColor: "var(--fd-colors-core-white)"
      }
    }
  })
);

export const Content = styled.div<{ variant: string }>`
  ${styles};
  border-radius: 8px;
  padding: 12px;
  margin: 4px;
  box-shadow: 0 2px 4px 0 rgba(10 10 10 / 10%);
  justify-content: center;
`;
