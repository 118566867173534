export const initialState = {
  promosList: [],
  isBeta: false,
  isEmailReferralOpen: false
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case "PROMOS_LIST_SET": {
      return { ...state, promosList: action.payload };
    }
    case "SET_BETA_OPTIN": {
      return { ...state, isBeta: action.payload };
    }
    case "SET_EMAIL_REFERRAL_OPEN": {
      return { ...state, isEmailReferralOpen: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default appReducer;
