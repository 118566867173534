import styled, { css } from "styled-components";
import { compose, space, variant } from "styled-system";
import type { FilterButtonSizes, ButtonIconSide } from "./types";

const filterCellSizes = {
  s: {
    height: "25px",
    padding: "4px 8px"
  },
  m: {
    height: "32px",
    padding: "7px 8px"
  },
  l: {
    height: "44px",
    padding: "13px 16px"
  }
};

const buttonStyles = compose(
  space,
  variant({ prop: "size", variants: filterCellSizes })
);

export const FilterCell = styled.button<{
  iconSide: ButtonIconSide;
  size: FilterButtonSizes;
  isStretched: boolean;
}>`
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  background-color: var(
    --fd-colors-component-button-transparent-background-base
  );
  border-radius: 4px;
  border: 1px solid transparent;
  box-sizing: border-box;
  flex-direction: ${({ iconSide }) =>
    iconSide === "left" ? "row" : "row-reverse"};
  ${({ isStretched }) =>
    isStretched &&
    css`
      flex-grow: 1;
    `}
  ${buttonStyles}
  transition: all 0.2s ease-in-out;
  z-index: 1;

  &:hover {
    background-color: var(
      --fd-colors-component-selection-control-background-hover
    );
    border: 1px solid var(--fd-colors-component-selection-control-border-hover);

    & svg path,
    svg rect {
      stroke: var(--fd-colors-content-default);
    }

    & p {
      color: var(--fd-colors-content-default);
    }
  }

  &[aria-selected="true"] {
    background-color: var(
      --fd-colors-component-selection-control-background-selected
    );
    border: 1px solid
      var(--fd-colors-component-selection-control-background-selected);

    & svg path,
    svg rect {
      stroke: var(--fd-colors-component-pill-primary-content-selected);
    }

    & p {
      color: var(--fd-colors-component-pill-primary-content-selected);
    }
  }

  &:disabled {
    background-color: var(
      --fd-colors-component-button-transparent-background-base
    );
    border: 1px solid
      var(--fd-colors-component-button-transparent-background-base);

    & svg path,
    svg rect {
      stroke: var(--fd-colors-content-disabled);
    }

    & p {
      color: var(--fd-colors-content-disabled);
    }
  }
`;
