import styled from "styled-components";
import { transparentize } from "polished";

import { buildText } from "../../_atom/Text";

import buildColor from "../ColorPalette";

export const Overlay = styled.div`
  position: absolute;
  inset: ${(props) => `${props.top}px`} 0 0 0;
  background-color: ${(props) =>
    props.isTransparent
      ? transparentize(0.05, buildColor("white", "100"))
      : buildColor("white", "100")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;
  ${(props) => props.rounded && "border-radius: 2px;"};
`;

export const Title = styled(
  buildText({
    tag: "span",
    fontSize: 12,
    color: buildColor("grey", "900")
  })
)`
  margin-top: 9px;
`;
