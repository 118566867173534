import styled from "styled-components";
import { fontBold, fontNormal } from "../Typography";
import buildColor from "../ColorPalette";

const PromosSeoContainer = styled.div`
  margin: 0 auto;
  padding-bottom: 32px;

  h3 {
    margin-bottom: 12px;
    font-family: ${fontBold};
    font-size: 16px;
    line-height: 20px;
    color: ${buildColor("grey", "900")};
  }

  p {
    font-family: ${fontNormal};
    color: ${buildColor("grey", "800")};
  }

  a {
    font-family: ${fontNormal};
    color: ${buildColor("blue_accent", "500")};
    text-decoration: none;
    display: inline-block;
    vertical-align: baseline;
  }

  @media only screen and (min-width: 800px) {
    max-width: 750px;
  }

  @media only screen and (min-width: 600px) and (max-width: 799px) {
    max-width: 624px;
  }

  @media only screen and (max-width: 599px) {
    padding: 0 12px 32px;
  }
`;

export default PromosSeoContainer;
