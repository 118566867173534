export enum ReferralStatusProgress {
  EXPIRED = 0,
  SIGNUP = 1,
  DEPOSIT = 2,
  BONUS = 3
}

export interface RefereeEmailField {
  id: string;
  value: string;
  warningMessage?: string;
  isInvalid?: boolean;
}

export interface RefereeReferralData {
  customerReferralId: number;
  accountId: number;
  name: string;
  accountCreatedDate: string;
  depositDate: string | null;
  paidDate: string | null;
  bonusAmount: number | null;
}

export interface ReferralStatus {
  daysToExpire: number;
  payoutInProgress: boolean;
  refereeReferralData: RefereeReferralData;
  depositDone: boolean;
  paidOut: boolean;
  expired: boolean;
}

export interface ReferralStatusResponse {
  totalCompletedReferrals: number;
  totalBonusAmount: number;
  refereeReferralStatusList: ReferralStatus[];
}

export interface ReferralEmailErrorItems {
  code: number;
  domain: string;
  message: string;
}
