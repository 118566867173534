import React, { PureComponent } from "react";

import Parser from "html-react-parser";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fal } from "@fortawesome/pro-light-svg-icons";

import {
  PromoInfoStepContainer,
  PromoIconContainer,
  PromoDetailContainer,
  PromoStepNumber,
  PromoStepDescription
} from "./styled-components";

library.add(fal);

const getIconName = (name) => name.slice(3);

export default class PromoInfoStep extends PureComponent {
  static defaultProps = {
    content: {
      id: undefined,
      iconName: undefined,
      stepNumber: undefined,
      stepTitle: undefined,
      stepDescription: undefined
    },
    qaLabel: "promoInfoStep"
  };

  renderIcon = (name) => (
    <FontAwesomeIcon icon={["fal", getIconName(name)]} size="3x" />
  );

  render() {
    const { content, qaLabel } = this.props;

    return (
      <PromoInfoStepContainer data-qa-label={qaLabel}>
        <PromoIconContainer data-qa-label={`${qaLabel}Icon`}>
          {content.iconName && this.renderIcon(content.iconName.icon)}
        </PromoIconContainer>
        <PromoDetailContainer data-qa-label={`${qaLabel}DetailContainer`}>
          <PromoStepNumber data-qa-label={`${qaLabel}StepNumber`}>
            {content.stepNumber}
          </PromoStepNumber>
          <PromoStepDescription data-qa-label={`${qaLabel}StepDescription`}>
            {Parser(content.stepDescription)}
          </PromoStepDescription>
        </PromoDetailContainer>
      </PromoInfoStepContainer>
    );
  }
}
