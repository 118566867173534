import React, { PureComponent } from "react";
import Title from "@tvg/atomic-ui/_atom/Title";
import Context from "@tvg-mar/promos-context";

import buildColor from "../../_static/ColorPalette";
import OptinButton from "../OptinButton";
import {
  PromosBannerContentWrapper,
  PromosBannerContentContainer,
  PromoBannerButtonWrapper,
  SecondDescription
} from "./styled-components";

const { UserContext, PathContext } = Context;

export default class PromosBannerContent extends PureComponent {
  static defaultProps = {
    promosBannerItem: {
      config: {
        path: {
          id: "",
          url: "",
          cached_url: "",
          linktype: "url"
        },
        plugin: "banner_plugin",
        segment: "all",
        ctaLabel: "",
        forOptin: false,
        promoId: "",
        promoCode: "",
        displayCTA: false,
        linkLabel: "",
        enablePromoCode: false,
        requiresAuth: false
      },
      categoryTitle: "",
      categoryTitleColor: "",
      title: "",
      titleColor: "",
      description: "",
      descriptionColor: "",
      secondDescription: "",
      isMobilePersistent: false
    },
    qaLabel: "promosBannerContent"
  };

  static contextType = UserContext;

  renderButton = (slug) => {
    const { promosBannerItem, qaLabel } = this.props;
    const { isMobilePersistent = false, config } = promosBannerItem;
    const {
      ctaLabel,
      forOptin,
      linkLabel,
      path,
      segment,
      promoCode,
      displayCTA,
      requiresAuth
    } = config;

    const buttonProps = {
      ctaLabel,
      forOptin,
      linkLabel,
      path,
      isForBanner: true,
      segment,
      promoCode,
      requiresAuth,
      isUpperCase: true,
      isMobilePersistent:
        displayCTA && isMobilePersistent ? true : isMobilePersistent
    };

    return (
      <PromoBannerButtonWrapper
        promosPage={slug === "promos"}
        data-qa-label={`${qaLabel}ButtonWrapper`}
      >
        <OptinButton content={buttonProps} qaLabel={`${qaLabel}OptinButton`} />
      </PromoBannerButtonWrapper>
    );
  };

  render() {
    const { promosBannerItem, qaLabel } = this.props;
    const { displayCTA } = promosBannerItem.config;
    const hasSecondDescription =
      promosBannerItem.secondDescription &&
      promosBannerItem.secondDescription !== "";
    const categoryTitleColor =
      promosBannerItem.categoryTitleColor &&
      promosBannerItem.categoryTitleColor !== ""
        ? promosBannerItem.categoryTitleColor
        : buildColor("yellow", "500");
    const descriptionColor =
      promosBannerItem.descriptionColor &&
      promosBannerItem.descriptionColor !== ""
        ? promosBannerItem.descriptionColor
        : buildColor("yellow", "000");
    const titleColor =
      promosBannerItem.titleColor && promosBannerItem.titleColor !== ""
        ? promosBannerItem.titleColor
        : buildColor("white", "100");

    return (
      <PathContext.Consumer>
        {(path) => (
          <PromosBannerContentContainer
            data-qa-label={qaLabel}
            promosPage={path.currentSlug === "promos"}
          >
            <PromosBannerContentWrapper
              hasSecondDescription={hasSecondDescription}
              data-qa-label={`${qaLabel}Wrapper`}
            >
              <Title
                tag="h3"
                fontSize={16}
                color={categoryTitleColor}
                uppercase
                qaLabel={`${qaLabel}CategoryTitle`}
              >
                {promosBannerItem.categoryTitle}
              </Title>
              <Title
                tag="h2"
                fontSize={40}
                color={titleColor}
                qaLabel={`${qaLabel}Title`}
              >
                {promosBannerItem.title}
              </Title>
              <Title
                tag="h4"
                fontSize={18}
                color={descriptionColor}
                qaLabel={`${qaLabel}Description`}
              >
                {promosBannerItem.description}
                {hasSecondDescription && (
                  <SecondDescription qaLabel={`${qaLabel}SecondDescription`}>
                    {promosBannerItem.secondDescription}
                  </SecondDescription>
                )}
              </Title>
              {displayCTA && this.renderButton(path.currentSlug)}
            </PromosBannerContentWrapper>
          </PromosBannerContentContainer>
        )}
      </PathContext.Consumer>
    );
  }
}
