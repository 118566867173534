import styled, { css } from "styled-components";
import { transparentize } from "polished";
import { buildTitle } from "../../_atom/Title";
import Button from "../../_atom/Buttons/default";
import Icon from "../../_static/Icons";
import { fontMedium, fontBold, fontNormal } from "../../_static/Typography";
import { easeInOut, generateTransition } from "../../_static/Transitions";
import buildColor from "../../_static/ColorPalette";

export const TitleContainer = styled.div`
  display: flex;
  align-items: stretch;
  padding: 8px;
  transition: ${generateTransition(easeInOut, "box-shadow")};
  ${({ isTitleCenter }) => isTitleCenter && `position: relative`};
  ${(props) =>
    props.isNavigation
      ? css`
          justify-content: flex-start;
          background-color: ${buildColor("blue", "700")};
        `
      : css`
          justify-content: ${({ isTitleCenter }) =>
            isTitleCenter ? `center` : `space-between`};
          background-color: ${props.titleType === "defaultBold"
            ? buildColor("blue", "700")
            : buildColor("white", "100")};
          ${props.titleType !== "defaultBold" &&
          props.hasHeaderBorder &&
          css`
            border-bottom: 1px solid ${buildColor("blue", "100")};
          `};
        `};
  color: ${(props) =>
    props.titleType === "defaultBold"
      ? buildColor("white", "100")
      : buildColor("grey", "900")};
  ${(props) =>
    props.roundedCorners &&
    css`
      border-radius: 4px 4px 0 0;
    `};
  flex: 0 0 auto;
  ${(props) =>
    props.isSticky &&
    css`
      position: sticky;
      top: 0;
      z-index: 20;
    `};
  ${(props) =>
    props.myBetsStandaloneToggle &&
    css`
      color: ${buildColor("blue", "900")};
    `};
  ${({ titleType, myBetsStandaloneToggle }) =>
    titleType === "ipp" &&
    !myBetsStandaloneToggle &&
    css`
      border-bottom: 1px solid ${buildColor("blue", "100")};
      z-index: 20;
    `}
  ${(props) =>
    props.showShadow &&
    css`
      box-shadow:
        0 2px 4px ${transparentize(0.94, buildColor("blue", "900"))},
        0 1px 2px ${transparentize(0.92, buildColor("blue", "900"))};
    `};
`;

export const NavigationTitleContainer = styled.div`
  display: flex;
`;

export const HeaderButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 0;
  background: none;
  flex: 0 0 32px;
  border-radius: 50%;
  ${({ isTitleCenter }) =>
    isTitleCenter &&
    css`
      position: absolute;
      top: 0;
      right: 8px;
      transform: translate(0, 25%);
    `}
  ${({ hasBiggerHeader }) =>
    hasBiggerHeader &&
    css`
      align-self: start;
    `}
  ${({ hasMarginRight }) =>
    hasMarginRight &&
    css`
      margin-right: 10px;
    `}

    ${({ shouldRenderWhiteTitle }) =>
    !shouldRenderWhiteTitle &&
    `
      &:hover {
        background-color: ${buildColor("grey", "100")};
      }
    
      &:active {
        background-color: ${buildColor("grey", "200")};
      }
    
      @media (pointer: fine) {
        &:hover {
          background-color: ${buildColor("grey", "100")};
        }
      }
    
      @media (pointer: coarse) {
        &:active {
          background-color: ${buildColor("grey", "200")};
        }
      }
    `}
`;

export const CloseButton = styled(Button)`
  margin: 0 12px 0 auto;
  color: ${buildColor("white", "100")};
  font-weight: 500;
  font-size: 14px;
  font-family: ${fontMedium};
  background: none;
  display: flex;
  ${({ translateHeader }) =>
    translateHeader &&
    css`
      transform: translateX(-338px);
    `}

  & > svg {
    margin-left: 4px;
  }
`;

export const StatusIcon = styled(Icon)`
  margin-right: 8px;
`;

export const Title = styled(
  buildTitle({
    color: buildColor("grey", "900"),
    fontSize: 16,
    ellipsis: true,
    uppercase: false,
    qaLabel: "modal-title"
  })
)`
  display: flex;
  align-items: center;
  ${({ isTitleCenter }) => !isTitleCenter && `flex: 1 1 auto`};
  margin: 4px 0 0 4px;
  line-height: 24px;
  font-family: ${fontBold};
  text-transform: capitalize;
  ${(props) =>
    !props.hasSubtitle &&
    css`
      margin-bottom: 4px;
    `};
  ${(props) =>
    props.success &&
    css`
      color: ${buildColor("green", "600")};
    `};
  ${(props) =>
    props.error &&
    css`
      color: ${buildColor("red", "500")};
    `};
  ${(props) =>
    props.warning &&
    css`
      color: ${buildColor("orange", "500")};
    `};
  ${(props) =>
    props.isNavigation &&
    css`
      color: ${buildColor("white", "100")};
    `};
  ${(props) =>
    props.subtitle &&
    css`
      color: ${buildColor("grey", "800")};
    `};
  ${(props) =>
    props.myBetsStandaloneToggle &&
    css`
      display: inline-block;
      font-family: ${fontBold};
      text-transform: capitalize;
      font-size: 18px;
    `};
  ${({ info }) =>
    info &&
    css`
      font-size: 18px;
      font-weight: 700;
      text-transform: none;
    `}
  ${({ shouldRenderWhiteTitle }) =>
    shouldRenderWhiteTitle && `color: ${buildColor("white", "100")};`}
`;

export const TitleHolder = styled.div`
  flex: 1 0 auto;
  display: inline-flex;
  flex-direction: column;
`;

export const WidgetContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-right: 8px;
`;

export const Subtitle = styled.p`
  margin: 0 0 4px 4px;
  font-family: ${fontNormal};
  font-size: 14px;
  font-style: normal;
  text-transform: capitalize;
  line-height: 18px;
  color: ${buildColor("grey", "800")};
`;

export const HeaderChildrenContainer = styled.div`
  display: block;
  width: 100%;
`;

export const HeaderContainer = styled.div`
  display: block;
  ${(props) =>
    props.showShadow &&
    css`
      z-index: 20;
      box-shadow:
        0 2px 4px ${transparentize(0.94, buildColor("blue", "900"))},
        0 1px 2px ${transparentize(0.92, buildColor("blue", "900"))};
    `};
`;

export const CounterContainer = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  padding-right: 12px;
`;

export const NormalCounter = styled.span`
  padding-right: 4px;
  font-weight: 700;
`;

export const DollarCounter = styled.span`
  padding-right: 4px;
  padding-left: 16px;
  font-weight: 700;
`;

export const CounterDescription = styled.span`
  font-size: 11px;
  font-family: ${fontNormal};
  color: ${buildColor("grey", "700")};
`;
