import { get } from "lodash";
import { Messages } from "@tvg/ts-types/capi";

export const getMessages = (store: unknown): Messages =>
  get(store, "capi.messages", {});

export const getNamespaces = (store: unknown): Record<string, boolean> =>
  get(store, "capi.namespaces", {});

export const getFeatureToggles = (store: unknown): Record<string, boolean> =>
  get(store, "capi.featureToggles", {});
