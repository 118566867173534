import styled from "styled-components";

export const PromosBodyContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(233 239 245);
  border-radius: 4px 4px 0 0;

  @media only screen and (width >= 600px) {
    padding: 40px;
  }
`;

export const PromosBodyContentWrapper = styled.div`
  background-color: #fff;
  max-width: 800px;
  border-radius: 4px;

  @media only screen and (width >= 600px) {
    padding: 48px;
  }

  @media only screen and (width <= 600px) {
    padding: 12px;
    max-width: 100%;
  }
`;
