import styled from "styled-components";
import Button from "../../../_atom/Buttons/button";

import buildColor from "../../../_static/ColorPalette";

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 8px;
  z-index: 2000;
  background-color: ${(props) => props.isVisible && buildColor("black", "30")};
`;

export const ExpandButton = styled(Button)`
  justify-self: stretch;
`;

export const VolumeButton = styled(Button)`
  justify-self: stretch;
  margin-right: 8px;
`;

export const PlayButton = styled.button`
  display: flex;
  height: 40px;
  padding: 0 8px;
  background-color: ${buildColor("black", "30")};
  border-radius: 2px;
  outline: none;
  cursor: pointer;

  /* only add hover state for fine pointers, meaning that only on desktops using a mouse the hover state will work */
  @media (pointer: fine) {
    &:hover {
      background-color: ${buildColor("black", "30")};
    }
  }

  &:active {
    background-color: ${buildColor("black", "50")};

    /* on touch devices (coarse), the active color should be the hover spec according to the style guide on zeplin */
    @media (pointer: coarse) {
      background-color: ${buildColor("black", "30")};
    }
  }

  & > span {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
`;

export const LeftCornerOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  padding: 8px;
`;

// TODO: TO BE REMOVED ON THE FUTURE
export const FeedBackContainer = styled.div`
  position: relative;
  place-self: stretch stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & span {
    padding-top: 4px;
  }
`;

export const SkipButton = styled.button`
  background-color: ${buildColor("black", "60")};
  color: ${buildColor("white", "100")};
  width: 57px;
  height: 24px;
  top: calc(50% - 12px);
  border-radius: 2px;
  padding: 0 8px;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &.rewButton {
    position: absolute;
    left: 8px;
  }

  &.fwdButton {
    position: absolute;
    right: 8px;
  }
`;

export const PlayButtonLabel = styled.span`
  text-transform: uppercase;
  color: ${buildColor("white", "100")};
  font-size: 12px;
  user-select: none;
`;

export const InnerPlayButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WatchButton = styled.button`
  color: ${buildColor("white", "100")};
  font-size: 12px;
  text-transform: uppercase;
  padding: 7px 8px;
  background-color: ${buildColor("black", "30")};
  position: absolute;
  top: 8px;
  left: calc(50% - 52px);
  cursor: pointer;
  border-radius: 2px;

  &.replay {
    left: calc(50% - 45px);
  }

  /* only add hover state for fine pointers, meaning that only on desktops using a mouse the hover state will work */
  @media (pointer: fine) {
    &:hover {
      background-color: ${buildColor("black", "30")};
    }
  }

  &:active {
    background-color: ${buildColor("black", "50")};

    /* on touch devices (coarse), the active color should be the hover spec according to the style guide on zeplin */
    @media (pointer: coarse) {
      background-color: ${buildColor("black", "30")};
    }
  }
`;

export const FullUnmuteButton = styled(Button)`
  margin-right: 8px;

  & svg {
    margin-right: 6px;
  }
`;
