import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  height: 30px;
  padding: 0 8px;
  border-top: 1px solid var(--fd-colors-system-positive-border-default);
  border-bottom: 1px solid var(--fd-colors-system-positive-border-default);
  align-items: center;
  justify-content: center;
  background-color: var(--fd-colors-system-positive-background-subtle);
`;
