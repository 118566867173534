import styled from "styled-components";
import buildColor from "../../_static/ColorPalette";
import { fontBold } from "../../_static/Typography";

const HeaderText = styled.h3`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "18px")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "24px")};
  margin-bottom: 16px;
  font-family: ${fontBold};
  color: ${buildColor("grey", "900")};
`;

export default HeaderText;
