import styled from "styled-components";
import buildColor from "../../_static/ColorPalette";
import Icon from "../../_static/Icons";
import { fontMedium, fontNormal } from "../../_static/Typography";

export const EmptyStandingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  flex-grow: 1;
  justify-content: center;
`;

export const EmptyStandingsIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: ${buildColor("grey", "100")};
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin-bottom: 20px;
`;

export const EmptyStandingsInfoIcon = styled(Icon)`
  position: absolute;
  top: 0;
  right: 0;

  & > path:first-child {
    fill: ${buildColor("blue_accent", "400")};
  }

  & > path:last-child {
    stroke: ${buildColor("white", "100")};
  }
`;

export const EmptyStandingsTitle = styled.span`
  font-family: ${fontMedium};
  color: ${buildColor("grey", "900")};
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 4px;
`;

export const EmptyStandingsSubTitle = styled.span`
  font-family: ${fontNormal};
  font-size: 14px;
  color: ${buildColor("grey", "800")};
  line-height: 18px;
`;
