import styled from "styled-components";
import buildColor from "../../_static/ColorPalette";

export const PromoSlotsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
`;

export const PromoSlotsWrapper = styled.div`
  display: block;
  padding: 0 5px;
  background-color: ${buildColor("white", "100")};

  .promoAllSlotsTitle {
    color: ${buildColor("grey", "900")};
    margin: 0 0 24px 6px;
  }

  @media only screen and (width <= 599px) {
    padding: 0 6px;

    .promoAllSlotsTitle {
      margin: 0 6px 24px;
    }
  }

  @media (width >= 768px) {
    padding: 16px 5px;
  }
`;
