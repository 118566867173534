import { keyframes, css } from "styled-components";

export const delayTime = "150ms";

export const easeInOut = {
  function: "ease-in-out",
  value: 200,
  time: "ms"
};

export const delayAction = {
  function: "linear",
  value: 0,
  time: "s"
};

export const linear = {
  function: "linear",
  value: 300,
  time: "ms"
};

export const cubic = {
  function: "cubic-bezier(0.03, 0.59, 0.1, 0.97)",
  value: 250,
  time: "ms"
};

export const spring = {
  function: "cubic-bezier(.19,1.32,.48,1)",
  value: 450,
  time: "ms"
};

export const pageSwitch = {
  function: "cubic-bezier(0,1.02,.58,1)",
  value: 400,
  time: "ms"
};

export const generateTransition = (transition, cssProp = "all", delay) =>
  `${cssProp} ${transition.value}${transition.time} ${transition.function}${
    delay ? ` ${delay}` : ""
  }`;

export const opacityIn = () => keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const opacityOut = () => keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

const TransitionBaseFloating = `
  backface-visibility: hidden;
  transform: translateZ(0);
  will-change: transform;
  transform: translate3d(0, -50%, 0);
  transition: ${generateTransition(cubic, "transform")};
`;

const TransitionBase = `
  backface-visibility: hidden;
  transform: translateZ(0);
  will-change: transform;
  transform: translate3d(0, 0, 0);
  transition: ${generateTransition(cubic, "transform")};
`;

export const SlideFloatingBottomTransition = css`
  ${TransitionBaseFloating}

  &.bottomFloating-appear {
    transition-delay: ${delayTime};
    transform: translate3d(0, 50%, 0);
  }

  &.bottomFloating-appear-active {
    transform: translate3d(0, -50%, 0);
  }

  &.bottomFloating-enter {
    transition-delay: ${delayTime};
    transform: translate3d(0, 50vh, 0);
  }

  &.bottomFloating-enter-active {
    transform: translate3d(0, -50%, 0);
  }

  &.bottomFloating-exit {
    transform: translate3d(0, 50vh, 0);
  }
`;

export const SlideBottomTransition = css`
  ${TransitionBase}

  &.bottom-appear {
    transition-delay: ${delayTime};
    transform: translate3d(0, 100%, 0);
  }

  &.bottom-appear-active {
    transform: translate3d(0, 0, 0);
  }

  &.bottom-enter {
    transition-delay: ${delayTime};
    transform: translate3d(0, 100%, 0);
  }

  &.bottom-enter-active {
    transform: translate3d(0, 0, 0);
  }

  &.bottom-exit {
    transform: translate3d(0, 100%, 0);
  }
`;

export const SlideTopTransition = css`
  ${TransitionBase}

  &.top-appear {
    transition-delay: ${delayTime};
    transform: translate3d(0, -100%, 0);
  }

  &.top-appear-active {
    transform: translate3d(0, 0, 0);
  }

  &.top-enter {
    transition-delay: ${delayTime};
    transform: translate3d(0, -100%, 0);
  }

  &.top-enter-active {
    transform: translate3d(0, 0, 0);
  }

  &.top-exit {
    transform: translate3d(0, -100%, 0);
  }
`;

export const SlideRightTransition = css`
  ${TransitionBase}

  &.right-appear {
    transform: translate3d(99.9%, 0, 0);
  }

  &.right-appear-active {
    transform: translate3d(0, 0, 0);
  }

  &.right-enter {
    transform: translate3d(99.9%, 0, 0);
  }

  &.right-enter-active {
    transform: translate3d(0, 0, 0);
  }

  &.right-exit {
    transform: translate3d(99.9%, 0, 0);
  }
`;

export const SlideLeftTransition = css`
  ${TransitionBase}

  &.left-appear {
    transform: translate3d(-99.9%, 0, 0);
  }

  &.left-appear-active {
    transform: translate3d(0, 0, 0);
  }

  &.left-enter {
    transform: translate3d(-99.9%, 0, 0);
  }

  &.left-enter-active {
    transform: translate3d(0, 0, 0);
  }

  &.left-exit {
    transform: translate3d(-99.9%, 0, 0);
  }
`;

export const FadeTransition = css`
  backface-visibility: hidden;
  will-change: opacity;
  transition: ${generateTransition(easeInOut, "opacity")};

  &.fade-appear {
    opacity: 0;
  }

  &.fade-appear-active {
    opacity: 1;
  }

  &.fade-enter {
    opacity: 0;
  }

  &.fade-enter-active {
    opacity: 1;
  }

  &.fade-exit {
    opacity: 0;
  }
`;

export const grow = () => keyframes`
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.5);
  }

  100%{
    transform: scale(1);
  }
`;
