import moment from "moment";

export const filterComingUpRaces = (
  comingUpRaces,
  allRaces,
  resultsPerPage,
  page
) => {
  const tbdRaces = (allRaces || [])
    .filter((race) => race.raceDate === null)
    .map((race) => ({
      ...race,
      raceDate: "TBD",
      number: race.raceNumber
    }));

  const officialRacesFiltered = (comingUpRaces || [])
    .filter((race) => ["MC", "RO", "SK"].includes(race.status.code))
    .sort(
      (a, b) => new Date(a.postTime).getTime() - new Date(b.postTime).getTime()
    );

  const allRacesFiltered = (allRaces || [])
    .filter((race) => moment().subtract(1, "d").isBefore(race.raceDate))
    .sort(
      (a, b) => new Date(a.raceDate).getTime() - new Date(b.raceDate).getTime()
    )
    .map((race) => ({
      ...race,
      number: race.raceNumber
    }));

  const races = (
    allRacesFiltered.filter(
      (allRacesItem) =>
        !officialRacesFiltered.find(
          (officialRacesItem) =>
            officialRacesItem.trackCode === allRacesItem.trackCode &&
            officialRacesItem.number === allRacesItem.raceNumber
        )
    ) || []
  ).concat(tbdRaces || []);

  return {
    count: races.length,
    races: races.slice(
      (page - 1) * resultsPerPage,
      (page - 1) * resultsPerPage + resultsPerPage
    )
  };
};

export const filterPastRaces = (
  comingUpRaces,
  allRaces,
  resultsPerPage,
  page
) => {
  const comingUpRacesFiltered = (comingUpRaces || [])
    .filter((race) => race.status.code === "RO")
    .map((race) => ({
      ...race,
      date: race.postTime
    }));

  const allRacesFiltered = (allRaces || [])
    .filter((race) => moment().subtract(1, "d").isAfter(race.raceDate))
    .map((race) => ({
      ...race,
      date: race.raceDate,
      number: race.raceNumber
    }));

  const races = (comingUpRacesFiltered || [])
    .concat(allRacesFiltered || [])
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  return {
    count: races.length,
    races: races.slice(
      (page - 1) * resultsPerPage,
      (page - 1) * resultsPerPage + resultsPerPage
    )
  };
};
