/* eslint-disable security/detect-object-injection */
import React from "react";
import {
  BagdeContainer,
  StyledComponent,
  IconContainer
} from "./styled-components";
import { type IconWithBackgroundProps } from "./types";
import { Icon } from "../Icon";
import { checkColor, iconWithBackgroundVariations } from "./styled-utils";

export const IconWithBackground = ({
  qaLabel = "icon-with-background",
  mainIcon,
  badgeIcon,
  variation = "info",
  override
}: IconWithBackgroundProps) => {
  let styles = iconWithBackgroundVariations[variation];

  if (override) {
    styles = { ...styles, ...(override as typeof styles) };
  }
  styles.mainCircleBackground = checkColor(styles.mainCircleBackground);

  return (
    <StyledComponent data-qa-label={qaLabel}>
      <svg width="62" height="62" viewBox="0 0 63 62" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d={
            badgeIcon
              ? "M45.6208 3.39549C41.3858 1.22478 36.586 0 31.5 0C14.3792 0 0.5 13.8792 0.5 31C0.5 48.1208 14.3792 62 31.5 62C48.6208 62 62.5 48.1208 62.5 31C62.5 25.914 61.2752 21.1142 59.1045 16.8792C57.7264 17.5953 56.1604 18 54.5 18C48.9772 18 44.5 13.5228 44.5 8C44.5 6.33956 44.9047 4.77364 45.6208 3.39549Z"
              : "M31.5 62C48.6208 62 62.5 48.1208 62.5 31C62.5 13.8792 48.6208 0 31.5 0C14.3792 0 0.5 13.8792 0.5 31C0.5 48.1208 14.3792 62 31.5 62Z"
          }
          fill={styles.mainCircleBackground}
        />
      </svg>
      <IconContainer>
        <Icon
          name={mainIcon}
          size={styles.mainIcon.size}
          lineColor={styles.mainIcon.lineColor}
          backgroundColor={styles.mainIcon.backgroundColor}
        />
      </IconContainer>
      {badgeIcon && (
        <BagdeContainer size={styles.mainIcon.size}>
          <Icon
            name={badgeIcon}
            size="s"
            lineColor={styles.badgeIcon.lineColor}
            backgroundColor={styles.badgeIcon.backgroundColor}
          />
        </BagdeContainer>
      )}
    </StyledComponent>
  );
};

export type { IconWithBackgroundProps };
