import React from "react";
import Text from "@tvg/atomic-ui/_atom/Text";

import buildColor from "../../_static/ColorPalette";

import {
  ImagesWrapper,
  ImagesContainer,
  ImageWrapper,
  Image,
  Caption
} from "./styled-components";

const ImageGrid = ({ content, qaLabel }) => {
  const { images, caption } = content;
  const totalImages = images.length;
  const isOddGrid = totalImages % 2 !== 0;

  const renderImages = () =>
    images.map((image, index) => (
      <ImageWrapper
        key={`image-${index.toString()}`}
        data-qa-label={`${index}-${qaLabel}ImagesList`}
      >
        <Image
          src={image.imageUrl.filename}
          alt={image.imageAlt}
          largeImage={isOddGrid && totalImages === index + 1}
          data-qa-label={`${qaLabel}ImageAlt`}
        />
      </ImageWrapper>
    ));

  return (
    <ImagesWrapper data-qa-label={qaLabel}>
      <ImagesContainer
        data-qa-label={`${qaLabel}ImagesContainer`}
        className={`grid col-${images.length}`}
      >
        {renderImages()}
      </ImagesContainer>
      {caption && (
        <Caption data-qa-label={`${qaLabel}Caption`}>
          <Text
            qaLabel={`${qaLabel}CaptionText`}
            tag="span"
            fontSize={12}
            color={buildColor("grey", "600")}
          >
            {caption}
          </Text>
        </Caption>
      )}
    </ImagesWrapper>
  );
};

ImageGrid.defaultProps = {
  content: {
    images: [],
    caption: ""
  },
  qaLabel: "imageGrid"
};

export default ImageGrid;
